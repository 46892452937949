import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  CdkDropListGroup,
} from '@angular/cdk/drag-drop';
import {CommonModule} from '@angular/common';
import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {
  AVAILABLE_FILTER_DEFINITION_KEYS,
  EXCLUDED_RADAR_FILTERS,
  EditFilterSet,
  EditProductGroupStore,
  FilterGroup,
  FilterGroupItem,
  IFilterSet,
  IGenericFilterSetting,
  ProductGroupsStore,
  optionValueType,
} from '@em/data-feed/data-access-products';
import {
  ComponentBase,
  DialogService,
  EmButtonModule,
  EmMoreInfoModule,
} from '@em/shared/ui';
import {CountryCode} from '@em/shared/util-types';
import {CountryService} from '@em/user-account/data-access';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {List, Map} from 'immutable';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {v4 as uuidv4} from 'uuid';
import {CategorySelectionComponent} from '../product-filter/category-selection/category-selection.component';
import {ExtendFilterDefinitionService} from '../product-filter/extend-filter-definition/extend-filter-definition.service';
import {FilterItemComponent} from '../product-filter/filter-item/filter-item.component';
import {
  ManualProductSelectionComponent,
  listName,
} from '../product-filter/manual-product-selection/manual-product-selection.component';
import {ProductFilterDefinition} from '../product-filter/product-filter-definition';
import {PRODUCT_FILTER_DEFINITIONS} from '../product-filter/product-filter-definitions';
import {ProductFilterOverlayComponent} from '../product-filter/product-filter-overlay/product-filter-overlay.component';
import {ProductFilteringFeatureModule} from '../product-filter/product-filtering-feature.module';
import {
  bestPriceFilterValidator,
  filterValidator,
} from '../product-filter/validators';

const HIGH_FREQUENCY_PRODUCT_LIMIT = 5;

@Component({
  selector: 'em-edit-product-group',
  templateUrl: './edit-product-group.component.html',
  styleUrls: ['./edit-product-group.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    ProductFilterOverlayComponent,
    FilterItemComponent,
    CategorySelectionComponent,
    ProductFilteringFeatureModule,
    ManualProductSelectionComponent,
    EmButtonModule,
    EmMoreInfoModule,
    MatSelectModule,
    CdkDropListGroup,
    CdkDropList,
    CdkDrag,
  ],
  providers: [EditProductGroupStore],
})
export class EditProductGroupComponent extends ComponentBase implements OnInit {
  @Output() finish = new EventEmitter<IFilterSet | null>();
  todo = ['Get to work', 'Pick up groceries', 'Go home', 'Fall asleep'];

  done = ['Get up', 'Brush teeth', 'Take a shower', 'Check e-mail', 'Walk dog'];

  readonly componentState$ = this._editGroupStore.state$.pipe(
    map((state) => ({
      overlayState: state.overlayState,
      selectedFilterSet: state.group,
      selectedFilters: state.group.selectedFilters,
      links: state.group.links,
    })),
  );

  readonly countryCode: Observable<CountryCode> = this._country.observable();
  readonly groups$ = this._productGroupsStoer
    .getGroups(this.countryCode)
    .pipe(
      map((groups) => groups.filter((g) => g.uuid !== this.data?.group?.uuid)),
    );

  showExcludeInput = false;
  form: UntypedFormGroup;
  filterDefinitions: {[key: string]: ProductFilterDefinition} | undefined;

  constructor(
    private readonly _fb: UntypedFormBuilder,
    private readonly _country: CountryService,
    private readonly _dialog: DialogService,
    private readonly _translate: TranslateService,
    private readonly _extendFilterDefinition: ExtendFilterDefinitionService,
    private readonly _editGroupStore: EditProductGroupStore,
    private readonly _productGroupsStoer: ProductGroupsStore,
    @Inject(MAT_DIALOG_DATA) public data: {group: IFilterSet | undefined},
  ) {
    super();

    this._subscribe(
      this._extendFilterDefinition.extendFilterDefinition(
        PRODUCT_FILTER_DEFINITIONS,
      ),
      (filterDefinition) => (this.filterDefinitions = filterDefinition),
    );

    this.form = this._fb.group({
      name: ['', [Validators.required, Validators.minLength(1)]],
      excludeSetId: [''],
      selectedFilters: this._fb.array(
        [],
        [filterValidator, bestPriceFilterValidator],
      ),
      products: {
        manuallyAdded: [],
        manuallyRemoved: [],
      },
      categories: [[], []],
      invertCategorySelection: [undefined, []],
      isGoogleSPD: [false, Validators.required],
      isPriceCrawl: [false, Validators.required],
    });
  }

  ngOnInit(): void {
    if (this.data.group) {
      this._editGroupStore.updateGroup(this.data.group);
    }
    this._patchFormFromState();
  }

  get selectedFiltersForm(): UntypedFormArray {
    return this.form.get('selectedFilters') as UntypedFormArray;
  }

  get excludeSetIdControl(): FormControl {
    return this.form.get('excludeSetId') as FormControl;
  }

  get availableFilters(): string[] {
    return AVAILABLE_FILTER_DEFINITION_KEYS;
  }

  get canSave(): boolean {
    return this.form.valid && this.form.value.name;
  }

  get selectedCategoriesCount(): number {
    return this.form.value.categories.length;
  }

  addFilter(filterKey: string, index?: number) {
    if (this.isExcluded(filterKey)) {
      return;
    }

    const filterGroupItem: FilterGroupItem = this._createFilterEntry(filterKey);

    this.selectedFiltersForm.push(
      this._fb.group({...filterGroupItem.data.toJS()}),
    );
    this._editGroupStore.addSelectedItem({item: filterGroupItem, index});

    this.form.markAsDirty();
  }

  removeFilter(uuid: string | undefined, group: FilterGroup) {
    if (!uuid) {
      return;
    }
    if (group.items.size > 1) {
      this._editGroupStore.removeSelectedItem(uuid);
    } else {
      this._editGroupStore.removeFilterGroup(group);
    }

    // remove filter from selectedFiltersForm
    const position = this.selectedFiltersForm.controls.findIndex(
      (x) => x.value.uuid === uuid,
    );

    if (position > -1) {
      this.selectedFiltersForm.removeAt(position);
      this.form.markAsDirty();
    }
  }

  saveFilterSet() {
    if (!this.canSave) return;
    this._subscribe(
      this.componentState$.pipe(take(1)),
      ({selectedFilterSet, selectedFilters, links}) => {
        if (
          selectedFilterSet &&
          (selectedFilters || this.form.value.categories)
        ) {
          const filters = selectedFilters
            ? selectedFilters
                .map((group: FilterGroup) => ({
                  items: group.items.map((item: FilterGroupItem) => ({
                    ...this.form.value.selectedFilters.find(
                      (groupFilter: FilterGroupItem) =>
                        groupFilter.uuid === item.uuid,
                    ),
                    parent_uuid: group.uuid,
                  })),
                }))
                .map((x) => x.items)
                .flatten(1)
                .toJS()
            : [];

          const filterSet: IFilterSet = {
            uuid: selectedFilterSet.uuid,
            name: this.form.controls['name'].value,
            excludeSetId: this.form.get('excludeSetId')?.value,
            country: selectedFilterSet.country,
            // Unfortunately angular decides to initialize `undefined` values as `null`, so we need to take care of that.
            selectedFilters: filters as unknown as IGenericFilterSetting[],
            links: links || [],
            categoryFilter: {
              inverted: this.form.value.invertCategorySelection,
              categories: this.form.value.categories,
            },
            manuallyAddedProducts: this.form.value.products.manuallyAdded,
            manuallyRemovedProducts: this.form.value.products.manuallyRemoved,
            productCount: null,
            appliedCampaigns: [],
            type: 'custom',
            isGoogleSPD: this.form.value.isGoogleSPD,
            isPriceCrawl: this.form.value.isPriceCrawl,
          };

          this.finish.emit(filterSet);
          // this._editGroupStore.saveGroup(filterSet);
        }
      },
    );
  }

  openFilterSetsOverview() {
    if (this.form.dirty) {
      this._dialog
        .confirm({
          title: 'FILTER_SET_CONFIRM_TITLE',
          description: 'FILTER_SET_CONFIRM_TEXT',
          confirmLabel: 'SHARED_LABEL_DISCARD_CHANGES',
          cancelLabel: 'SHARED_LABEL_CONTINUE_EDITING',
          confirmType: 'danger',
        })
        .pipe(map((confirmed) => !!confirmed))
        .subscribe((confirmed) => {
          if (confirmed) {
            this.finish.emit(null);
          }
        });
    } else {
      this.finish.emit(null);
    }
  }

  hasUnsavedChanges(): boolean {
    return this.form.dirty;
  }

  openManualProductSelection() {
    this._editGroupStore.updateOverlayState('edit-manual-products');
  }

  openCategorySelection() {
    this._editGroupStore.updateOverlayState('edit-categories');
  }

  openEditFilterSet() {
    this._editGroupStore.updateOverlayState('edit-filter-set');
  }

  getProductCountForList(formGroupName: listName): number {
    return this.form.value.products[formGroupName].length;
  }

  i18nSystemFilterSet(name: string | undefined): string {
    if (!name) return '';

    return this._translate.instant(name.toUpperCase());
  }

  isHighFrequencyFilterSet(filterSet: EditFilterSet | undefined): boolean {
    return (
      filterSet?.type === 'template' &&
      filterSet?.name === 'AUTOMATIC_FILTER_HIGH_FREQUENCY'
    );
  }

  productLimit(selectedFilterSet: EditFilterSet | undefined): null | number {
    return this.isHighFrequencyFilterSet(selectedFilterSet)
      ? HIGH_FREQUENCY_PRODUCT_LIMIT
      : null;
  }

  getFormIndex(uuid: string | undefined) {
    return (
      this.form.controls['selectedFilters'] as unknown as UntypedFormArray
    ).controls.findIndex(
      (formGroup: UntypedFormGroup | AbstractControl) =>
        (formGroup as UntypedFormGroup).controls['uuid'].value === uuid,
    );
  }

  showFilterConnect(
    filterGroupsSize: number,
    filterGroupIndex: number,
    itemSize: number,
  ): boolean {
    return (
      filterGroupsSize > filterGroupIndex &&
      filterGroupIndex > 0 &&
      itemSize === 1
    );
  }

  showFilterDisconnect(itemsSize: number, itemIndex: number): boolean {
    return itemsSize > 1 && itemIndex > 0;
  }

  isFilterConnected(filterGroup: FilterGroup): boolean {
    return filterGroup.items.size > 1;
  }

  showFilterGroupConnect(filterGroups: List<FilterGroup>, index: number) {
    const next = filterGroups.getIn([index + 1]);
    return next && next.items.size > 1;
  }

  isExcluded(filterKey: string) {
    return (
      (this.form.value.isPriceCrawl || this.form.value.isGoogleSPD) &&
      EXCLUDED_RADAR_FILTERS.indexOf(filterKey) > -1
    );
  }

  addExcludeGroup() {
    this.showExcludeInput = true;
  }

  removeExcludeGroup() {
    this.showExcludeInput = false;
    this.form.get('excludeSetId')?.setValue(null);
  }

  filterDrop(event: CdkDragDrop<string[]>) {
    this.addFilter(event.item.data as string, event.currentIndex);
  }

  trackByIndex(index: number) {
    return index;
  }

  private _createFilterEntry(key: string): FilterGroupItem {
    const options: {[k: string]: optionValueType | undefined} = {};

    if (this.filterDefinitions) {
      this.filterDefinitions[key].availableOptions.forEach((option) => {
        options[option.name] = undefined;
      });
    }

    return new FilterGroupItem({
      uuid: uuidv4(),
      parent_uuid: '',
      key,
      options,
    });
  }

  private _patchFormFromState() {
    this._subscribe(
      this._editGroupStore.group$.pipe(take(1)),
      (selectedFilterSet) => {
        this.form.patchValue({
          name: selectedFilterSet?.name,
          excludeSetId: selectedFilterSet?.excludeSetId,
          products: {
            manuallyAdded: selectedFilterSet?.manuallyAddedProducts ?? [],
            manuallyRemoved: selectedFilterSet?.manuallyRemovedProducts ?? [],
          },
          categories: selectedFilterSet?.categoryFilter?.categories ?? [],
          invertCategorySelection: selectedFilterSet?.categoryFilter?.inverted,
          isGoogleSPD: selectedFilterSet?.isGoogleSPD || false,
          isPriceCrawl: selectedFilterSet?.isPriceCrawl || false,
        });

        const selectedFilters = selectedFilterSet?.selectedFilters ?? [];
        // We need to reset the controls before we push (new and old) items onto it.
        this.selectedFiltersForm.clear();

        selectedFilters.forEach((filterGroup: FilterGroup) => {
          if (filterGroup.items) {
            filterGroup.items.forEach((item: FilterGroupItem) => {
              if (
                !this.selectedFiltersForm.controls.find(
                  (x) =>
                    (x as unknown as UntypedFormGroup).controls['uuid']
                      .value === item.uuid,
                )
              ) {
                this.selectedFiltersForm.push(
                  this._fb.group({
                    uuid: item.uuid,
                    key: item.key,
                    options: (
                      item.options as unknown as Map<
                        string,
                        string | number | boolean
                      >
                    ).toJS(),
                  }),
                );
              }
            });
          }
        });
      },
    );
  }
}
