import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import {DialogService} from '../../services/dialog-service/dialog.service';

@Component({
  selector: 'em-more-info',
  templateUrl: './more-info.component.html',
  styleUrls: ['./more-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class EmMoreInfoComponent {
  @Input() title: string | undefined;
  @Input() description: string | undefined;
  @Input() maxWidth: number | undefined;
  @ViewChild('infoContent', {read: TemplateRef})
  infoContent: TemplateRef<unknown> | undefined;

  private readonly _dialogService = inject(DialogService);

  constructor() {}

  openInfoModal(event: MouseEvent) {
    this._dialogService.info(
      {
        title: this.title,
        description: this.description,
      },
      {maxWidth: this.maxWidth},
    );

    event.stopPropagation();
  }
}
