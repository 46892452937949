import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SetupStatusService} from '@em/auth/data-access';
import {ProductPipelinesGateway} from '@em/shared/api-interface';
import {
  ComponentStoreBase,
  ComponentStoreStateBase,
} from '@em/shared/util-types';
import {tapResponse} from '@ngrx/operators';
import {Observable} from 'rxjs';
import {filter, switchMap, withLatestFrom} from 'rxjs/operators';
import {DatafeedStore} from '../datafeed-store/datafeed.store';
import {PipelineListItem} from '../types/pipeline-list-item';
import {PipelineListFactory} from './pipeline-list-factory';

type errorTypes = 'load-pipelines' | 'activate-pipeline' | 'delete-pipeline';

export interface PipelineListState extends ComponentStoreStateBase<errorTypes> {
  pipelinesList?: PipelineListItem[];

  listLoaded?: boolean;
}

const initialState: PipelineListState = {};

@Injectable()
export class PipelineListStore extends ComponentStoreBase<
  PipelineListState,
  errorTypes
> {
  readonly pipelinesList$ = this.select((state) => state.pipelinesList);
  readonly activePipeline$ = this.select((state) =>
    state.pipelinesList
      ? state.pipelinesList?.find((p) => p.isActive) || null
      : undefined,
  );
  readonly pluginName$ = this._dataefeedStore.pluginName$;
  readonly listLoaded$: Observable<boolean | undefined> = this.select(
    (state) => state.listLoaded,
  );

  constructor(
    private readonly _dataefeedStore: DatafeedStore,
    private readonly _pipelinesGateway: ProductPipelinesGateway,
    private readonly _setupStatusService: SetupStatusService,
  ) {
    super(initialState);
  }

  readonly loadPipelines = this.effect<boolean | void>((forceLoading$) =>
    forceLoading$.pipe(
      withLatestFrom(this.listLoaded$),
      filter(
        ([forceLoading, listLoaded]) => forceLoading === true || !listLoaded,
      ),
      switchMap(() => {
        this.startLoading();
        return this._pipelinesGateway.getList({}).pipe(
          switchMap((pipelines) =>
            PipelineListFactory.buildPipelinesList(
              pipelines,
              this._setupStatusService.observable(),
              this._dataefeedStore,
            ),
          ),
          tapResponse(
            (pipelinesList) => {
              this.patchState({
                pipelinesList,
                isLoading: false,
                listLoaded: true,
              });
            },
            (err: HttpErrorResponse) => {
              this.addError({
                httpError: err,
                errorMessage: {
                  key: 'load-pipelines',
                },
                statePayload: {
                  pipelinesList: [],
                },
              });
            },
          ),
        );
      }),
    ),
  );

  readonly deletePipeline = this.effect<string>((uuid$) =>
    uuid$.pipe(
      switchMap((uuid) => {
        this.startLoading();
        return this._pipelinesGateway.deleteProductPipelines({uuid}).pipe(
          tapResponse(
            () => {
              this.loadPipelines(true);
            },
            (err: HttpErrorResponse) => {
              this.addError({
                httpError: err,
                errorMessage: {
                  key: 'delete-pipeline',
                },
              });
            },
          ),
        );
      }),
    ),
  );

  readonly toggleActivePipeline = this.effect<{
    uuid: string;
    isActive: boolean;
  }>((activateData$) =>
    activateData$.pipe(
      switchMap((activateData) => {
        this.startLoading();
        return this._pipelinesGateway
          .getProductPipelines({uuid: activateData.uuid})
          .pipe(
            switchMap((pipeline) =>
              this._pipelinesGateway.postProductPipelines({
                uuid: pipeline.uuid,
                source: pipeline.source,
                source_options: pipeline.source_options,
                active: activateData.isActive,
              }),
            ),
            tapResponse(
              () => {
                this.loadPipelines(true);
              },
              (err: HttpErrorResponse) => {
                this.addError({
                  httpError: err,
                  errorMessage: {
                    key: 'activate-pipeline',
                  },
                });
              },
            ),
          );
      }),
    ),
  );
}
