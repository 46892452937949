import {
  GOOGLE_PRICES_FILTER_TYPES,
  GooglePricesFilterType,
  IFilterDefinition,
  IGenericFilterSetting,
} from '@em/data-feed/data-access-products';
import {
  buildOptionDefinition,
  IOptionDefinition,
  OptionDefinition,
  validateOptionValue,
} from './option-definition';
import {IProductFilterError} from './product-filter-error';

type filterComponentType =
  | 'default'
  | 'nestedToggle'
  | 'dropdown'
  | 'modePrice'
  | 'customWithCompetitors'
  | 'customGooglePrices'
  | 'customPriceChanges'
  | 'customFetchStatus';

const FILTER_KEY_COMPONENT_TYPE_MAPPER: {[k: string]: filterComponentType} = {
  product_performance: 'default',
  profit_margin: 'dropdown',
  gtin: 'default',
  roas: 'dropdown',
  price: 'dropdown',
  current_price: 'dropdown',
  cogs: 'dropdown',
  outliers: 'default',
  source_created_at: 'dropdown',
  with_costs: 'dropdown',
  with_clicks: 'dropdown',
  with_conversions: 'dropdown',
  with_impressions: 'dropdown',
  with_competitors: 'customWithCompetitors',

  // custom definitions
  best_price: 'nestedToggle',

  mode_price_v2: 'modePrice',

  brand: 'dropdown',
  title: 'dropdown',
  availability: 'dropdown',
  custom_labels: 'dropdown',
  suggested_price: 'customGooglePrices',
  predicted_impressions_change_fraction: 'customGooglePrices',
  predicted_conversions_change_fraction: 'customGooglePrices',
  predicted_clicks_change_fraction: 'customGooglePrices',
  benchmark_price: 'customGooglePrices',
  price_changes: 'customPriceChanges',
  fetch_status: 'customFetchStatus',
};

export interface IProductFilterDefinition {
  availableOptions: IOptionDefinition[];
  key: string;
  requiresOptions: boolean;
  componentType: filterComponentType;
}

export function buildProductFilterDefinition(
  filtersSchema: IFilterDefinition[],
) {
  const result: {[key: string]: ProductFilterDefinition} = {};

  for (const def of filtersSchema) {
    for (const key of def.properties['key'].enum) {
      const productFilterDefinition = new ProductFilterDefinition(key);
      extractOptions(def, productFilterDefinition);
      result[productFilterDefinition.key] = productFilterDefinition;
    }
  }

  return result;
}

export function extractOptions(
  def: IFilterDefinition,
  productFilterDefinition: IProductFilterDefinition,
) {
  if (def.required.includes('options')) {
    productFilterDefinition.requiresOptions = true;
  }

  Object.entries(def.properties['options'].properties).forEach(
    ([name, option]) => {
      const required: boolean =
        def.properties['options'].required &&
        def.properties['options'].required.includes(name);

      productFilterDefinition.availableOptions.push(
        buildOptionDefinition(name, option, !!required),
      );
    },
  );
}

export function validateProductFilter(
  definition: IProductFilterDefinition,
  filter: IGenericFilterSetting,
) {
  if (filter.key !== definition.key) {
    throw new Error(`Expected filter ${definition.key} but got ${filter.key}`);
  }
  if (!definition.requiresOptions) return [];

  const results: IProductFilterError[] = [];
  for (const optionDefinition of definition.availableOptions) {
    const err = validateOptionValue(
      optionDefinition,
      filter.options[optionDefinition.name],
    );

    if (err) results.push({definition, error: err, option: optionDefinition});
  }

  return results;
}

export class ProductFilterDefinition implements IProductFilterDefinition {
  availableOptions: OptionDefinition[];
  key: string;
  requiresOptions: boolean;
  componentType: filterComponentType;

  constructor(key: string) {
    this.key = key;
    this.requiresOptions = false;
    this.availableOptions = [];
    this.componentType = FILTER_KEY_COMPONENT_TYPE_MAPPER[key] || 'default';
  }
}

// Get either the filter key, or in case of part of a filters group (e.g google prices) get the group key
export function getFilterGroupI18nKey(
  key: string,
  prefix: string,
  suffix?: string | number,
): string {
  if (GOOGLE_PRICES_FILTER_TYPES.includes(key as GooglePricesFilterType)) {
    key = 'GOOGLE_PRICES';
  }
  suffix = _getI18nSuffix(suffix);

  return `${prefix}_${key}${suffix}`.toUpperCase();
}

export function getFilterI18nKey(
  key: string,
  prefix: string,
  suffix?: string | number,
): string {
  suffix = _getI18nSuffix(suffix);

  return `${prefix}_${key}${suffix}`.toUpperCase();
}

function _getI18nSuffix(suffix: string | number | undefined) {
  if (suffix !== undefined) {
    // Unfortunately we chose to omit the '_' for numeric suffices...
    suffix = _getI18nSuffixForReal(suffix);
  } else {
    suffix = '';
  }
  return suffix;
}

function _getI18nSuffixForReal(suffix: string | number) {
  if (typeof suffix === 'number') {
    suffix = suffix.toString();
  } else {
    suffix = (suffix && '_' + suffix.toUpperCase()) || '';
  }
  return suffix;
}
