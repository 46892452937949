import {CommonModule} from '@angular/common';
import {Component, Inject, OnInit, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {
  EmIconButtonComponent,
  EmIconModule,
  EmSpinnerModule,
} from '@em/shared/ui';
import {Contract, StripeGateway} from '@em/subscription/api-interface';
import {TranslateModule} from '@ngx-translate/core';
import {catchError, map, of, tap} from 'rxjs';
import {ContractService} from '@em/subscription/data-access';

@Component({
  selector: 'em-update-subscription-modal',
  templateUrl: './update-subscription-modal.component.html',
  styleUrls: ['./update-subscription-modal.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    EmIconButtonComponent,
    EmSpinnerModule,
    EmIconModule,
  ],
})
export class EmUpdateSubscriptionModalComponent implements OnInit {
  private readonly _matDialog = inject(MatDialog);
  private readonly _stripeGateway = inject(StripeGateway);
  private readonly _contractService = inject(ContractService);

  protected isLoading = true;
  protected showSubscriptionUpdated = false;
  protected showSubscriptionUpdatedFailed = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {contract: Contract},
  ) {}

  ngOnInit(): void {
    if (this.data.contract) {
      this._stripeGateway
        .postUpdate(this.data.contract)
        .pipe(
          map((resp) => !!resp),
          catchError(() => of(false)),
          tap((result) => {
            if (result) {
              this.showSubscriptionUpdated = true;
              this._contractService.reloadContracts();
            } else {
              this.showSubscriptionUpdatedFailed = true;
            }
            this.isLoading = false;
          }),
        )
        .subscribe();
    } else {
      this.showSubscriptionUpdatedFailed = true;
      this.isLoading = false;
    }
  }

  close() {
    this._matDialog.closeAll();
  }
}
