import {each} from 'lodash-es';

export class ScriptInjector {
  constructor(private readonly _document: Document) {}

  injectScript(
    src: string,
    attrs: {[key: string]: string | undefined},
    onload?: () => void,
    onerror?: (err: string | Event) => void,
  ): HTMLElement {
    const scriptElm = this._document.createElement('script');

    scriptElm.setAttribute('src', src);

    each(attrs, (val, key) => {
      if (!val) return;
      scriptElm.setAttribute(key, val);
    });

    if (onload) {
      scriptElm.onload = onload;
    }
    if (onerror) {
      scriptElm.onerror = onerror;
    }

    this._document.body.appendChild(scriptElm);

    return scriptElm;
  }
}
