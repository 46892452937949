import {Component, Input} from '@angular/core';
import {EmIconModule} from '../icon/icon.component';
import {EmCardModule} from '../card/card.module';
import {EmBoxModule} from '../box/box.module';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'em-add-new-item-card',
  templateUrl: './add-new-item-card.component.html',
  styleUrls: ['./add-new-item-card.component.scss'],
  imports: [CommonModule, EmCardModule, EmIconModule, EmBoxModule],
})
export class EmAddNewItemCardComponent {
  @Input({required: true}) title?: string;
  @Input() asBox?: boolean = false;
}
