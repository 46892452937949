import {createFeatureSelector, createSelector} from '@ngrx/store';
import {EnterpriseState} from './enterprise.reducers';

export const selectEnterpriseStateFeature =
  createFeatureSelector<EnterpriseState>('enterprise');

export const selectEnterpriseState = createSelector(
  selectEnterpriseStateFeature,
  (state) => state,
);

export const selectAdwordsAccounts = createSelector(
  selectEnterpriseStateFeature,
  (state) => state.adwordsAccounts.list,
);

export const selectHasOauthToken = createSelector(
  selectEnterpriseStateFeature,
  (state) => state.hasOauthToken,
);

export const selectAccessToken = createSelector(
  selectEnterpriseStateFeature,
  (state) => state.oauth.accessToken,
);

export const selectSdkLoading = createSelector(
  selectEnterpriseStateFeature,
  (state) => state.sdk.loading,
);

export const selectSdkReady = createSelector(
  selectEnterpriseStateFeature,
  (state) => state.sdk.ready,
);

export const selectAdwordsAccountsLoaded = createSelector(
  selectEnterpriseStateFeature,
  (state) => state.adwordsAccounts.loaded,
);

export const selectPerformanceData = createSelector(
  selectEnterpriseStateFeature,
  (state) => state.performanceData,
);

export const selectAdwordsAccountsLoading = createSelector(
  selectEnterpriseStateFeature,
  (state) => state.adwordsAccounts.loading,
);

export const selectOauthState = createSelector(
  selectEnterpriseStateFeature,
  (state) => state.oauth.state,
);

export const selectAuthorizationState = createSelector(
  selectEnterpriseStateFeature,
  (state) => state.authorization.state,
);
