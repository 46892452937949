<ng-container *ngIf="filterSet">
  <mat-card
    class="filter-set"
    (click)="selectFilterSet(filterSet)"
    [ngClass]="{active: isSelected}"
  >
    <mat-card-header [ngClass]="{'compact-view': compactView}">
      <div mat-card-avatar class="relative">
        <em-pie-chart
          class="chart"
          [data]="
            productShare(
              productsCount$ | async,
              totalProductCountCurrentCountry | async
            )
          "
        >
        </em-pie-chart>
        <em-spinner
          class="em-spinner-mini"
          [overContent]="true"
          *ngIf="isLoadingCount$ | async"
        ></em-spinner>
      </div>
      <mat-card-title
        ><span class="break-words text-base mb-1">{{
          filterSet.name | productGroupName
        }}</span></mat-card-title
      >
      <mat-card-subtitle>
        <span translate>PRODUCT_FILTER_SET_FILTER_LIST</span>
        <span
          *ngIf="totalProductCountCurrentCountry | async as totalProductCount"
        >
          ({{ productsCount$ | async }} / {{ totalProductCount }})
        </span>
      </mat-card-subtitle>
      <div class="-mt-4 -mr-4 ml-auto" *ngIf="!compactView">
        <em-icon-button
          icon="more_vert"
          color=""
          [matMenuTriggerFor]="menu"
          (click)="$event.stopPropagation()"
        >
        </em-icon-button>
      </div>
    </mat-card-header>

    <ng-container *ngIf="!compactView && hasDescription(filterSet)">
      <div class="description">
        {{ i18nFilterSetDescription(filterSet) | translate }}
      </div>
    </ng-container>
    <mat-card-content *ngIf="!compactView">
      <div *ngIf="appliedCampaigns.length > 0" class="campaigns-wrapper">
        <strong translate>PRODUCT_FILTER_CAMPAIGNS_USAGE</strong>
        <div *ngIf="isLoadingCampaigns$ | async; else campaignsList">
          <em-three-dots-loading size="xs"></em-three-dots-loading>
        </div>
        <ng-template #campaignsList>
          <ul>
            <li
              class="flex items-center"
              [ngClass]="{
                'text-red-500': campaign.attributes?.invertedProducts
              }"
              *ngFor="let campaign of appliedCampaigns$ | async"
            >
              <img
                class="platform-icon inline"
                [src]="platformIcon(campaign.platform)"
              />{{ campaign.name }}

              <em-more-info
                class="ml-2"
                [maxWidth]="400"
                [description]="
                  campaign.attributes?.invertedProducts
                    ? 'CAMPAIGN_PRODUCTGROUP_TOOLTIP_INVERTED'
                    : 'CAMPAIGN_PRODUCTGROUP_TOOLTIP'
                "
              >
              </em-more-info>
            </li>
          </ul>
        </ng-template>
      </div>

      <div *ngIf="appliedCampaigns.length <= 0"></div>

      <div
        class="filter-item-wrapper flex flex-wrap"
        [ngClass]="hasTooManyFilters && !showMoreFilters ? 'hide-extra' : ''"
      >
        <em-filter-item-plain
          *ngFor="let item of selectedFilters"
          [text]="getPlainFilterText(item)"
          [icon]="'filter_list'"
        >
        </em-filter-item-plain>
        <em-filter-item-plain
          *ngIf="selectedCategoriesCount > 0"
          [count]="selectedCategoriesCount"
          [text]="'PRODUCT_FILTER_SELECTED_CATEGORIES'"
          [icon]="'filter_list'"
        >
        </em-filter-item-plain>
        <em-filter-item-plain
          *ngIf="includedProductsCount > 0"
          [count]="includedProductsCount"
          [text]="'FILTER_MANUAL_INCLUDED'"
          [icon]="'add_circle_outline'"
        >
        </em-filter-item-plain>
        <em-filter-item-plain
          *ngIf="excludedProductsCount > 0"
          [count]="excludedProductsCount"
          [text]="'FILTER_MANUAL_EXCLUDED'"
          [icon]="'remove_circle_outline'"
        >
        </em-filter-item-plain>

        <ng-container *ngIf="hasTooManyFilters">
          <div *ngIf="!showMoreFilters" class="self-center">...</div>
          <em-button
            type="text"
            (btnClick)="toggleMoreFilters($event)"
            [icon]="showMoreFilters ? 'expand_less' : 'expand_more'"
            ><ng-container *ngIf="!showMoreFilters">{{
              'SHARED_LABEL_SHOW_MORE' | translate
            }}</ng-container
            ><ng-container *ngIf="showMoreFilters">{{
              'SHARED_LABEL_SHOW_LESS' | translate
            }}</ng-container>
          </em-button>
        </ng-container>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="editGroup(filterSet)">
      <div class="flex items-center">
        <em-icon name="edit"></em-icon>
        <span>{{ 'PRODUCT_FILTER_EDIT_SET' | translate }}</span>
      </div>
    </button>
    <button mat-menu-item (click)="duplicateGroup(filterSet)">
      <div class="flex items-center">
        <em-icon name="content_copy"></em-icon>
        <span>{{ 'SHARED_LABEL_DUPLICATE' | translate }}</span>
      </div>
    </button>
    <button
      mat-menu-item
      [routerLink]="['/export-products/add']"
      [queryParams]="{
        group_uuid: filterSet.uuid,
        country: filterSet.country,
        name: filterSet.name + ' - CSV'
      }"
      *ngIf="filterSet.country"
    >
      <div class="flex items-center">
        <em-icon name="file_upload" color=""></em-icon>
        <span>{{ 'NAV_EXPORT_CSV' | translate }}</span>
      </div>
    </button>
    <button mat-menu-item (click)="downloadProductsCSV(filterSet)">
      <div class="flex items-center">
        <em-icon name="download"></em-icon>
        <span>{{
          'PRODUCT_FILTER_SET_DOWNLOAD_PRODUCTS_CSV' | translate
        }}</span>
      </div>
    </button>
    <button mat-menu-item *ngIf="allowDelete" (click)="deleteGroup(filterSet)">
      <div class="flex items-center">
        <em-icon name="delete" color="warn"></em-icon>
        <span>{{ 'PRODUCT_FILTER_SET_DELETE' | translate }}</span>
      </div>
    </button>
  </mat-menu>
</ng-container>
