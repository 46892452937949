import {Injectable, inject} from '@angular/core';
import {
  GroupsProductsService,
  ProductGroupsStore,
} from '@em/data-feed/data-access-products';
import {ProductGroupsGateway} from '@em/repricing/api-interface';
import {isNotNullOrUndefined} from '@em/shared/util-rxjs';
import {CountryCode} from '@em/shared/util-types';
import {ComponentStore} from '@ngrx/component-store';
import {tapResponse} from '@ngrx/operators';

import {Observable, switchMap, take, tap} from 'rxjs';

interface RepricingProductsCountState {
  repricingProductsCount: number;
  isLoading: boolean;
}

@Injectable()
export class RepricingProductsCountStore extends ComponentStore<RepricingProductsCountState> {
  private readonly _productGroupsStore = inject(ProductGroupsStore);
  private readonly _groupProductsService = inject(GroupsProductsService);
  private readonly _repricingJobsGateway = inject(ProductGroupsGateway);

  readonly isLoading$: Observable<boolean> = this.select(
    (state) => state.isLoading,
  );
  readonly repricingProductsCount$: Observable<number> = this.select(
    (s) => s.repricingProductsCount,
  );

  constructor() {
    super({isLoading: true, repricingProductsCount: 0});
  }

  readonly loadProductsCount = this.effect<void>((trigger$) =>
    trigger$.pipe(
      switchMap(() =>
        this._repricingJobsGateway.getProductsCount({}).pipe(
          tapResponse(
            (resp) => {
              this.patchState({
                isLoading: false,
                repricingProductsCount: resp.total_products_count || 0,
              });
            },
            () => {
              this.patchState({isLoading: false});
            },
          ),
        ),
      ),
    ),
  );

  readonly includeProductGroupCount = this.effect<{
    groupId: string;
    country: CountryCode;
  }>((trigger$) =>
    trigger$.pipe(
      switchMap((input) =>
        this._productGroupsStore
          .getGroup(input.groupId, input.country)
          .pipe(isNotNullOrUndefined(), take(1)),
      ),
      switchMap((group) =>
        this._groupProductsService.getProductsIds(group).pipe(
          take(1),
          tap((ids) => {
            this.patchState({
              repricingProductsCount:
                this.get((s) => s.repricingProductsCount) + ids.length,
            });
          }),
        ),
      ),
    ),
  );
}
