import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgModule,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {ComponentBase} from '@em/shared/ui';
import {TranslateModule} from '@ngx-translate/core';
import {ApexOptions, NgApexchartsModule} from 'ng-apexcharts';
import {TimeSeries, ITimeDatapoint} from '@em/shared/util-types';

export interface IBarChartData {
  data: ITimeDatapoint[];
  name?: string;
}

@Component({
  selector: 'em-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class BarChartComponent extends ComponentBase implements OnChanges {
  @Input() config?: ApexOptions | null;
  @Input() data?: TimeSeries[] | null;
  result: IBarChartData[] = [];

  constructor(private readonly _changeDet: ChangeDetectorRef) {
    super();
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (this.data?.length) {
      this.result = [
        ...this.data
          .filter((item) => !!item)
          .map((item) => ({data: item?.data, name: item.name})),
      ];
    } else {
      this.result = [];
    }

    this._changeDet.markForCheck();
  }

  hasData() {
    return !!(
      this.data &&
      this.data?.length &&
      this.data.some((dataItem) => !dataItem?.isEmpty())
    );
  }
}

@NgModule({
  declarations: [BarChartComponent],
  exports: [BarChartComponent],
  imports: [CommonModule, NgApexchartsModule, TranslateModule],
})
export class BarChartModule {}
