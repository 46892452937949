import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {Router, RouterLink} from '@angular/router';
import {ManagedCampaignsStore} from '@em/campaigns/data-access';
import {
  DownloadGroupProductsService,
  GroupsProductsService,
  IAppliedCampaign,
  IFilterSet,
  IGenericFilterSetting,
  ProductGroup,
  findMatchingCampaigns,
  mapFilterSet,
} from '@em/data-feed/data-access-products';
import {ProductsCountService} from '@em/data-feed/data-access-products';
import {FACEBOOK, GOOGLE, PlatformId} from '@em/shared/platforms/util';
import {
  DialogService,
  EmButtonModule,
  EmIconButtonComponent,
  EmIconModule,
  EmMoreInfoModule,
  EmSpinnerModule,
  EmThreeDotsLoadingModule,
} from '@em/shared/ui';
import {EmPieChartModule} from '@em/shared/ui-charts';
import {isNotNullOrUndefined} from '@em/shared/util-rxjs';
import {TranslateModule} from '@ngx-translate/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, switchMap, take, tap} from 'rxjs/operators';
import {EmProductGroupNamePipe} from '../../pipes/product-group-name/product-group-name.pipe';
import {ManageGroupsService} from '../../services/manage-groups/manage-groups.service';
import {FilterItemPlainModule} from '../filter-item-plain/filter-item-plain.component';
import {PRODUCT_FILTER_DEFINITIONS} from '../product-filter/product-filter-definitions';
import {getFilterI18nKey} from '../product-filter/product-filter-definition';
import {ContractService} from '@em/subscription/data-access';

const logoMapper: {[k: string]: string} = {
  'google-shopping': GOOGLE.logos.campaign,
  facebook: FACEBOOK.logos.general,
  bing: 'assets/images/bing/microsoft-logo.svg',
};

@Component({
  selector: 'em-product-group-card',
  imports: [
    CommonModule,
    TranslateModule,
    RouterLink,
    MatCardModule,
    MatMenuModule,
    EmPieChartModule,
    FilterItemPlainModule,
    EmIconButtonComponent,
    EmMoreInfoModule,
    EmButtonModule,
    EmIconModule,
    EmThreeDotsLoadingModule,
    EmSpinnerModule,
    EmProductGroupNamePipe,
  ],
  templateUrl: './product-group-card.component.html',
  styleUrls: ['./product-group-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmProductGroupCardComponent implements OnInit {
  @Input() set group(group: ProductGroup | null | undefined) {
    this.productGroup$.next(group || null);
    this.filterSet = group ? mapFilterSet(group) : undefined;
  }
  @Input() isSelected?: boolean;
  @Input() allowDelete?: boolean = true;
  @Input() compactView = false;
  @Output() updated = new EventEmitter<IFilterSet | string>();

  readonly filterDefinitions = PRODUCT_FILTER_DEFINITIONS;
  readonly totalProductCountCurrentCountry: Observable<number | undefined>;
  readonly getFilterI18nKey = getFilterI18nKey;
  showMoreFilters = false;
  defaultMaxDisplayedFilters = 6;
  filterSet: IFilterSet | undefined;
  productGroup$ = new BehaviorSubject<ProductGroup | null>(null);
  isLoadingCampaigns$: Observable<boolean> | undefined;
  appliedCampaigns$: Observable<IAppliedCampaign[]> | undefined;
  productsCount$: Observable<number> = this.productGroup$.pipe(
    isNotNullOrUndefined(),
    switchMap((group) => this._groupProductsService.getProductsIds(group)),
    map((products) => products.length),
  );

  isLoadingCount$ = this.productGroup$.pipe(
    isNotNullOrUndefined(),
    switchMap((group) => this._groupProductsService.isLoading(group?.uuid)),
  );

  constructor(
    private readonly _dialog: DialogService,
    private readonly _productsCountService: ProductsCountService,
    private readonly _router: Router,
    private readonly _manageGroupsService: ManageGroupsService,
    private readonly _groupProductsService: GroupsProductsService,
    private readonly _contractService: ContractService,
    private readonly _downloadProductsService: DownloadGroupProductsService,
    @Optional() private readonly _managedCampaignsStore: ManagedCampaignsStore,
  ) {
    this.totalProductCountCurrentCountry =
      this._productsCountService.productsCount$;
  }

  ngOnInit(): void {
    if (this._managedCampaignsStore) {
      this.isLoadingCampaigns$ = this._managedCampaignsStore.isLoadingAny$;
      this.appliedCampaigns$ = this._managedCampaignsStore.allCampaigns$.pipe(
        map((allCampaigns) =>
          findMatchingCampaigns(
            this.filterSet?.appliedCampaigns || [],
            allCampaigns,
          ),
        ),
      );
    }
  }

  get selectedFilters() {
    if (!this.filterSet) return [];
    return this.filterSet.selectedFilters;
  }

  get appliedCampaigns() {
    if (!this.filterSet) return [];
    return this.filterSet.appliedCampaigns;
  }

  get selectedCategoriesCount() {
    if (!this.filterSet) return 0;
    return this.filterSet.categoryFilter.categories.length;
  }

  get includedProductsCount() {
    if (!this.filterSet) return 0;
    return this.filterSet.manuallyAddedProducts.length;
  }

  get excludedProductsCount() {
    if (!this.filterSet) return 0;
    return this.filterSet.manuallyRemovedProducts.length;
  }

  get filtersCount() {
    return (
      (this.selectedFilters?.length || 0) +
      (this.selectedCategoriesCount ? 1 : 0) +
      (this.includedProductsCount ? 1 : 0) +
      (this.excludedProductsCount ? 1 : 0)
    );
  }

  get hasTooManyFilters() {
    return this.filtersCount > this.defaultMaxDisplayedFilters;
  }

  hasDescription(filterSet: IFilterSet): boolean {
    return filterSet.type === 'template';
  }

  platformIcon(platformKey: PlatformId | undefined): string {
    return platformKey ? logoMapper[platformKey] : '';
  }

  productShare(count: number | null, total: number | null | undefined): number {
    if (count === null || !total) return 0;

    return (count / total) * 100;
  }

  editGroup(filterSet: IFilterSet) {
    // if no uuid proviced don't automatically save, emit in the output
    if (filterSet.uuid) {
      this._manageGroupsService
        .openEditGroupAndSave(filterSet, filterSet.country)
        .pipe(
          tap((updated) => {
            if (updated) {
              this.updated.emit(updated);
            }
          }),
        )
        .subscribe();
    } else {
      this._manageGroupsService
        .openAddEditGroup(filterSet)
        .pipe(
          tap((updated) => {
            if (updated) {
              this.updated.emit(updated);
            }
          }),
        )
        .subscribe();
    }
  }

  deleteGroup(filterSet: IFilterSet) {
    if (filterSet.uuid) {
      this._contractService.editableContract$
        .pipe(
          take(1),
          tap((contract) => {
            const crawlGroups = contract?.price_crawls?.map(
              (crawl) => crawl.selected_filters_uuid,
            );
            if (filterSet.uuid && crawlGroups?.includes(filterSet.uuid)) {
              this._informCantDelete();
            } else {
              this._confirmAndDelete(filterSet);
            }
          }),
        )
        .subscribe();
    }
  }

  selectFilterSet(filterSet: IFilterSet) {
    this._router.navigate(['/', 'products', 'list'], {
      queryParams: {group: filterSet.uuid},
    });
  }

  i18nFilterSetDescription(filterSet: IFilterSet): string {
    return `${filterSet.name.toUpperCase()}_DESCRIPTION`;
  }

  toggleMoreFilters(event: MouseEvent) {
    this.showMoreFilters = !this.showMoreFilters;
    event.stopPropagation();
  }

  getPlainFilterText(filter: IGenericFilterSetting) {
    return getFilterI18nKey(filter.key, 'CAMPAIGN_PERFORMANCE_FILTER');
  }

  duplicateGroup(filterSet: IFilterSet) {
    this._manageGroupsService
      .duplicateAndEditGroup(filterSet)
      .pipe(
        tap((updated) => {
          if (updated) {
            this.updated.emit(updated);
          }
        }),
      )
      .subscribe();
  }

  downloadProductsCSV(filterSet: IFilterSet) {
    if (filterSet.uuid) {
      this._downloadProductsService
        .downloadGroupProducts(filterSet.uuid, filterSet.name)
        .subscribe();
    }
  }

  private _confirmAndDelete(filterSet: IFilterSet) {
    this._dialog
      .confirm({
        title: 'FILTER_SET_CONFIRM_DELETE_TITLE',
        description: 'FILTER_SET_CONFIRM_DELETE_TEXT',
        confirmLabel: 'SHARED_LABEL_DELETE',
        confirmType: 'danger',
      })
      .pipe(
        tap((confirmed) => {
          if (confirmed && filterSet.uuid) {
            this._manageGroupsService.deleteGroup(filterSet.uuid);
          }
        }),
      )
      .subscribe();
  }

  private _informCantDelete() {
    this._dialog
      .info({
        confirmType: 'danger',
        title: 'DELETE_GROUP_NOT_POSSIBLE',
        description: 'DELETE_GROUP_USED_IN_CRAWL_JOB',
      })
      .subscribe();
  }
}
