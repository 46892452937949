import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  listName,
  ManualSelectableProduct,
} from '../manual-product-selection/manual-product-selection.component';
import {CommonModule} from '@angular/common';
import {EmButtonModule} from '@em/shared/ui';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'em-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TranslateModule, MatIconModule, EmButtonModule],
})
export class ProductItemComponent {
  @Input() product?: ManualSelectableProduct;
  @Input() disabled = true;
  @Input() showIncludeIcon = false;
  @Input() showExcludeIcon = false;
  @Output() productSelected = new EventEmitter<listName>();

  constructor() {}

  addToIncluded() {
    if (this.disabled) return;

    this.productSelected.emit('manuallyAdded');
  }

  addToExcluded() {
    if (this.disabled) return;

    this.productSelected.emit('manuallyRemoved');
  }
}
