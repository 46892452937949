import {Injectable, inject} from '@angular/core';
import {ShopifyGateway} from '@em/subscription/api-interface';
import {ComponentStore} from '@ngrx/component-store';
import {tapResponse} from '@ngrx/operators';
import {Observable, combineLatest, interval} from 'rxjs';
import {filter, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';

const POLL_ACTIVE_TRIES = 50;
const POLL_INTERVAL = 3000;

interface ShopifyPaymentStatusState {
  paymentSuccess?: boolean;
  pollTries: number;
  isLoading: boolean;
}

@Injectable()
export class ShopifyPaymentStatusStore extends ComponentStore<ShopifyPaymentStatusState> {
  private readonly _shopifyGateway = inject(ShopifyGateway);

  paymentSuccess$ = this.select((s) => s.paymentSuccess);
  pollTries$ = this.select((s) => s.pollTries);
  isLoading$: Observable<boolean> = this.select((s) => s.isLoading);

  constructor() {
    super({pollTries: 0, isLoading: true});

    this.monitorLoadingState();
  }

  readonly loadSubscriptionStatus = this.effect<string>((subscriptionId$) =>
    subscriptionId$.pipe(
      switchMap((subscriptionId) =>
        this._shopifyGateway.getStatus({subscription_id: subscriptionId}).pipe(
          tapResponse(
            () => {
              this.patchState({paymentSuccess: true});
            },
            () => {
              this.patchState({paymentSuccess: false});
            },
          ),
        ),
      ),
    ),
  );

  readonly pollSubscriptionStatus = this.effect<string>((subscriptionId$) =>
    subscriptionId$.pipe(
      switchMap((subscriptionId) =>
        interval(POLL_INTERVAL).pipe(
          startWith(0),
          tap(() => {
            this.loadSubscriptionStatus(subscriptionId);
            this.patchState({pollTries: this.get((s) => s.pollTries + 1)});
          }),
          takeUntil(
            combineLatest([this.paymentSuccess$, this.pollTries$]).pipe(
              filter(
                ([paymentSuccess, pollTries]) =>
                  paymentSuccess || pollTries > POLL_ACTIVE_TRIES,
              ),
            ),
          ),
        ),
      ),
    ),
  );

  readonly monitorLoadingState = this.effect((sessionId$) =>
    sessionId$.pipe(
      switchMap(() => combineLatest([this.paymentSuccess$, this.pollTries$])),
      tap(([paymentSuccess, pollTries]) => {
        this.patchState({
          isLoading: !paymentSuccess && pollTries <= POLL_ACTIVE_TRIES,
        });
      }),
    ),
  );
}
