import {Component} from '@angular/core';
import {EmInputComponent} from '../input/input.component';

@Component({
  selector: 'em-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  standalone: false,
})
export class EmTextAreaComponent extends EmInputComponent {}
