import {CommonModule} from '@angular/common';
import {Component, inject} from '@angular/core';
import {EmPrimaryButtonComponent, EmSpinnerModule} from '@em/shared/ui';
import {Invoice, InvoicesGateway} from '@em/subscription/api-interface';
import {TranslateModule} from '@ngx-translate/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  combineLatest,
  map,
  of,
  tap,
} from 'rxjs';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule, PageEvent} from '@angular/material/paginator';

@Component({
  selector: 'em-invoices-list',
  templateUrl: './invoices-list.component.html',
  styleUrls: ['./invoices-list.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    MatTableModule,
    MatPaginatorModule,
    EmSpinnerModule,
    EmPrimaryButtonComponent,
  ],
})
export class EmInvoicesListComponent {
  private readonly _invoicesGateway = inject(InvoicesGateway);

  protected isLoading = true;
  protected currentPage$ = new BehaviorSubject(0);
  protected pageSize$ = new BehaviorSubject(10);

  protected allInvoices$ = this._invoicesGateway.getInvoices({}).pipe(
    tap(() => (this.isLoading = false)),
    catchError(() => {
      this.isLoading = false;
      return of(null);
    }),
  );
  protected readonly pagedInvoices$: Observable<Invoice[] | null> =
    combineLatest([this.allInvoices$, this.currentPage$, this.pageSize$]).pipe(
      map(([invoices, page, pageSize]) =>
        invoices?.length
          ? invoices.slice(page * pageSize, (page + 1) * pageSize)
          : null,
      ),
    );

  readonly displayedColumns: string[] = ['product', 'created_at', 'url'];

  goToPage(event: PageEvent) {
    this.currentPage$.next(event.pageIndex);
  }
}
