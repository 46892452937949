import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EmIconModule} from '../icon/icon.component';
import {expandCollapseVisibilityAnimation} from '../../animations/expand-collapse';

@Component({
  selector: 'em-expandable-section',
  imports: [CommonModule, EmIconModule],
  templateUrl: './expandable-section.component.html',
  styleUrls: ['./expandable-section.component.scss'],
  animations: [expandCollapseVisibilityAnimation],
})
export class ExpandableSectionComponent {
  @Input() title = '';
  @Input() set expand(value: boolean) {
    this.isExpanded = value;
  }

  protected isExpanded = false;

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
