import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {createSelector, Store} from '@ngrx/store';
import {ComponentBase} from '@em/shared/ui';
import {validateEmail} from '@em/shared/ui';
import {LanguageService} from '@em/user-account/data-access';
import {
  finishCommonWizard,
  selectCommonWizard,
} from '@em/onboarding/data-access';

const mapError = (error: string): string => {
  if (error === 'email is already taken') return 'CREATE_ACCOUNT_EMAIL_IN_USE';
  return 'ERROR_UNKNOWN';
};

const selectCommonWizardState = createSelector(selectCommonWizard, (state) => ({
  state,
  error: state.error ? mapError(state.error) : null,
}));

@Component({
  selector: 'em-common-wizard',
  templateUrl: './common-wizard.component.html',
  styleUrls: ['./common-wizard.component.scss'],
  standalone: false,
})
export class CommonWizardComponent
  extends ComponentBase
  implements OnInit, OnDestroy
{
  readonly state$ = this._store.select(selectCommonWizardState);

  readonly form = this._fb.group({
    email: ['', [Validators.required, validateEmail]],
    country: ['', Validators.required],
    language: ['', Validators.required],
  });

  constructor(
    private readonly _store: Store,
    private readonly _fb: UntypedFormBuilder,
    private readonly _language: LanguageService,
  ) {
    super();
  }

  ngOnInit() {
    this._subscribe(this.state$, ({state}) => {
      this.form.patchValue({
        email: state?.email,
        country: state?.country,
        language: state?.language,
      });
    });
  }

  canProcess() {
    return this.form.valid;
  }

  process() {
    if (!this.canProcess()) return;

    this._store.dispatch(
      finishCommonWizard({
        data: {...this.form.value, commonConfirmed: true},
        redirectAfterSave: true,
      }),
    );
    this._afterProcess();
  }

  private _afterProcess() {
    this._language.change(this.form.value.language);
  }
}
