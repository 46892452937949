import {CommonModule} from '@angular/common';
import {Component, inject} from '@angular/core';
import {DatafeedStore} from '@em/data-feed/data-access-setup';
import {
  EmErrorBoxModule,
  EmPrimaryButtonComponent,
  EmSpinnerModule,
} from '@em/shared/ui';
import {StripeGateway} from '@em/subscription/api-interface';
import {ContractService} from '@em/subscription/data-access';
import {TranslateModule} from '@ngx-translate/core';
import {catchError, map, of, tap} from 'rxjs';

@Component({
  selector: 'em-external-payment-portal-entry',
  templateUrl: './external-payment-portal-entry.component.html',
  styleUrls: ['./external-payment-portal-entry.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    EmPrimaryButtonComponent,
    EmSpinnerModule,
    EmErrorBoxModule,
  ],
})
export class EmExternalPaymentPortalEntryComponent {
  private readonly _stripeGateway = inject(StripeGateway);
  private readonly _datafeedStore = inject(DatafeedStore);
  private readonly _contractService = inject(ContractService);

  protected isLoading = false;
  protected showNoStripeAccount = false;
  protected isShopify$ = this._datafeedStore.pluginName$.pipe(
    map((plugin) => plugin === 'shopify'),
  );

  protected isStripe$ = this._contractService.activeSubscription$.pipe(
    map((active) => active?.processor === 'stripe' || !active),
  );

  constructor() {
    this._datafeedStore.loadPlugin();
  }

  openStripePortal() {
    if (!this.isLoading) {
      this.isLoading = true;
      this.showNoStripeAccount = false;
      this._stripeGateway
        .getPortal({})
        .pipe(
          tap((response) => (window.location.href = response.stripe.url)),
          catchError(() => {
            this.showNoStripeAccount = true;
            this.isLoading = false;
            return of(null);
          }),
        )
        .subscribe();
    }
  }
}
