import {Inject, Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {GapiAuthService, LocalUserDataService} from '@em/auth/data-access';
import {CurrencyCode} from '@em/shared/util-types';
import {WINDOW} from '@em/shared/util-web';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {Store} from '@ngrx/store';
import {of} from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import {AccountCreationComponent} from './account-creation/account-creation.component';
import {
  AccountCreationFailed,
  AccountCreationSuccess,
  ActionTypes,
  DeleteOauthToken,
  EnterpriseActions,
  LoadAdwordsAccounts,
  LoadAdwordsAccountsSuccess,
  LoadPerformanceDataSuccess,
  Noop,
  OauthSignInFailed,
  OauthSignInSuccess,
  SdkReady,
} from './enterprise.actions';
import {IAdwordsAccount} from './enterprise.reducers';
import {
  selectAccessToken,
  selectHasOauthToken,
  selectSdkReady,
} from './enterprise.selectors';
import {EnterpriseService} from './enterprise.service';

const canLogin = (account: IAdwordsAccount): boolean => {
  const jwt = account.jwt;
  return typeof jwt === 'string' && jwt !== '';
};

const OAUTH_ACCESS_TOKEN_KEY = 'oauth_access_token';

@Injectable()
export class EnterpriseEffects {
  deleteOauthToken = createEffect(
    () =>
      this._actions.pipe(
        ofType(ActionTypes.DELETE_OAUTH_TOKEN),
        tap(() => {
          this._localUserData.remove(OAUTH_ACCESS_TOKEN_KEY);
          this._window.location.reload();
        }),
      ),
    {dispatch: false},
  );

  loadPerformance = createEffect(() =>
    this._actions.pipe(
      ofType(ActionTypes.LOAD_PERFORMANCE_DATA),
      map((action) => action.payload),
      concatLatestFrom(() => this._store.select(selectAccessToken)),
      mergeMap(([{adwordsId, loginCustomerId}, accessToken]) => {
        const emptyPerformancePayload = {
          adwordsId,
          performanceData: {
            costs: null,
            conversions: null,
            clicks: null,
            revenue: null,
          },
        };

        if (!accessToken) {
          return of(new LoadPerformanceDataSuccess(emptyPerformancePayload));
        }

        return this._enterprise
          .getPerformance(accessToken, adwordsId, loginCustomerId)
          .pipe(
            map(
              (performanceData) =>
                new LoadPerformanceDataSuccess({adwordsId, performanceData}),
            ),
            catchError(() =>
              of(new LoadPerformanceDataSuccess(emptyPerformancePayload)),
            ),
          );
      }, 5),
    ),
  );

  loadGoogleSdk = createEffect(() =>
    this._actions.pipe(
      ofType(ActionTypes.LOAD_GOOGLE_SDK),
      concatLatestFrom(() => this._store.select(selectSdkReady)),
      switchMap(([, sdkReady]) =>
        sdkReady ? of(undefined) : this._gapiAuth.loadSdk(),
      ),
      map(() => new SdkReady()),
    ),
  );

  sdkReady = createEffect(() =>
    this._actions.pipe(
      ofType(ActionTypes.SDK_READY),
      map(() => {
        const accessToken = this._localUserData.get(OAUTH_ACCESS_TOKEN_KEY);
        if (accessToken) return new OauthSignInSuccess({accessToken});

        return new Noop();
      }),
    ),
  );

  loginWithGoogle = createEffect(() =>
    this._actions.pipe(
      ofType(ActionTypes.OAUTH_SIGN_IN),
      switchMap(() =>
        this._gapiAuth
          .oauthSignIn('https://www.googleapis.com/auth/adwords email')
          .pipe(
            map(
              (access_token) =>
                new OauthSignInSuccess({accessToken: access_token}),
            ),
            catchError(() => of(new OauthSignInFailed())),
          ),
      ),
    ),
  );

  oauthSignInSuccess = createEffect(() =>
    this._actions.pipe(
      ofType(ActionTypes.OAUTH_SIGN_IN_SUCCESS),
      map((action) => action.payload),
      tap(({accessToken}) => {
        this._localUserData.set(OAUTH_ACCESS_TOKEN_KEY, accessToken);
      }),
      map(({accessToken}) => new LoadAdwordsAccounts({accessToken})),
    ),
  );

  loadAdwordsAccounts = createEffect(() =>
    this._actions.pipe(
      ofType(ActionTypes.LOAD_ADWORDS_ACCOUNTS),
      map((action) => action.payload),
      exhaustMap(({accessToken}) =>
        this._enterprise
          .postSocialLogin(accessToken)
          .pipe(catchError(() => of(null))),
      ),
      map((resp) => {
        if (resp === null) return null;
        const adwordsAccounts = this._collectAdwordsAccounts(
          resp as unknown as IManagedAccount[],
        );
        const hasOauthToken = !!adwordsAccounts.find(canLogin);

        return {
          adwordsAccounts,
          hasOauthToken,
        };
      }),
      map((resp) => {
        if (resp === null) return new DeleteOauthToken();

        return new LoadAdwordsAccountsSuccess({
          adwordsAccounts: resp.adwordsAccounts,
          hasOauthToken: resp.hasOauthToken,
        });
      }),
    ),
  );

  openAccountCreationFlow = createEffect(() =>
    this._actions.pipe(
      ofType(ActionTypes.OPEN_ACCOUNT_CREATION_FLOW),
      map((action) => action.payload),
      concatLatestFrom(() => [
        this._store.select(selectHasOauthToken),
        this._store.select(selectAccessToken),
      ]),
      switchMap(
        ([
          {adwordsId, currencyCode, loginCustomerId},
          hasOauthToken,
          accessToken,
        ]) => {
          const dialogRef = this._matDialog.open(AccountCreationComponent, {
            data: {
              adwordsId,
              accessToken,
              hasOauthToken: !!hasOauthToken,
              currencyCode,
              loginCustomerId,
            },
            disableClose: false,
          });
          return dialogRef.afterClosed().pipe(
            map((jwt: string | null) => {
              if (jwt) return new AccountCreationSuccess({jwt, adwordsId});
              return new AccountCreationFailed();
            }),
          );
        },
      ),
    ),
  );

  constructor(
    private readonly _actions: Actions<EnterpriseActions>,
    private readonly _store: Store,
    private readonly _gapiAuth: GapiAuthService,
    private readonly _enterprise: EnterpriseService,
    private readonly _matDialog: MatDialog,
    private readonly _localUserData: LocalUserDataService,
    @Inject(WINDOW) private readonly _window: Window,
  ) {}

  private _collectAdwordsAccounts(resp: IManagedAccount[]): IAdwordsAccount[] {
    const accounts = [];

    for (const item of resp) {
      if (!item.is_manager) {
        accounts.push(this._buildAdwordsAccount(item));
      } else {
        for (const subItem of item.managed_accounts) {
          accounts.push(
            this._buildAdwordsAccount(
              subItem,
              item.adwords_id,
              item.descriptive_name,
            ),
          );
        }
      }
    }

    return accounts;
  }

  private _buildAdwordsAccount(
    item: IManagedAccount,
    managedById?: number,
    managedByName?: string | null,
  ): IAdwordsAccount {
    return {
      id: item.adwords_id,
      name: item.descriptive_name,
      jwt: item.jwt,
      currencyCode: item.currency_code,
      isManager: item.is_manager,
      loginCustomerId: managedById || item.adwords_id,
      managedById,
      managedByName: managedByName || null,
    } as IAdwordsAccount;
  }
}

interface IManagedAccount {
  adwords_id: number;
  currency_code: CurrencyCode;
  descriptive_name: string | null;
  jwt: string | boolean | null;
  is_manager?: boolean;
  managed_accounts: IManagedAccount[];
}
