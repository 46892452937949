import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, inject} from '@angular/core';
import {
  ColumnLayoutsGateway,
  ProductsListLayout,
} from '@em/shared/api-interface';
import {
  ComponentStoreBase,
  ComponentStoreStateBase,
} from '@em/shared/util-types';
import {tapResponse} from '@ngrx/operators';
import {Subject, switchMap, tap} from 'rxjs';

type errorTypes = 'loadLayout' | 'addLayout' | 'deleteLayout';

export interface ProductsListLayoutState
  extends ComponentStoreStateBase<errorTypes> {
  layouts?: ProductsListLayout[];
}

@Injectable()
export class ProductsListLayoutStore extends ComponentStoreBase<
  ProductsListLayoutState,
  errorTypes
> {
  private readonly _columnLayoutService = inject(ColumnLayoutsGateway);

  readonly layouts$ = this.select((s) => s.layouts);
  // return undefined if no layouts exist, null if no active found
  readonly activeLayout$ = this.select((s) =>
    s.layouts ? s.layouts?.find((l) => l.active) || null : undefined,
  );

  readonly layoutSaved$ = new Subject<string>();

  constructor() {
    super({});

    this.loadLayout();
  }

  // Effects

  // Load All Existing Layouts
  readonly loadLayout = this.effect<void>((trigger$) =>
    trigger$.pipe(
      tap(() => this.startLoading()),
      switchMap(() =>
        this._columnLayoutService.getColumnLayouts({}).pipe(
          tapResponse(
            (layout) => {
              this.patchState({
                layouts: layout.map((l) => ({
                  ...l,
                  layout: Array.isArray(l.layout) // should not come back as array
                    ? {filters: {}, columns: []}
                    : {
                        filters: l.layout['filters'] || {},
                        columns: (l.layout['columns'] as unknown[]) || [],
                      },
                })),
                isLoading: false,
              });
            },
            (error: HttpErrorResponse) => {
              this.addError({
                httpError: error,
                errorMessage: {
                  key: 'loadLayout',
                },
              });
            },
          ),
        ),
      ),
    ),
  );

  // Add new Layout
  readonly addOrUpdateLayout = this.effect<
    Omit<ProductsListLayout, 'uuid'> & {uuid?: string}
  >((trigger$) =>
    trigger$.pipe(
      tap(() => this.startLoading()),
      switchMap((layout) =>
        this._columnLayoutService.postColumnLayouts(layout).pipe(
          tapResponse(
            (res) => {
              this.loadLayout();
              this.layoutSaved$.next(res.uuid);
            },
            (error: HttpErrorResponse) => {
              this.addError({
                httpError: error,
                errorMessage: {
                  key: 'addLayout',
                },
              });
            },
          ),
        ),
      ),
    ),
  );

  // Delete Layout
  readonly deleteLayout = this.effect<string>((trigger$) =>
    trigger$.pipe(
      tap(() => this.startLoading()),
      switchMap((uuid) =>
        this._columnLayoutService
          .deleteColumnLayouts({
            uuid,
          })
          .pipe(
            tapResponse(
              () => {
                this.loadLayout();
              },
              (error: HttpErrorResponse) => {
                this.addError({
                  httpError: error,
                  errorMessage: {
                    key: 'deleteLayout',
                  },
                });
              },
            ),
          ),
      ),
    ),
  );
}
