import {
  CommonModule,
  CurrencyPipe,
  DecimalPipe,
  PercentPipe,
} from '@angular/common';
import {
  Component,
  DestroyRef,
  OnInit,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatBadgeModule} from '@angular/material/badge';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {
  FetchProductsStore,
  ProductsListService,
} from '@em/data-feed/data-access-products';
import {
  EmCardModule,
  EmIconButtonComponent,
  EmIconModule,
  EmSelectModule,
} from '@em/shared/ui';
import {getSelectedLocale} from '@em/shared/util-date-time';
import {isNotNullOrUndefined} from '@em/shared/util-rxjs';
import {CountryService} from '@em/user-account/data-access';
import {TranslateModule} from '@ngx-translate/core';
import {AgGridModule} from 'ag-grid-angular';
import {
  ColDef,
  ColGroupDef,
  GridApi,
  GridReadyEvent,
  IDatasource,
} from 'ag-grid-community';
import {distinctUntilChanged, skip, tap} from 'rxjs';
import {EmProductGroupNamePipe} from '../../pipes/product-group-name/product-group-name.pipe';
import {ManageGroupsService} from '../../services/manage-groups/manage-groups.service';
import {createProductsListColumnsDefAndTypes} from '../columns-def/columns-def';
import {EmColumnsSelectorComponent} from '../columns-selector/columns-selector.component';
import {EmCsvDownloadComponent} from '../csv-download/csv-download.component';
import {EmFetchProductsInfoComponent} from '../fetch-products-info/fetch-products-info.component';
import {getGridDataSrouce} from '../grid-data-source/grid-data-source';
import {AG_GRID_LOCALE_DE} from '../i18n/ag-grid-local-de';
import {EmProductsListLayoutComponent} from '../layout/products-list-layout/products-list-layout.component';

@Component({
  selector: 'em-products-list',
  imports: [
    CommonModule,
    TranslateModule,
    RouterLink,
    AgGridModule,
    MatMenuModule,
    MatTooltipModule,
    MatBadgeModule,
    EmColumnsSelectorComponent,
    EmSelectModule,
    EmCardModule,
    EmIconModule,
    EmIconButtonComponent,
    EmCsvDownloadComponent,
    EmFetchProductsInfoComponent,
    EmProductGroupNamePipe,
    EmProductsListLayoutComponent,
  ],
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss'],
  // in order to apply the grid styles to the grid child
  encapsulation: ViewEncapsulation.None,
  providers: [ProductsListService, CurrencyPipe, DecimalPipe, PercentPipe],
})
export class ProductsListComponent implements OnInit {
  private readonly _productsListService = inject(ProductsListService);
  private readonly _manageGroupsService = inject(ManageGroupsService);
  private readonly _fetchProductsStore = inject(FetchProductsStore);
  private readonly _countryService = inject(CountryService);
  private readonly _activatedRoute = inject(ActivatedRoute);
  private readonly _router = inject(Router);
  private readonly _destroyRef = inject(DestroyRef);

  protected gridApi!: GridApi;
  protected localeText:
    | {
        [key: string]: string;
      }
    | undefined;

  readonly columnsDefAndTypes = createProductsListColumnsDefAndTypes();
  readonly productGroups$ = this._manageGroupsService.getGroups();
  readonly allProductsCount$ = this._productsListService.allProductsCount$;
  readonly filteredProductsCount$ =
    this._productsListService.filteredProductsCount$;

  readonly defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    width: 150,
  };
  selectedGroup: string | undefined = '';
  gridIsReady = false;
  gridDataSource: IDatasource | undefined;
  currentColumnsDefs: Array<ColDef | ColGroupDef> | undefined;

  get filtersCount() {
    return Object.keys(this.gridApi?.getFilterModel() || {}).length;
  }

  ngOnInit(): void {
    // Set the group from the query
    this._activatedRoute.queryParamMap.subscribe((queryParamMap) => {
      this.selectedGroup = queryParamMap.get('group') || '';
      if (this.gridIsReady) {
        this.resetDataSource();
      }
    });

    // Set grid local
    const locale = getSelectedLocale();
    if (locale?.code?.includes('de')) {
      this.localeText = AG_GRID_LOCALE_DE;
    }

    // show/hide empty row in the grid
    this._productsListService.isEmpty$.subscribe((isEmpty) => {
      if (this.gridApi) {
        if (isEmpty) {
          this.gridApi.showNoRowsOverlay();
        } else {
          this.gridApi.hideOverlay();
        }
      }
    });

    // Refresh the grid when new fetch finish
    this._fetchProductsStore.uploadFinished$
      .pipe(
        isNotNullOrUndefined(),
        distinctUntilChanged(),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe(() => {
        this._productsListService.reloadTotalCount();
        this.refreshGrid();
      });

    // Refresh Grid when a country change
    this._countryService
      .observable()
      .pipe(
        skip(1),
        tap(() => this.refreshGrid()),
      )
      .subscribe();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;

    // this.restoreGridState();
    this.resetDataSource();
    this.gridIsReady = true;

    // keep track of the latest columns def after restoring the current state
    this.currentColumnsDefs = this.gridApi.getColumnDefs();
  }

  layoutChanged() {
    // keep track of the latest columns def after restoring the current state
    this.currentColumnsDefs = this.gridApi.getColumnDefs();
  }

  resetDataSource() {
    this.gridDataSource = getGridDataSrouce(this._productsListService, () => ({
      group: this.selectedGroup,
    }));
  }

  groupChanged(groupId: string) {
    if (groupId) {
      this._router.navigate(['./'], {
        relativeTo: this._activatedRoute,
        queryParams: {group: groupId},
      });
    } else {
      this._router.navigate(['./'], {
        relativeTo: this._activatedRoute,
      });
    }
  }

  refreshGrid() {
    if (this.gridDataSource) {
      this.gridApi.setGridOption('datasource', this.gridDataSource);
    }
  }

  clearFilters() {
    this.gridApi.setFilterModel(null);
  }
}
