import {
  Component,
  ContentChild,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {EmRadioSubContentDirective} from './radio-button-sub-content.directive';

@Component({
  selector: '[emRadioButton], em-radio-button',
  template: ` <ng-template #templateRef><ng-content></ng-content> </ng-template
    ><ng-template #subContent
      ><ng-content
        select="[emRadioSubContent], em-radio-sub-content"
      ></ng-content
    ></ng-template>`,
  standalone: false,
})
export class EmRadioButtonComponent<T> {
  @Input() value: T | undefined;
  @Input() disabled: boolean | undefined;
  @ViewChild('templateRef') templateRef:
    | TemplateRef<EmRadioButtonComponent<T>>
    | undefined;
  @ViewChild('subContent') subContent:
    | TemplateRef<EmRadioSubContentDirective>
    | undefined;
  @ContentChild(EmRadioSubContentDirective) subContentDir:
    | EmRadioSubContentDirective
    | undefined;

  constructor() {}
}
