import {createFeatureSelector, createSelector} from '@ngrx/store';
import {selectMerchantModel} from '@em/user-account/data-access';
import {IOnboardingState} from './reducers';

export const selectOnboardingWizard =
  createFeatureSelector<IOnboardingState>('wizard');

export const selectCommonWizard = createSelector(
  selectOnboardingWizard,
  (state) => state.commonWizard,
);

export const selectIsLoading = createSelector(
  selectOnboardingWizard,
  (state) => state.loading,
);

export const selectOnboardingDataLoaded = createSelector(
  selectOnboardingWizard,
  (state) => state.loaded,
);

export const selectOnboardingCompleted = createSelector(
  selectCommonWizard,
  selectMerchantModel,
  (commonWizard, merchantModel) =>
    !!commonWizard.country &&
    !!commonWizard.email &&
    !!commonWizard.language &&
    !!merchantModel?.basicDataConfirmed,
);

export const selectOnboardingGuardInfo = createSelector(
  selectOnboardingDataLoaded,
  selectOnboardingCompleted,
  selectIsLoading,
  (isLoaded, isOnboardingCompleted, isLoading) => ({
    isLoaded,
    isOnboardingCompleted,
    isLoading,
  }),
);
