import {CommonModule} from '@angular/common';
import {Component, Inject} from '@angular/core';
import {MatDialogModule, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TranslateModule} from '@ngx-translate/core';
import {IDialogConfig} from '../../../services/dialog-service/dialog-config';
import {EmPrimaryButtonComponent} from '../../buttons/primary-button/primary-button.component';
import {EmSecondaryButtonComponent} from '../../buttons/secondary-button/secondary-button.component';

@Component({
  selector: 'em-confirm-dialog',
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    EmPrimaryButtonComponent,
    EmSecondaryButtonComponent,
  ],
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class EmConfirmDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) readonly data: IDialogConfig) {}
}
