import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
} from '@angular/core';
import {ControlValueAccessor, NgControl, Validators} from '@angular/forms';
import {EmInputErroStateMatcher} from '../../error-matcher/input-error-matcher';
import {SubscriptSizing} from '@angular/material/form-field';
import {ErrorStateMatcher} from '@angular/material/core';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';

@Component({
  selector: 'em-material-wrapper',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export abstract class EmMaterialWrapperComponent<T>
  implements ControlValueAccessor
{
  @Input()
  set value(value: T | null | undefined) {
    this.controlValue = value;
  }
  get value() {
    return this.controlValue;
  }
  @Input() set disabled(value: string | boolean) {
    this.isDisabled = value;
  }
  @Input() errorMatcher: ErrorStateMatcher = new EmInputErroStateMatcher(this);
  // to be used when migrating to the mdc input to remove the hit space
  @Input() subscriptSizing: SubscriptSizing = 'fixed';
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur: EventEmitter<void> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() focus: EventEmitter<void> = new EventEmitter();

  @Input()
  get required(): boolean {
    return (
      this._required ??
      this.ngControl?.control?.hasValidator(Validators.required) ??
      false
    );
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
  }
  protected _required: boolean | undefined;

  controlValue: T | null | undefined = undefined;
  isDisabled: boolean | string = false;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      ngControl.valueAccessor = this;
    }
  }

  onChange: ((value: T) => void) | undefined;

  onTouched: (() => void) | undefined;

  writeValue(value: T): void {
    this.controlValue = value;
  }

  registerOnChange(fn: (value: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  controlValueChanged(newValue: T) {
    if (this.onChange) {
      this.onChange(newValue);
    } else {
      this.controlValue = newValue;
    }
  }

  controlTouched() {
    if (this.onTouched) {
      this.onTouched();
    }
  }
}
