import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {mapKeys} from 'lodash-es';
import {Observable} from 'rxjs';
import {ScriptInjector} from '../script-injector/script-injector';
import {IGeneralizedWindow, WINDOW} from '../injection-tokens';

export interface ISdk {
  src: string;
  id: string;
  callback?: string;
  async?: boolean;
  sdkRoot: string;
  onload?: true;
  onerror?: true;
  data?: {[key: string]: string | undefined};
}

@Injectable({
  providedIn: 'root',
})
export class ExternalSdkLoaderService {
  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(WINDOW) private readonly _window: IGeneralizedWindow,
  ) {}

  /**
   * Loads an external SDK in a async manner and returns an Observable
   * which resolves as soon as the SDK is available
   * @param sdk
   */
  loadSdk<T>(sdk: ISdk): Observable<T> {
    return new Observable((subscriber) => {
      const loadCallback = () => {
        const root = this._window[sdk.sdkRoot];
        if (!root) {
          subscriber.error(`SDK ${sdk.id} was not properly loaded.`);
        } else {
          subscriber.next(root);
          subscriber.complete();
        }
      };
      if (sdk.callback) {
        this._window[sdk.callback] = loadCallback;
      }
      const errorCallback = (error: string | Event) => {
        subscriber.error(error);
      };

      const dataAttrs = this._getDataAttrs(sdk.data);

      new ScriptInjector(this._document).injectScript(
        sdk.src,
        {
          id: sdk.id,
          async: sdk.async ? 'true' : 'false',
          ...dataAttrs,
        },
        sdk.onload ? loadCallback : undefined,
        sdk.onerror ? errorCallback : undefined,
      );
    });
  }

  private _getDataAttrs(data?: {[p: string]: string | undefined}) {
    if (!data) return {};

    return mapKeys(data, (v, k) => `data-${k}`);
  }
}
