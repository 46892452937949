import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {LanguageService} from '@em/user-account/data-access';
import {LanguageCode} from '@em/shared/util-types';

@Component({
  selector: 'em-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.scss'],
  standalone: false,
})
export class ChangeLanguageComponent {
  @Input() form?: UntypedFormGroup;
  readonly languages = this._language.availableLanguages();

  constructor(private readonly _language: LanguageService) {}

  i18nLanguageKey(languageCode: LanguageCode) {
    return `LANGUAGE_${languageCode.toUpperCase()}`;
  }
}
