import {NgModule, Pipe, PipeTransform} from '@angular/core';
import formatDistanceToNow from '../../functions/format-distance-to-now';

@Pipe({
  name: 'distanceFromNow',
  standalone: false,
})
export class DistanceFromNowPipe implements PipeTransform {
  constructor() {}

  transform(date: Date | undefined): string {
    if (!date) return '';

    return formatDistanceToNow(date, true);
  }
}

@NgModule({
  declarations: [DistanceFromNowPipe],
  exports: [DistanceFromNowPipe],
})
export class DistanceFromNowModule {}
