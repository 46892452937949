import {Injectable} from '@angular/core';
import {TimeSeriesResp} from '@em/shared/api-interface';
import {PlatformGateway} from '@em/shared/api-interface/lib/gateways/performance/platform.gateway';
import {TimeSeries} from '@em/shared/util-types';
import {IPlatform} from '@em/shared/platforms/util';
import {ComponentStore} from '@ngrx/component-store';
import {tapResponse} from '@ngrx/operators';
import {combineLatest, EMPTY, forkJoin, of} from 'rxjs';
import {map, switchMap, catchError} from 'rxjs/operators';
import {CountryCode} from '@em/shared/util-types';

interface stateDuration {
  days: number;
  interval: Duration;
}

export interface CampaignsPerfornamceBarChartsState {
  isLoading: boolean;
  platforms: IPlatform[] | undefined;
  barsCount: number;
  duration: stateDuration;
  country: CountryCode | undefined;
  timeSeries:
    | Array<{
        totalCosts: TimeSeries;
        netRevenue: TimeSeries;
        totalRoas: TimeSeries;
      }>
    | undefined;
}

@Injectable()
export class CampaignsPerfornamceBarChartsStore extends ComponentStore<CampaignsPerfornamceBarChartsState> {
  readonly duration$ = this.select((state) => state.duration);
  readonly platforms$ = this.select((state) => state.platforms);
  readonly timeSeries$ = this.select((state) => state.timeSeries);
  readonly barsCount$ = this.select((state) => state.barsCount);
  readonly isLoading$ = this.select((state) => state.isLoading);
  readonly country$ = this.select((state) => state.country);

  readonly updateDuration = this.updater<stateDuration>(
    (state, duration): CampaignsPerfornamceBarChartsState => ({
      ...state,
      duration,
    }),
  );

  readonly updatePlatforms = this.updater<IPlatform[]>(
    (state, platforms): CampaignsPerfornamceBarChartsState => ({
      ...state,
      platforms,
    }),
  );

  readonly updateCountry = this.updater<CountryCode>(
    (state, country): CampaignsPerfornamceBarChartsState => ({
      ...state,
      country,
    }),
  );

  private readonly loadTimeSeries = this.effect((trigger$) =>
    combineLatest([
      trigger$,
      this.platforms$,
      this.duration$,
      this.barsCount$,
      this.country$,
    ]).pipe(
      switchMap(([, platforms, duration, barsCount, country]) => {
        this.patchState({isLoading: true});
        if (platforms?.length && country) {
          return forkJoin(
            platforms.map((platform) =>
              this._platformGateway
                .getTimeseries({
                  country,
                  number_of_days: duration?.days.toString(),
                  platform: platform.id,
                })
                .pipe(
                  map((res) =>
                    this._buildTimeSeries(
                      res,
                      platform.name,
                      barsCount,
                      duration?.interval,
                    ),
                  ),
                  catchError(() => of(undefined)),
                ),
            ),
          ).pipe(
            tapResponse(
              (result) => {
                const filtered = result.filter((x) => !!x) as Array<{
                  totalCosts: TimeSeries;
                  netRevenue: TimeSeries;
                  totalRoas: TimeSeries;
                }>;
                this.patchState({
                  timeSeries: filtered,
                  isLoading: false,
                });
              },
              () => {
                this.patchState({isLoading: false});
              },
            ),
          );
        } else {
          this.patchState({timeSeries: undefined, isLoading: false});
          return EMPTY;
        }
      }),
    ),
  );

  constructor(private readonly _platformGateway: PlatformGateway) {
    super();
  }

  initialize(
    platforms: IPlatform[],
    duration: stateDuration,
    barsCount: number,
    country: CountryCode | undefined,
  ) {
    this.setState({
      platforms,
      duration,
      timeSeries: undefined,
      barsCount,
      isLoading: false,
      country,
    });

    this.loadTimeSeries();
  }

  private _buildTimeSeries(
    timeSeries: TimeSeriesResp,
    seriesName: string,
    batches: number,
    interval: Duration,
  ) {
    const lastTimestamp = new Date(timeSeries.request_date);
    const netRevenueValues = timeSeries.revenue.map(
      (revenue, idx) => (revenue || 0) - (timeSeries.costs[idx] || 0),
    );

    return {
      totalCosts: TimeSeries.aggregateTo(
        timeSeries.costs,
        batches,
        lastTimestamp,
        interval,
        true,
        seriesName,
      ),
      netRevenue: TimeSeries.aggregateTo(
        netRevenueValues,
        batches,
        lastTimestamp,
        interval,
        true,
        seriesName,
      ),
      totalRoas: TimeSeries.aggregateTo(
        timeSeries.roas,
        batches,
        lastTimestamp,
        interval,
        false,
        seriesName,
      ),
    };
  }
}
