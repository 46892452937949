import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DatafeedGateway} from '@em/shared/api-interface/lib/gateways/datafeed.gateway';
import {
  ComponentStoreBase,
  ComponentStoreStateBase,
} from '@em/shared/util-types';
import {PluginName} from '@em/shop-system/data-access-settings';
import {tapResponse} from '@ngrx/operators';
import {Observable} from 'rxjs';
import {filter, switchMap, withLatestFrom} from 'rxjs/operators';

type errorTypes = 'loadPlugin';

export interface DatafeedState extends ComponentStoreStateBase<errorTypes> {
  pluginName?: PluginName;

  pluginLoaded?: boolean;
}

@Injectable()
export class DatafeedStore extends ComponentStoreBase<
  DatafeedState,
  errorTypes
> {
  readonly pluginLoaded$: Observable<boolean | undefined> = this.select(
    (state) => state.pluginLoaded,
  );

  readonly pluginName$: Observable<PluginName | undefined> = this.select(
    this.state$,
    this.pluginLoaded$.pipe(filter((loaded) => !!loaded)),
    (state) => state.pluginName,
  );

  constructor(private readonly _datafeedGateway: DatafeedGateway) {
    super({
      isLoading: false,
    });
  }

  readonly loadPlugin = this.effect<boolean | void>((forceLoading$) =>
    forceLoading$.pipe(
      withLatestFrom(this.pluginLoaded$),
      filter(
        ([forceLoading, pluginLoaded]) =>
          forceLoading === true || !pluginLoaded,
      ),
      switchMap(() => {
        this.startLoading();

        return this._datafeedGateway.getPlugin().pipe(
          tapResponse(
            (response) => {
              this.patchState({
                pluginName: response?.plugin,
                isLoading: false,
                pluginLoaded: true,
              });
            },
            (error: HttpErrorResponse) => {
              this.addError({
                httpError: error,
                errorMessage: {
                  key: 'loadPlugin',
                },
                statePayload: {
                  pluginLoaded: true,
                },
              });
            },
          ),
        );
      }),
    ),
  );
}
