import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'em-value-trend',
  template: `
    <span *ngIf="value > 0" class="text-success-500">&#8593;</span>
    <span *ngIf="value < 0" class="text-danger-500">&#8595;</span>
  `,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmValueTrendComponent {
  @Input() value = 0;
}
