import {CommonModule} from '@angular/common';
import {Component, Inject, OnInit, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {LocalUserDataService} from '@em/auth/data-access';
import {
  EmIconModule,
  EmSpinnerModule,
  EmTextButtonComponent,
} from '@em/shared/ui';
import {Contract, ShopifyGateway} from '@em/subscription/api-interface';
import {TranslateModule} from '@ngx-translate/core';
import {catchError, of, tap} from 'rxjs';

@Component({
  selector: 'em-shopify-payment-modal',
  templateUrl: './shopify-payment-modal.component.html',
  styleUrls: ['./shopify-payment-modal.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    EmSpinnerModule,
    EmIconModule,
    EmTextButtonComponent,
  ],
})
export class EmShopifyPaymentModalComponent implements OnInit {
  private readonly _matDialog = inject(MatDialog);
  private readonly _shopifyGateway = inject(ShopifyGateway);
  private readonly _localStorage = inject(LocalUserDataService);

  protected isLoading = true;
  protected showFailedMessage = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      contract: Contract;
    },
  ) {}

  ngOnInit(): void {
    if (this.data.contract) {
      // Add new Subscriptoin
      this._shopifyGateway
        .postCheckout(this.data.contract)
        .pipe(
          tap((result) => {
            if (
              result.shopify.confirmation_url &&
              result.shopify.subscription_id
            ) {
              this._localStorage.set(
                'shopify_subscription_id',
                result.shopify.subscription_id,
              );
              window.location.href = result.shopify?.confirmation_url;
            } else {
              this.isLoading = false;
              this.showFailedMessage = true;
            }
          }),
          catchError(() => {
            this.showFailedMessage = true;
            this.isLoading = false;
            return of(false);
          }),
        )
        .subscribe();
    } else {
      this.showFailedMessage = true;
      this.isLoading = false;
    }
  }

  close() {
    this._matDialog.closeAll();
  }
}
