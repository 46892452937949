import {Component, forwardRef, Input, OnChanges} from '@angular/core';
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import {getOptionType} from '../util';
import {CommonModule} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {TranslateModule} from '@ngx-translate/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {IOptionDefinition} from '../option-definition';
import {ITypedControlValueAccessor} from '@em/shared/util-types';
import {
  IFilterOptionValues,
  optionValueType,
} from '@em/data-feed/data-access-products';
import {IProductFilterDefinition} from '../product-filter-definition';
import {ChangesObj} from '@em/shared/util-types';
import {castStringValues} from '../cast-string-values';
import {IOptionViewModel} from '../filter-item-options/filter-item-options.component';

// Ideally this translation should be done in a higher level in the component hierarchy.
const excludeTranslation = [{key: 'custom_labels', option: 'operand'}];

@Component({
  selector: 'em-dropdown-filter-item-options',
  templateUrl: './dropdown-filter-item-options.component.html',
  styleUrls: ['./dropdown-filter-item-options.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatSlideToggleModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownFilterItemOptionsComponent),
      multi: true,
    },
  ],
})
export class DropdownFilterItemOptionsComponent
  implements ITypedControlValueAccessor<IFilterOptionValues>, OnChanges
{
  disabled = false;
  vms: IOptionViewModel[] = [];

  @Input() definition?: IProductFilterDefinition;
  private _currentValues: IFilterOptionValues = {};
  private _onChangeCallback?: (
    val: IFilterOptionValues | null | undefined,
  ) => void;
  private _onTouchedCallback?: () => void;

  get placeholder() {
    return `CAMPAIGN_PERFORMANCE_FILTER_${this.definition?.key.toUpperCase()}_VALUE_PLACEHOLDER`;
  }

  constructor() {}

  ngOnChanges(
    changes: ChangesObj<DropdownFilterItemOptionsComponent, 'definition'>,
  ) {
    if (changes.definition && changes.definition.currentValue) {
      const definition = changes.definition.currentValue;
      this.vms = this._generateViewModel(definition);
    }
  }

  getOptionValue(key: string): optionValueType | undefined {
    return this._currentValues ? this._currentValues[key] : undefined;
  }

  setOptionValue(
    key: string,
    value: optionValueType | undefined,
    optionType: IOptionDefinition['type'],
  ) {
    if (!value) {
      delete this._currentValues[key];
    } else {
      this._currentValues[key] = castStringValues(value, optionType);
    }

    this.onTouched();
    if (this._onChangeCallback) this._onChangeCallback(this._currentValues);
  }

  registerOnChange(
    fn: (val: IFilterOptionValues | null | undefined) => void,
  ): void {
    this._onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: IFilterOptionValues | null | undefined): void {
    this._currentValues = obj || {};
    if (this.definition) {
      this.vms = this._generateViewModel(this.definition);
    }
  }

  onTouched() {
    if (this._onTouchedCallback) this._onTouchedCallback();
  }

  trackByFn(index: number, obj: IOptionViewModel) {
    return obj.key;
  }

  i18nDropdownKey(value: string, optionName?: string): string {
    const isExcluded = excludeTranslation.find(
      (item) => item.key === this.definition?.key && item.option === optionName,
    );
    return isExcluded
      ? value
      : `CAMPAIGN_PERFORMANCE_FILTER_${value.toUpperCase()}_DROPDOWN_LABEL`;
  }

  hasLongText(key: string): boolean {
    return ['exclude_products'].includes(key);
  }

  booleanOptions() {
    return this.vms.filter((x) => x.inputType === 'boolean');
  }

  protected _generateViewModel(definition: IProductFilterDefinition) {
    return definition.availableOptions
      .map((o) => ({
        key: o.name,
        definition: this._prepareOption(o, o.name),
        inputType: getOptionType(o),
      }))
      .reverse();
  }

  private _prepareOption(option: IOptionDefinition, key: string) {
    if (option.type === 'array') {
      // Include items already selected but no more availabe in the definition options
      const definitionOptions = option.selectableValues;
      const selectedOptions = (this.getOptionValue(key) as string[]) || [];

      option.selectableValues.push(
        ...selectedOptions.filter(
          (selectedOption) => definitionOptions.indexOf(selectedOption) === -1,
        ),
      );
    }

    return option;
  }
}
