import {Injectable, inject} from '@angular/core';
import {AutomaticFiltersGateway} from '@em/shared/api-interface';
import {saveAs} from 'file-saver-es';
import {tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class DownloadGroupProductsService {
  private readonly _autmaticFilterGateway = inject(AutomaticFiltersGateway);

  downloadGroupProducts(uuid: string, groupName: string) {
    return this._autmaticFilterGateway
      .postProductsCsv(
        {
          uuid,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {responseType: 'blob'} as any,
      )
      .pipe(
        tap((response) => {
          const blobData = response;
          const filename =
            groupName.replace(/[^a-z0-9]/gi, '_').toLowerCase() +
            '-products.csv';
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          saveAs(blobData as any, filename);
        }),
      );
  }
}
