import {CommonModule} from '@angular/common';
import {Component, EventEmitter, Output, inject} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import {ProductGroupType} from '@em/data-feed/data-access-products';
import {
  EmIconModule,
  EmPrimaryButtonComponent,
  EmRadioModule,
  EmSelectModule,
  EmSpinnerModule,
  EmTextButtonComponent,
} from '@em/shared/ui';
import {TranslateModule} from '@ngx-translate/core';
import {map} from 'rxjs';
import {ManageGroupsService} from '../../services/manage-groups/manage-groups.service';

@Component({
  selector: 'em-new-product-group-dialog',
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MatDialogModule,
    EmSpinnerModule,
    EmRadioModule,
    EmSelectModule,
    EmIconModule,
    EmTextButtonComponent,
    EmPrimaryButtonComponent,
  ],
  templateUrl: './new-product-group-dialog.component.html',
  styleUrls: ['./new-product-group-dialog.component.scss'],
})
export class NewProductGroupDialogComponent {
  @Output() save = new EventEmitter<{
    type: ProductGroupType;
    templateName?: string;
  }>();
  private readonly _manageGroupsService = inject(ManageGroupsService);
  private readonly _dialogRef = inject(
    MatDialogRef<NewProductGroupDialogComponent>,
  );
  private readonly _matDialogData = inject(MAT_DIALOG_DATA);

  groupType: ProductGroupType = 'custom';
  template = '';
  missingTemplate = false;

  readonly groupTemplates$ = this._manageGroupsService.getGroupTemplates();
  readonly isAdding$ = this._manageGroupsService.isAdding$;
  readonly allTemplatesAdded$ = this.groupTemplates$.pipe(
    map((templates) => !templates.find((template) => !template.isAdded)),
  );

  constructor() {
    this.groupType = this._matDialogData['type'] || 'custom';
  }

  submit() {
    if (this.groupType === 'custom') {
      this.save.emit({type: 'custom'});
      this._dialogRef.close();
    } else if (this.groupType === 'template') {
      if (this.template) {
        this.save.emit({type: 'template', templateName: this.template});
        this._dialogRef.close();
      } else {
        this.missingTemplate = true;
      }
    }
  }
}
