import {Component, HostBinding, Input, NgModule} from '@angular/core';

@Component({
  selector: 'em-three-dots-loading',
  templateUrl: './three-dots-loading.component.html',
  styleUrls: ['./three-dots-loading.component.scss'],
  standalone: false,
})
export class EmThreeDotsLoadingComponent {
  @HostBinding('class')
  @Input()
  size: 'lg' | 'md' | 'sm' | 'xs' = 'md';

  @HostBinding('class.over-content')
  @Input()
  overContent: boolean | null | undefined;
  constructor() {}
}

@NgModule({
  declarations: [EmThreeDotsLoadingComponent],
  exports: [EmThreeDotsLoadingComponent],
})
export class EmThreeDotsLoadingModule {}
