/* tslint:disable */
/**
* This file was automatically generated.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the generate-code script again.
*/
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DeleteReq as DeleteAutomaticFiltersReq} from '../types/request-objects/automatic-filters/delete';
import {DeleteResp as DeleteAutomaticFiltersResp} from '../types/view-models/automatic-filters/delete';
import {GetReq as GetAutomaticFiltersReq} from '../types/request-objects/automatic-filters/get';
import {GetResp as GetAutomaticFiltersResp} from '../types/view-models/automatic-filters/get';
import {PostAutoMinPriceCsvReq} from '../types/request-objects/automatic-filters/post-auto-min-price-csv';
import {PostReq as PostAutomaticFiltersReq} from '../types/request-objects/automatic-filters/post';
import {PostResp as PostAutomaticFiltersResp} from '../types/view-models/automatic-filters/post';
import {PostFilterPreviewReq} from '../types/request-objects/automatic-filters/post-filter-preview';
import {PostFilterPreviewResp} from '../types/view-models/automatic-filters/post-filter-preview';
import {PostProductsCsvReq} from '../types/request-objects/automatic-filters/post-products-csv';

@Injectable({
  providedIn: 'root',
})
export class AutomaticFiltersGateway {
  constructor(private _http: HttpClient) {}

  deleteAutomaticFilters(params: DeleteAutomaticFiltersReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<DeleteAutomaticFiltersResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.delete<DeleteAutomaticFiltersResp>('/emarketing_api/automatic_filters/', {...options, params: request});
  }

  getAutomaticFilters(params: GetAutomaticFiltersReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetAutomaticFiltersResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetAutomaticFiltersResp>('/emarketing_api/automatic_filters/', {...options, params: request});
  }

  postAutoMinPriceCsv(params: PostAutoMinPriceCsvReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<undefined> {
    return this._http.post<undefined>('/emarketing_api/automatic_filters/auto_min_price_csv', params, options);
  }

  postAutomaticFilters(params: PostAutomaticFiltersReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PostAutomaticFiltersResp> {
    return this._http.post<PostAutomaticFiltersResp>('/emarketing_api/automatic_filters/', params, options);
  }

  postFilterPreview(params: PostFilterPreviewReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PostFilterPreviewResp> {
    return this._http.post<PostFilterPreviewResp>('/emarketing_api/automatic_filters/filter_preview', params, options);
  }

  postProductsCsv(params: PostProductsCsvReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<undefined> {
    return this._http.post<undefined>('/emarketing_api/automatic_filters/products_csv', params, options);
  }
}
