import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LocalUserDataService, SetupStatusService} from '@em/auth/data-access';
import {toStatusEnum, WorkflowStatusEnum} from './helper';
import {
  WorkflowCompletedNotification,
  WORKFLOW_COMPLETED_NOTIFICATION_TYPES,
} from '@em/server-notifications';
import {NotificationModel} from '@em/server-notifications';
import {
  DatafeedResponse,
  MerchantsGateway,
  WorkflowsGateway,
} from '@em/shared/api-interface';
import {DatafeedGateway} from '@em/shared/api-interface/lib/gateways/datafeed.gateway';
import {
  ComponentStoreBase,
  ComponentStoreStateBase,
} from '@em/shared/util-types';
import {tapResponse} from '@ngrx/operators';
import {combineLatest, Observable, of} from 'rxjs';
import {catchError, filter, map, switchMap} from 'rxjs/operators';
import {DatafeedStore} from '../datafeed-store/datafeed.store';
import {NotificationsService} from '@em/server-notifications';
import {
  IWorkflowEntry,
  WorkflowActivityFactory,
} from '@em/activities/data-access';
import {PartnerModel, PartnerService} from '@em/shared/white-label';

type errorTypes =
  | 'loadingStatus'
  | 'loadingDatafeed'
  | 'createChallenge'
  | 'createToken'
  | 'loadingDatafeedinfo';

interface ShopSystemSetupState extends ComponentStoreStateBase<errorTypes> {
  onlyDatafeedPlugin?: boolean;
  callbacksEnabled?: boolean;
  partner?: PartnerModel;
  workflows?: IWorkflowEntry[];
  datafeedSettings?: DatafeedResponse;
  isConnected?: boolean;
  isDatafeedLoading?: boolean;
}

@Injectable()
export class ShopSystemSetupStore extends ComponentStoreBase<
  ShopSystemSetupState,
  errorTypes
> {
  readonly callbacksEnabled$: Observable<boolean | undefined> = this.select(
    (state) => state.callbacksEnabled,
  );

  readonly showAutoConnectBtn$: Observable<boolean | undefined> = this.select(
    (state) => state.callbacksEnabled && state.onlyDatafeedPlugin,
  );

  readonly isEmarketingPartner$: Observable<boolean | undefined> = this.select(
    (state) => state.partner?.isEmarketing(),
  );

  readonly workflow$: Observable<IWorkflowEntry | undefined> = this.select(
    (state) => state.workflows?.[0],
  );

  readonly isDatafeedLoading$: Observable<boolean | undefined> = this.select(
    (state) => state.isDatafeedLoading,
  );

  readonly datafeedSettings$: Observable<DatafeedResponse | undefined> =
    this.select((state) => state.datafeedSettings);

  readonly isConnected$: Observable<boolean | undefined> = this.select(
    (state) => state.isConnected,
  );

  readonly canAutoConnect$: Observable<boolean> = this.select(
    (state) =>
      !!(
        state.callbacksEnabled &&
        (state.workflows?.[0]
          ? toStatusEnum(state?.workflows[0].status) !==
            WorkflowStatusEnum.requested
          : true)
      ),
  );

  constructor(
    private readonly _merchantsGateway: MerchantsGateway,
    private readonly _workflowsGateway: WorkflowsGateway,
    private readonly _workflowActivity: WorkflowActivityFactory,
    private readonly _datafeedGateway: DatafeedGateway,
    private readonly _partner: PartnerService,
    private readonly _localUserData: LocalUserDataService,
    private readonly _notifications: NotificationsService,
    private readonly _datafeedStore: DatafeedStore,
    private readonly _setupStatusService: SetupStatusService,
  ) {
    super({
      isLoading: true,
    });

    this.loadSetupStatus();
  }

  readonly loadSetupStatus = this.effect<void>((trigger$) =>
    trigger$.pipe(
      switchMap(() => {
        this.startLoading();

        return combineLatest([
          this._merchantsGateway.getSettings(),
          this._partner.observable(),
          this._setupStatusService.observable(),
          this._workflowsGateway.getWorkflows({
            workflow_type: 'UseCases::Workflows::AuthorizationWorkflow',
          }),
          this._datafeedGateway
            .getSettings()
            .pipe(catchError(() => of(undefined))),
        ]).pipe(
          tapResponse(
            ([merchant, partner, setupStatus, workflows, datafeedSettings]) => {
              this.patchState({
                callbacksEnabled: merchant.callbacks_enabled,
                onlyDatafeedPlugin:
                  !setupStatus?.repricing.isSetup && !setupStatus?.hasGoogleSPD,
                partner,
                workflows: workflows.map((workflow) =>
                  this._workflowActivity.buildActivity(workflow),
                ),
                isConnected: !!setupStatus.datafeed.isSetup,
                isLoading: false,
              });

              if (
                !merchant.callbacks_enabled &&
                (!datafeedSettings ||
                  (datafeedSettings && !datafeedSettings.shop_token))
              ) {
                this.createToken();
              }

              if (!workflows?.[0]) {
                this.monitorNotifications();
              }
            },
            (error: HttpErrorResponse) => {
              this.addError({
                httpError: error,
                errorMessage: {
                  key: 'loadingStatus',
                },
              });
            },
          ),
        );
      }),
    ),
  );

  readonly reloadData = this.effect<void>((trigger$) =>
    trigger$.pipe(
      switchMap(() => {
        this.startLoading();
        this._datafeedStore.loadPlugin(true);
        this._setupStatusService.invalidateSetupStatus();
        return combineLatest([
          this._workflowsGateway.getWorkflows({
            workflow_type: 'UseCases::Workflows::AuthorizationWorkflow',
          }),
          this._datafeedGateway
            .getSettings()
            .pipe(catchError(() => of(undefined))),
        ]).pipe(
          tapResponse(
            ([workflows, datafeedSettings]) => {
              this.patchState({
                workflows: workflows.map((workflow) =>
                  this._workflowActivity.buildActivity(workflow),
                ),
                datafeedSettings,
                isLoading: false,
              });
            },
            (error: HttpErrorResponse) => {
              this.addError({
                httpError: error,
                errorMessage: {
                  key: 'loadingDatafeed',
                },
              });
            },
          ),
        );
      }),
    ),
  );

  readonly initAutomaticConnection = this.effect<void>((trigger$) =>
    trigger$.pipe(
      switchMap(() => {
        this.startLoading();
        return this._merchantsGateway
          .postChallenge({
            challenge: null,
          })
          .pipe(
            tapResponse(
              () => {
                this.reloadData();
                this._localUserData.remove('challenge');
              },
              (error: HttpErrorResponse) => {
                this.addError({
                  httpError: error,
                  errorMessage: {
                    key: 'createChallenge',
                  },
                });
              },
            ),
          );
      }),
    ),
  );

  readonly createToken = this.effect<void>((trigger$) =>
    trigger$.pipe(
      switchMap(() => {
        this.startLoading();
        return this._datafeedGateway.putSettings({}).pipe(
          tapResponse(
            () => {
              this.reloadData();
            },
            (error: HttpErrorResponse) => {
              this.addError({
                httpError: error,
                errorMessage: {
                  key: 'createToken',
                },
              });
            },
          ),
        );
      }),
    ),
  );

  readonly monitorNotifications = this.effect<void>((trigger$) =>
    trigger$.pipe(
      switchMap(() =>
        this._notifications.newNotificationListObservable().pipe(
          map((notifications) =>
            notifications.filter(
              (n) =>
                n.key ===
                  'UseCases::Workflows::Notifications::WorkflowCompleted' &&
                this._onlyAuthorizationWorkflowNotifications(n),
            ),
          ),
          filter((notifications) => notifications.length > 0),
          tapResponse(
            () => {
              this.reloadData();
            },
            (error: HttpErrorResponse) => {
              this.addError({
                httpError: error,
                errorMessage: {
                  key: 'createToken',
                },
              });
            },
          ),
        ),
      ),
    ),
  );

  private _onlyAuthorizationWorkflowNotifications(
    notification: NotificationModel,
  ) {
    if (notification instanceof WorkflowCompletedNotification) {
      return (
        notification.workflowType ===
        WORKFLOW_COMPLETED_NOTIFICATION_TYPES.AuthorizationWorkflow
      );
    }
    return false;
  }
}
