import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
} from '@angular/core';
import {FormsModule, NgControl} from '@angular/forms';
import {FloatLabelType} from '@angular/material/form-field';
import {EmMaterialWrapperComponent} from '../material-wrapper/material-wrapper.component';
import {EmSelectModule} from '../select/select.module';
import {EmCountryFlagModule} from '../country-flag/country-flag.module';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';

const defaultCompareWith = (a: unknown, b: unknown) => a === b;
@Component({
  selector: 'em-select-country',
  templateUrl: './select-country.component.html',
  styleUrls: ['./select-country.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    EmSelectModule,
    EmCountryFlagModule,
  ],
})
export class EmSelectCountryComponent<T extends string>
  extends EmMaterialWrapperComponent<T>
  implements AfterViewInit
{
  @Input() availableCountries: T[] | null | undefined = [];
  @Input() label: string | undefined;
  @Input() placeholder: string | undefined;
  @Input() hint: string | undefined;
  @Input() addAllOption = false;
  // @Input() multiple: boolean | undefined;
  @Input() floatLabel: FloatLabelType = 'auto';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() compareWith: (o1: any, o2: any) => boolean = defaultCompareWith;
  @Output() selectionChange: EventEmitter<T> = new EventEmitter();

  constructor(
    @Optional() @Self() public override ngControl: NgControl,
    protected readonly ref: ChangeDetectorRef,
  ) {
    super(ngControl);
  }

  ngAfterViewInit(): void {
    this.ref.detectChanges();
  }
}
