import {HttpErrorResponse} from '@angular/common/http';
import {createAction, props} from '@ngrx/store';
import {CountryCode, LanguageCode} from '@em/shared/util-types';
import {OnboardingData} from './reducers';

export const loadOnboardingData = createAction('[Wizard] load onboarding data');
export const loadOnboardingDataSuccess = createAction(
  '[Wizard] load onboarding data success',
  props<{data: OnboardingData}>(),
);

export const loadOnboardingDataFailure = createAction(
  '[Wizard] load onboarding data failure',
  props<{error: HttpErrorResponse}>(),
);

export const finishCommonWizard = createAction(
  '[Common Wizard] finish',
  props<{
    data: {
      email: string;
      country: CountryCode;
      language: LanguageCode;
      commonConfirmed: boolean;
    };
    redirectAfterSave?: boolean;
  }>(),
);

export const finishCommonWizardSuccess = createAction(
  '[Wizard] save onboarding data success',
  props<{
    data: {
      email: string;
      country: CountryCode;
      language: LanguageCode;
      commonConfirmed: boolean;
    };
    redirectAfterSave?: boolean;
  }>(),
);

export const saveMerchantSettingsFailure = createAction(
  '[Common Wizard] save merchant settings failure',
  props<{error: HttpErrorResponse}>(),
);

export const noop = createAction('[] noop');
