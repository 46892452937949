import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
} from '@angular/core';

@Component({
  selector: 'em-box',
  template: `
    <div class="box-title" *ngIf="title">{{ title }}</div>
    <ng-content></ng-content>
  `,
  styleUrls: ['./box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class EmBoxComponent {
  @Input() title = '';
  @Input() size: 'md' | 'sm' = 'md';

  @HostBinding('class') get class() {
    return 'size-' + this.size;
  }
}
