import {CommonModule} from '@angular/common';
import {Component, Input, NgModule} from '@angular/core';
import {MatMenuModule} from '@angular/material/menu';
import {RouterModule} from '@angular/router';
import {EmButtonModule, EmExternalLinkDirective} from '@em/shared/ui';
import {TranslateModule} from '@ngx-translate/core';
import {IMenuItem} from '../nav-menu-items-types';

@Component({
  selector: 'em-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  standalone: false,
})
export class UserMenuComponent {
  @Input() icon: string | undefined;
  @Input() title = '';
  @Input() minWidth: number | undefined;
  @Input() menuItems: IMenuItem[] | undefined;
  @Input() type: 'icon-button' | 'button' = 'icon-button';

  constructor() {}
}

@NgModule({
  declarations: [UserMenuComponent],
  exports: [UserMenuComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    TranslateModule,
    EmButtonModule,
    EmExternalLinkDirective,
  ],
})
export class UserMenuModule {}
