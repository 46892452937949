import {CommonModule} from '@angular/common';
import {Component, OnInit, inject} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalUserDataService} from '@em/auth/data-access';
import {
  EmBoxModule,
  EmIconModule,
  EmPrimaryButtonComponent,
  EmSpinnerModule,
} from '@em/shared/ui';
import {TranslateModule} from '@ngx-translate/core';

import {StripePaymentStatusStore} from '@em/subscription/data-access';
import {Observable, map, take} from 'rxjs';

@Component({
  selector: 'em-stripe-payment-status-page',
  templateUrl: './stripe-payment-status-page.component.html',
  styleUrls: ['./stripe-payment-status-page.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    EmSpinnerModule,
    EmBoxModule,
    EmIconModule,
    EmPrimaryButtonComponent,
  ],
  providers: [StripePaymentStatusStore],
})
export class EmStripePaymentStatusPageComponent implements OnInit {
  private readonly _activatedRoute = inject(ActivatedRoute);
  private readonly _router = inject(Router);
  private readonly _localStorage = inject(LocalUserDataService);
  private readonly _statusStore = inject(StripePaymentStatusStore);

  protected readonly isLoading$ = this._statusStore.isLoading$;
  protected readonly paymentSuccess$ = this._statusStore.paymentSuccess$;
  protected readonly paymentFailed$ = this._statusStore.paymentFailed$;

  private readonly _sessionId$: Observable<string> =
    this._activatedRoute.queryParamMap.pipe(
      map((paramMap) => paramMap.get('session_id') || ''),
      take(1),
    );

  ngOnInit(): void {
    const isContract = this._localStorage.get('payment_is_contract');
    this._statusStore.loadCheckoutStatus(
      this._sessionId$.pipe(
        map((id) => ({sessionId: id, pollContract: !!isContract})),
      ),
    );
  }

  continue() {
    const redirectUrl = this._localStorage.get('payment_redirect_url');
    if (redirectUrl) {
      this._router.navigateByUrl(redirectUrl, {replaceUrl: true});
      this._localStorage.remove('payment_redirect_url');
    } else {
      this._router.navigateByUrl('/payment/subscription', {replaceUrl: true});
    }
  }

  tryAgain() {
    const redirectUrl = this._localStorage.get('payment_redirect_url');
    this._router.navigateByUrl(redirectUrl || '/payment/subscription', {
      replaceUrl: true,
    });
  }
}
