import {CommonModule} from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {
  GOOGLE_PRICES_FILTER_TYPES,
  GooglePricesFilterType,
  IGenericFilterSetting,
  optionValueType,
} from '@em/data-feed/data-access-products';
import {TranslateModule} from '@ngx-translate/core';
import {IOptionDefinition} from '../option-definition';
import {IProductFilterDefinition} from '../product-filter-definition';
import {getOptionType} from '../util';
import {castStringValues} from '../cast-string-values';
import {IOptionViewModel} from '../filter-item-options/filter-item-options.component';

const I18NFilterTypes: {[key in GooglePricesFilterType]: string} = {
  suggested_price: 'COLUMN_SUGGESTED_PRICE',
  predicted_impressions_change_fraction:
    'COLUMN_PREDICTED_IMPRESSIONS_CHANGE_FRACTION',
  predicted_conversions_change_fraction:
    'COLUMN_PREDICTED_CONVERSIONS_CHANGE_FRACTION',
  predicted_clicks_change_fraction: 'COLUMN_PREDICTED_CLICKS_CHANGE_FRACTION',
  benchmark_price: 'COLUMN_BENCHMARK_PRICE',
};

@Component({
  selector: 'em-google-prices-options',
  templateUrl: './google-prices-options.component.html',
  styleUrls: ['./google-prices-options.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
  ],
})
export class GooglePricesOptionsComponent implements OnChanges {
  @Input() form?: FormGroup;
  @Input() definition?: IProductFilterDefinition;
  @Output() valueChanged = new EventEmitter<IGenericFilterSetting>();

  readonly filterKeyOptions = GOOGLE_PRICES_FILTER_TYPES;
  disabled = false;
  operatorOption?: IOptionViewModel;
  valueOption?: IOptionViewModel;

  private readonly _currentValues: Partial<IGenericFilterSetting> = {};

  get keyFormControl() {
    return this.form?.get('key');
  }
  get optionsFormControl() {
    return this.form?.get('options');
  }
  get optionsValue() {
    return this.form?.get('options')?.value;
  }

  get showValueField() {
    const value = this.optionsValue.operator;
    return value !== 'is_empty' && value !== 'not_empty';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['form'].currentValue && changes['definition'].currentValue) {
      this._generateViewModel(changes['definition'].currentValue);
    }
  }

  getOptionValue(key: string): optionValueType | undefined {
    return this.optionsValue[key] || undefined;
  }

  i18nDropdownKey(value: string): string {
    return `CAMPAIGN_PERFORMANCE_FILTER_${value.toUpperCase()}_DROPDOWN_LABEL`;
  }

  protected _generateViewModel(definition: IProductFilterDefinition) {
    const operatorDef = definition.availableOptions.find(
      (o) => o.name === 'operator',
    );
    const valueDef = definition.availableOptions.find(
      (o) => o.name === 'value',
    );

    if (operatorDef) {
      this.operatorOption = this._buildOption(operatorDef);
    }
    if (valueDef) {
      this.valueOption = this._buildOption(valueDef);
    }
  }

  protected _buildOption(option: IOptionDefinition): IOptionViewModel {
    return {
      key: option.name,
      definition: this._prepareOption(option, option.name),
      inputType: getOptionType(option),
    };
  }

  private _prepareOption(option: IOptionDefinition, key: string) {
    if (option.type === 'array') {
      // Include items already selected but no more availabe in the definition options
      const definitionOptions = option.selectableValues;
      const selectedOptions = (this.getOptionValue(key) as string[]) || [];

      option.selectableValues.push(
        ...selectedOptions.filter(
          (selectedOption) => definitionOptions.indexOf(selectedOption) === -1,
        ),
      );
    }

    return option;
  }

  i18nFilterKey(key: GooglePricesFilterType) {
    return I18NFilterTypes[key];
  }

  optionValueChange(
    key: string,
    value: string | number | undefined,
    type: string = 'string',
  ) {
    const oldValue = this.form?.get('options')?.value;
    this.optionsFormControl?.setValue({
      ...oldValue,
      [key]: castStringValues(value, type),
    });

    if (key === 'operand' && (value === 'is_empty' || value === 'not_empty')) {
      this.optionValueChange(value, undefined);
    }
  }

  keyValueChanged(newKey: string) {
    this.keyFormControl?.setValue(newKey);
  }
}
