import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'em-number-kpi',
  imports: [CommonModule, TranslateModule],
  templateUrl: './number-kpi.component.html',
  styleUrls: ['./number-kpi.component.scss', '../kpi.shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmNumberKpiComponent {
  @Input() performanceLabel?: string;
  @Input() performanceValue?: number | null;

  constructor() {}
}
