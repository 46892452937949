import {some} from 'lodash-es';
import {each} from 'lodash-es';

export class Category<TI, TC> {
  id: TI;
  content: TC;
  parent: Category<TI, TC> | null;
  children: Array<Category<TI, TC>>;

  constructor(id: TI, content: TC) {
    this.id = id;
    this.content = content;
    this.children = [];
    this.parent = null;
  }

  addChild(child: Category<TI, TC>): Category<TI, TC> {
    this.children.push(child);
    child.parent = this;
    return child;
  }

  createChild(id: TI, content: TC): Category<TI, TC> {
    const child = new Category(id, content);
    return this.addChild(child);
  }

  isLeaf(): boolean {
    return this.children.length === 0;
  }

  isRoot(): boolean {
    return !this.parent;
  }

  eachNode(fun: (x: Category<TI, TC>) => void): Category<TI, TC> {
    each(this.children, (x) => {
      x.eachNode(fun);
    });

    fun(this);
    return this;
  }

  findNode(
    fun: (x: Category<TI, TC>) => boolean,
  ): Category<TI, TC> | undefined {
    if (fun(this)) {
      return this;
    }

    let found;
    this.children.find((x) => {
      found = x.findNode(fun);
      return !!found;
    });

    return found;
  }

  getAncestors(): Array<Category<TI, TC>> {
    if (!this.parent) {
      return [];
    }

    return [this.parent].concat(this.parent.getAncestors());
  }

  getLevel(): number {
    return this.getAncestors().length;
  }

  hasLowerLevels(): boolean {
    if (!this.parent) {
      return true;
    }

    return some(this.parent.children, (c) => !c.isLeaf());
  }
}
