import {Component, Input, TemplateRef, ViewChild} from '@angular/core';

@Component({
  selector: '[emOption], em-option',
  template: '<ng-template #templateRef><ng-content></ng-content></ng-template>',
  standalone: false,
})
export class EmOptionComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() value: any | undefined;
  @Input() disabled: boolean | undefined;
  @ViewChild('templateRef') templateRef:
    | TemplateRef<EmOptionComponent>
    | undefined;

  constructor() {}
}
