import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IMerchantFeatureState} from './reducers';

export const selectMerchantFeatureState =
  createFeatureSelector<IMerchantFeatureState>('merchant');

export const selectMerchantModel = createSelector(
  selectMerchantFeatureState,
  (state: IMerchantFeatureState) => state.data,
);
