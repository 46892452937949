import {Injectable, inject} from '@angular/core';
import {CheckoutStatus, StripeGateway} from '@em/subscription/api-interface';
import {ComponentStore} from '@ngrx/component-store';
import {tapResponse} from '@ngrx/operators';
import {Observable, combineLatest, interval} from 'rxjs';
import {
  filter,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {ContractService} from '../contract/contract.service';

const POLL_ACTIVE_TRIES = 5;
const POLL_INTERVAL = 3000;

interface StripePaymentStatusState {
  checkoutStatus?: CheckoutStatus;
  pollContract: boolean;
  pollTries: number;
  isLoading: boolean;
}

@Injectable()
export class StripePaymentStatusStore extends ComponentStore<StripePaymentStatusState> {
  private readonly _stripeGateway = inject(StripeGateway);
  private readonly _contractService = inject(ContractService);

  checkoutStatus$ = this.select((s) => s.checkoutStatus);
  pollContract$ = this.select((s) => s.pollContract);
  pollTries$ = this.select((s) => s.pollTries);
  isLoading$: Observable<boolean> = this.select((s) => s.isLoading);

  paymentSuccess$ = combineLatest([
    this.checkoutStatus$,
    this._contractService.pendingContract$,
    this.pollContract$,
  ]).pipe(
    map(
      ([status, pendingContract, pollContract]) =>
        status === 'complete' && (!pollContract || !pendingContract),
    ),
  );

  paymentFailed$ = combineLatest([
    this.checkoutStatus$,
    this._contractService.pendingContract$,
    this.pollContract$,
  ]).pipe(
    map(
      ([status, pendingContract, pollContract]) =>
        status !== 'complete' || (pollContract && pendingContract),
    ),
  );

  constructor() {
    super({pollTries: 0, isLoading: true, pollContract: true});

    this.monitorLoadingState();
  }

  readonly loadCheckoutStatus = this.effect<{
    sessionId: string;
    pollContract: boolean;
  }>((trigger$) =>
    trigger$.pipe(
      switchMap((trigger) =>
        this._stripeGateway.getStatus({session_id: trigger.sessionId}).pipe(
          tapResponse(
            (resp) => {
              if (trigger.pollContract) {
                this.patchState({
                  checkoutStatus: resp.stripe.status,
                  pollContract: true,
                });
                if (resp.stripe.status === 'complete') {
                  this.pollLiveContract();
                }
              } else {
                this.patchState({
                  pollContract: false,
                  checkoutStatus: resp.stripe.status,
                });
              }
            },
            () => {
              this.patchState({checkoutStatus: 'expired'});
            },
          ),
        ),
      ),
    ),
  );

  readonly pollLiveContract = this.effect<void>((trigger$) =>
    trigger$.pipe(
      switchMap(() =>
        interval(POLL_INTERVAL).pipe(
          startWith(0),
          tap(() => {
            this._contractService.reloadContracts();
            this.patchState({pollTries: this.get((s) => s.pollTries + 1)});
          }),
          takeUntil(
            combineLatest([
              this._contractService.pendingContract$,
              this.pollTries$,
            ]).pipe(
              filter(
                ([pending, pollTries]) =>
                  !pending || pollTries > POLL_ACTIVE_TRIES,
              ),
            ),
          ),
        ),
      ),
    ),
  );

  readonly monitorLoadingState = this.effect((sessionId$) =>
    sessionId$.pipe(
      switchMap(() =>
        combineLatest([
          this.checkoutStatus$,
          this._contractService.pendingContract$,
          this.pollTries$,
          this.pollContract$,
        ]),
      ),
      tap(([status, pendingContract, pollTries, pollContract]) => {
        this.patchState({
          isLoading:
            !status ||
            (status === 'complete' &&
              pollContract &&
              !!pendingContract &&
              pollTries <= POLL_ACTIVE_TRIES),
        });
      }),
    ),
  );
}
