import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {
  MillerColumnsViewModel,
  SelectableNode,
} from './miller-columns-view-model';
import {ProductCategoryModel} from './product-category.model';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {EmButtonModule, EmSearchInputComponent} from '@em/shared/ui';
import {MatTooltipModule} from '@angular/material/tooltip';
import {Category, GeometricScaler} from '@em/data-feed/data-access-products';
import {ChangesObj} from '@em/shared/util-types';

type NodeType = Category<string, SelectableNode<ProductCategoryModel>>;

@Component({
  selector: 'em-miller-columns',
  templateUrl: './miller-columns.component.html',
  styleUrls: ['./miller-columns.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatCheckboxModule,
    MatTooltipModule,
    EmButtonModule,
    EmSearchInputComponent,
  ],
})
export class MillerColumnsComponent implements OnChanges {
  @Input() availableCategories?: ProductCategoryModel[] = [];
  scaler = new GeometricScaler(0.6);
  @Input() selectedCategories?: ProductCategoryModel[] = [];
  @Output() selectedCategoriesChange = new EventEmitter<
    ProductCategoryModel[]
  >();
  vm?: MillerColumnsViewModel<ProductCategoryModel>;

  constructor() {}

  canNavigateUp() {
    return this.vm && this.vm.levels.length > 1;
  }

  clicked(node: NodeType) {
    if (!this.vm || !this.selectedCategories) return;

    this.vm.toggleCategory(node);
    this.selectedCategories.splice(0, Infinity, ...this.vm.getSelection());
    this.selectedCategoriesChange.emit(this.selectedCategories);
  }

  getRelativeWidth(level: number) {
    if (!this.vm) {
      return 100;
    }
    const n = this.vm.levels.length;

    return this.scaler.get(level, n);
  }

  getSelectedProductCountSum(node: NodeType): number {
    if (!this.vm) return 0;

    return this.vm.getSelectedSum(node, (x) => x.productCount);
  }

  getSelectedTotal(): number {
    if (!this.vm) return 0;

    return this.vm.getSelectedTotal((x) => x.productCount);
  }

  getTotal(): number {
    if (!this.vm) return 0;

    return this.vm.getTotal((x) => x.productCount);
  }

  getUnselectedProductCountSum(node: NodeType): number {
    if (!this.vm) return 0;

    return (
      this.vm.getTotalIn(node, (x) => x.productCount) -
      this.getSelectedProductCountSum(node)
    );
  }

  hasCategories() {
    if (!this.vm) {
      return false;
    }

    return this.vm.categoryList.length > 0;
  }

  navigateUp() {
    if (!this.vm || this.vm.levels.length === 1) return;

    this.vm.levels.splice(-1, 1);
  }

  ngOnChanges(
    changes: ChangesObj<
      MillerColumnsComponent,
      'availableCategories' | 'selectedCategories'
    >,
  ): void {
    if (changes.availableCategories) {
      this.vm = new MillerColumnsViewModel<ProductCategoryModel>(
        this.availableCategories || [],
        (cat) => cat.path,
        (cat) => -cat.conversions,
      );
      this.vm.applySelection(this.selectedCategories || []);
    } else if (this.vm && changes.selectedCategories) {
      this.vm.applySelection(changes.selectedCategories.currentValue || []);
    }
  }

  searchCategory(val?: string) {
    if (this.vm) {
      if (val) {
        val = val.trim();
        this.vm.searchCategoryFor(val);
      } else {
        this.vm.resetView();
      }
    }
  }

  showChevron(node: NodeType) {
    return node.hasLowerLevels();
  }
}
