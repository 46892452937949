import {ProductsListItem} from '@em/shared/api-interface';
import {ValueGetterFunc} from 'ag-grid-community';
import {CustomMoneyDiffRendererComponent} from '../custom-renderer/money-diff-renderer/money-diff-renderer.component';
import {ColumnTypeName} from './columns-types';
import {moneyValueGetter} from './value-getters';
import {EmLoadingCellRendererComponent} from '../loading-cell-renderer/loading-cell-renderer';
import {GOOGLE, MICROSOFT} from '@em/shared/platforms/util';
import {CustomValueDiffRendererComponent} from '../custom-renderer/value-diff-renderer/value-diff-renderer.component';

export interface Column {
  field: keyof ProductsListItem;
  valueGetter?: ValueGetterFunc;
  headerTitle?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cellRenderer?: any;
  type?: ColumnTypeName;
  includeSpinner?: boolean;
  showByDefault?: boolean;
  isPinned?: boolean;
  width?: number;
}

export interface ColumnGroup {
  groupId: string;
  logo?: string;
  icon?: string;
  children: Column[];
}

export const COLUMNS_GROUPS: ColumnGroup[] = [
  {
    groupId: 'products',
    icon: 'shopping_cart',
    children: [
      {
        field: 'title',
        includeSpinner: true,
        showByDefault: true,
        isPinned: true,
        width: 200,
        cellRenderer: EmLoadingCellRendererComponent,
      },
      {field: 'id'},
      {field: 'gtin'},
      {field: 'description', width: 200},
      {
        field: 'price',
        valueGetter: moneyValueGetter('price', 'currency'),
        type: 'moneyColumn',
      },
      {field: 'currency'},
      {
        field: 'shipping',
        type: 'moneyColumn',
        valueGetter: moneyValueGetter('shipping', 'currency'),
      },
      {field: 'shipping_service'},
      {
        field: 'current_price',
        type: 'moneyColumn',
        valueGetter: moneyValueGetter('current_price', 'currency'),
      },
      {
        field: 'full_price',
        type: 'moneyColumn',
        showByDefault: true,
        valueGetter: moneyValueGetter('full_price', 'currency'),
      },
      {
        field: 'sale_price',
        type: 'moneyColumn',
        valueGetter: moneyValueGetter('sale_price', 'currency'),
      },
      {field: 'sale_price_start_date', type: 'stringDateColumn'},
      {field: 'sale_price_end_date', type: 'stringDateColumn'},
      {
        field: 'cost_of_goods_sold',
        type: 'moneyColumn',
        valueGetter: moneyValueGetter('cost_of_goods_sold', 'currency'),
      },
      {
        field: 'auto_pricing_min_price',
        type: 'moneyColumn',
        valueGetter: moneyValueGetter('auto_pricing_min_price', 'currency'),
      },
      {field: 'content_language'},
      {field: 'mpn'},
      {field: 'brand'},
      {field: 'link'},
      {field: 'image_link'},
      {field: 'additional_image_links'},
      {field: 'mobile_link'},
      {field: 'availability', showByDefault: true},
      {field: 'stock_quantity', type: 'numberColumn'},
      {field: 'size'},
      {field: 'condition'},
      {field: 'gender'},
      {field: 'age_group'},
      {field: 'adult', type: 'booleanColumn'},
      {field: 'material'},
      {field: 'color'},
      {field: 'pattern'},
      {field: 'google_product_category'},
      {field: 'product_type'},
      {field: 'parent_id'},
      {field: 'energy_efficiency_class'},
      {field: 'is_bundle', type: 'booleanColumn'},
      {field: 'multipack', type: 'numberColumn'},
      {field: 'promotion_id'},
      {field: 'unit_pricing_measure', type: 'objectColumn'},
      {field: 'unit_pricing_base_measure', type: 'objectColumn'},
      {field: 'shipping_length', type: 'objectColumn'},
      {field: 'shipping_width', type: 'objectColumn'},
      {field: 'shipping_height', type: 'objectColumn'},
      {field: 'shipping_weight', type: 'objectColumn'},
      {field: 'custom_label0'},
      {field: 'custom_label1'},
      {field: 'custom_label2'},
      {field: 'custom_label3'},
      {field: 'custom_label4'},
      {field: 'source_created_at', type: 'stringDateColumn'},
    ],
  },

  {
    groupId: 'google_ads',
    logo: GOOGLE.logos.campaign,
    children: [
      {
        field: 'ad_spend',
        type: 'moneyColumn',
        valueGetter: moneyValueGetter('ad_spend', 'currency'),
        showByDefault: true,
      },
      {
        field: 'avg_cpc',
        type: 'moneyColumn',
        valueGetter: moneyValueGetter('avg_cpc', 'currency'),
      },
      {field: 'clicks', type: 'numberColumn'},
      {field: 'conversion_rate', type: 'numberColumn'},
      {
        field: 'cpo',
        type: 'moneyColumn',
        valueGetter: moneyValueGetter('cpo', 'currency'),
      },
      {field: 'ctr', type: 'numberColumn'},
      {field: 'impressions', type: 'numberColumn'},
      {field: 'ad_cost_ratio', type: 'numberColumn'},
      {
        field: 'net_revenue',
        type: 'moneyColumn',
        valueGetter: moneyValueGetter('net_revenue', 'currency'),
      },
      {field: 'conversions', type: 'numberColumn', showByDefault: true},
      {
        field: 'conversion_value',
        type: 'moneyColumn',
        valueGetter: moneyValueGetter('conversion_value', 'currency'),
      },
    ],
  },
  {
    groupId: 'microsoft_ads',
    logo: MICROSOFT.logos.campaign,
    children: [
      {
        field: 'bing_ad_spend',
        headerTitle: 'ad_spend',
        type: 'moneyColumn',
        valueGetter: moneyValueGetter('bing_ad_spend', 'currency'),
        showByDefault: true,
      },
      {
        field: 'bing_avg_cpc',
        headerTitle: 'avg_cpc',
        type: 'moneyColumn',
        valueGetter: moneyValueGetter('bing_avg_cpc', 'currency'),
      },
      {field: 'bing_clicks', headerTitle: 'clicks', type: 'numberColumn'},
      {
        field: 'bing_conversion_rate',
        headerTitle: 'conversion_rate',
        type: 'numberColumn',
      },
      {
        field: 'bing_cpo',
        headerTitle: 'cpo',
        type: 'moneyColumn',
        valueGetter: moneyValueGetter('bing_cpo', 'currency'),
      },
      {field: 'bing_ctr', headerTitle: 'ctr', type: 'numberColumn'},
      {
        field: 'bing_impressions',
        headerTitle: 'impressions',
        type: 'numberColumn',
      },
      {
        field: 'bing_net_revenue',
        headerTitle: 'net_revenue',
        type: 'moneyColumn',
        valueGetter: moneyValueGetter('bing_net_revenue', 'currency'),
      },
      {
        field: 'bing_conversions',
        headerTitle: 'conversions',
        type: 'numberColumn',
        showByDefault: true,
      },
    ],
  },

  {
    groupId: 'competitors_and_prices',
    icon: 'local_offer',
    children: [
      {
        field: 'fetch_status',
        type: 'fetchStatusColumn',
      },
      {
        field: 'min_price',
        type: 'moneyColumn',
        valueGetter: moneyValueGetter('min_price', 'min_price_currency'),
      },
      {
        field: 'min_price_full',
        type: 'moneyColumn',
        valueGetter: moneyValueGetter('min_price_full', 'min_price_currency'),
        showByDefault: true,
      },
      {
        field: 'min_shipping_costs',
        type: 'moneyColumn',
        valueGetter: moneyValueGetter(
          'min_shipping_costs',
          'min_price_currency',
        ),
      },
      {
        field: 'diff_price_full',
        type: 'moneyColumn',
        showByDefault: true,
        valueGetter: moneyValueGetter('diff_price_full', 'min_price_currency'),
        cellRenderer: CustomMoneyDiffRendererComponent,
      },
      {
        field: 'diff_shipping_costs',
        type: 'moneyColumn',
        valueGetter: moneyValueGetter(
          'diff_shipping_costs',
          'min_price_currency',
        ),
      },
      {field: 'min_price_currency'},
      {field: 'min_price_competitor', showByDefault: true},
      {
        field: 'suggested_price',
        type: 'moneyColumn',
        valueGetter: moneyValueGetter('suggested_price', 'currency'),
      },
      {
        field: 'diff_suggested_price',
        type: 'moneyColumn',
        valueGetter: moneyValueGetter(
          'diff_suggested_price',
          'min_price_currency',
        ),
        cellRenderer: CustomMoneyDiffRendererComponent,
      },
      {
        field: 'diff_suggested_price_percentage',
        type: 'percentageColumn',
        cellRenderer: CustomValueDiffRendererComponent,
      },
      {
        field: 'predicted_conversions_change_fraction',
        type: 'percentageColumn',
      },
      {
        field: 'revenue_increase',
        type: 'moneyColumn',
        valueGetter: moneyValueGetter('revenue_increase', 'currency'),
      },
      {
        field: 'predicted_clicks_change_fraction',
        type: 'percentageColumn',
      },
      {
        field: 'additional_ad_spend',
        type: 'moneyColumn',
        valueGetter: moneyValueGetter('additional_ad_spend', 'currency'),
      },
      {
        field: 'increase_net_revenue',
        type: 'moneyColumn',
        valueGetter: moneyValueGetter('increase_net_revenue', 'currency'),
      },
      {
        field: 'predicted_impressions_change_fraction',
        type: 'percentageColumn',
      },
      {
        field: 'benchmark_price',
        type: 'moneyColumn',
        valueGetter: moneyValueGetter('benchmark_price', 'currency'),
      },
    ],
  },
];
