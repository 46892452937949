import {Injectable} from '@angular/core';
import {MerchantsGateway} from '@em/shared/api-interface/lib/gateways/merchants.gateway';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {ROUTER_NAVIGATION} from '@ngrx/router-store';
import {Store} from '@ngrx/store';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {
  incorporateSetupStatusFromBackend,
  invalidateSetupStatus,
  loadSetupStatusFromBackend,
} from './setup-status.actions';
import {SessionService} from '../services/session/session.service';
import {ISetupFeatureState} from './setup-status.state';

@Injectable()
export class SetupStatusEffects {
  loadSetupStatus = createEffect(() =>
    this._actions.pipe(
      ofType(loadSetupStatusFromBackend),
      switchMap(() => this._merchantsGateway.getSetupStatus()),
      map((setupStatus) => incorporateSetupStatusFromBackend({setupStatus})),
    ),
  );

  refreshSetupStatus = createEffect(() =>
    this._actions.pipe(
      ofType(invalidateSetupStatus),
      concatLatestFrom(() => this._store),
      filter(
        ([, state]) => !(state as {setup: ISetupFeatureState}).setup.loading,
      ),
      map(() => loadSetupStatusFromBackend()),
    ),
  );

  invalidateOnRouting = createEffect(
    () =>
      this._actions.pipe(
        ofType(ROUTER_NAVIGATION),
        tap(() => {
          if (this._session.isLoggedIn()) {
            // eslint-disable-next-line @ngrx/no-dispatch-in-effects
            this._store.dispatch(invalidateSetupStatus());
          }
        }),
      ),
    {dispatch: false},
  );

  constructor(
    private readonly _actions: Actions,
    private readonly _store: Store,
    private readonly _session: SessionService,
    private readonly _merchantsGateway: MerchantsGateway,
  ) {}
}
