import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'orderBy',
  standalone: false,
})
export class OrderByPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(
    arr: Array<{[key: string]: string | undefined}>,
    field?: string,
    direction: 'asc' | 'desc' = 'asc',
  ): Array<{[key: string]: string | undefined}> {
    if (!field) return arr;

    return arr.sort((a, b) => {
      const fieldA = `${a[field]}`.toLowerCase();
      const fieldB = `${b[field]}`.toLowerCase();

      return fieldA !== fieldB
        ? fieldA < fieldB
          ? direction === 'asc'
            ? -1
            : 1
          : direction === 'desc'
          ? -1
          : 1
        : 0;
    });
  }
}
