import {DOCUMENT} from '@angular/common';
import {Directive, ElementRef, Inject, OnInit, Renderer2} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {IFavicons, ThemingService} from './theming.service';

@Directive({
  selector: '[emTheming]',
  standalone: false,
})
export class ThemingDirective implements OnInit {
  private readonly _head: HTMLHeadElement;
  private _insertedElements: Element[] = [];

  constructor(
    private readonly _theming: ThemingService,
    private readonly _renderer: Renderer2,
    private readonly _title: Title,
    private readonly _el: ElementRef,
    @Inject(DOCUMENT) private readonly _document: Document,
  ) {
    const head = this._document.getElementsByTagName('head').item(0);
    if (!head) {
      throw new Error('Could not find HEAD');
    }
    this._head = head;
  }

  ngOnInit(): void {
    this._theming.faviconsObservable().subscribe((favicons: IFavicons) => {
      this._applyFavicons(favicons);
    });

    this._theming.titleObservable().subscribe((title) => {
      this._changeTitle(title);
    });
  }

  private _applyFavicons(favicons: IFavicons) {
    for (const el of this._insertedElements) {
      this._renderer.removeChild(this._head, el);
    }
    this._insertedElements = [];

    for (const link of favicons.links) {
      const el = this._parseNode(link);
      this._insertedElements.push(el);
      this._renderer.appendChild(this._head, el);
    }
  }

  private _parseNode(html: string): Element {
    const tmp = this._document.createElement('div');
    tmp.innerHTML = html;
    return tmp.firstChild as Element;
  }

  private _changeTitle(title: string) {
    this._title.setTitle(title);
  }
}
