import {CommonModule} from '@angular/common';
import {Component, DestroyRef, Input, inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {EmPremiumLockModule} from '@em/shared/ui';
import {Contract} from '@em/subscription/api-interface';
import {ContractService} from '@em/subscription/data-access';
import {BehaviorSubject} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {PaymentService} from '../services/payment-service/payment.service';

@Component({
  selector: 'em-card-paywall',
  templateUrl: './card-paywall.component.html',
  styleUrls: ['./card-paywall.component.scss'],
  imports: [CommonModule, EmPremiumLockModule],
})
export class EmCardPaywallComponent {
  @Input() set missingFeatures(value: Partial<Contract> | undefined) {
    this.missingFeatures$.next(value);
  }
  @Input() upgradeNeededIf: ((c: Contract) => boolean) | undefined;

  private readonly _paymentService: PaymentService = inject(PaymentService);
  private readonly _contractService: ContractService = inject(ContractService);
  private readonly _destroyRef: DestroyRef = inject(DestroyRef);

  readonly missingFeatures$ = new BehaviorSubject<
    Partial<Contract> | undefined
  >(undefined);

  readonly showLock$ = this._contractService.activeContract$.pipe(
    map(
      (contract) =>
        !!(
          !contract ||
          (this.upgradeNeededIf && this.upgradeNeededIf(contract))
        ),
    ),
  );

  showPaywall() {
    const missingFeatureValue = this.missingFeatures$.value;

    this._paymentService
      .includeAndUpgradePlan(missingFeatureValue || {})
      .pipe(take(1), takeUntilDestroyed(this._destroyRef))
      .subscribe();
  }
}
