import {Component, Input} from '@angular/core';
import {
  IGenericFilterSetting,
  optionValueType,
} from '@em/data-feed/data-access-products';
import {values} from 'lodash-es';
import {join} from 'lodash-es';
import {isEmpty} from 'lodash-es';

@Component({
  selector: 'em-single-filter-row',
  templateUrl: './single-filter-row.component.html',
  styleUrls: ['./single-filter-row.component.scss'],
  standalone: false,
})
export class SingleFilterRowComponent {
  @Input() filter?: IGenericFilterSetting;

  constructor() {}

  getCategoriesLength(): number {
    if (this.filter) {
      return (this.filter.options['categories'] as string[]).length;
    }
    return 0;
  }

  getFirstOptionValue(): optionValueType | undefined {
    if (this.filter) {
      return values(this.filter.options)[0];
    }
    return undefined;
  }

  getI18nKey(key: string, suffix?: string): string {
    const sfx = suffix?.toUpperCase() ?? '';
    return `CAMPAIGN_PERFORMANCE_FILTER_${key.toUpperCase()}${sfx}`;
  }

  getOfferIdsList(): string {
    if (this.filter) {
      const offerIds = this.filter.options['offer_ids'] as string[];
      return join(offerIds || [], ', ');
    }

    return '';
  }

  hasOptions(): boolean {
    if (this.filter) {
      return !isEmpty(this.filter.options);
    }
    return false;
  }
}
