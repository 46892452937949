import {CommonModule} from '@angular/common';
import {Component, Input} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {EmCheckboxComponent} from '../checkbox/checkbox.component';

@Component({
  selector: 'em-slide-toggle',
  imports: [CommonModule, MatSlideToggleModule, FormsModule],
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
})
export class EmSlideToggleComponent extends EmCheckboxComponent {
  @Input() labelPosition: 'before' | 'after' = 'after';
  @Input() isStatusToggle = false;
}
