import {CommonModule, CurrencyPipe, DatePipe} from '@angular/common';
import {Component, Input, inject} from '@angular/core';
import {isNotUndefined} from '@em/shared/util-rxjs';
import {CurrencyCode} from '@em/shared/util-types';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {ApexAxisChartSeries, ApexChart, ApexOptions} from 'ng-apexcharts';
import {BehaviorSubject, combineLatest, map} from 'rxjs';
import {PriceHistory} from './types';
import {LineChartModule} from '../line-chart/line-chart.component';
import {lineChartOptions} from '../../utils/apex-charts.helper';

interface ChartConfig {
  currency: CurrencyCode;
  height: number | string | undefined;
}

@Component({
  selector: 'em-price-changes-chart',
  templateUrl: './price-changes-chart.component.html',
  styleUrls: ['./price-changes-chart.component.scss'],
  imports: [CommonModule, LineChartModule, TranslateModule],
  providers: [CurrencyPipe, DatePipe],
})
export class EmPriceChangesChartComponent {
  @Input()
  set series(data: PriceHistory) {
    this._generateSeries(data);
  }
  @Input()
  set config(config: ChartConfig) {
    this._configChart(config);
  }

  private readonly _currencyPipe = inject(CurrencyPipe);
  private readonly _datePipe = inject(DatePipe);
  private readonly _translateService = inject(TranslateService);

  chartConfig$ = new BehaviorSubject<ApexOptions | null | undefined>(undefined);
  series$ = new BehaviorSubject<ApexAxisChartSeries | null | undefined>(
    undefined,
  );
  chartData$ = combineLatest([
    this.chartConfig$.pipe(isNotUndefined()),
    this.series$.pipe(isNotUndefined()),
  ]).pipe(map(([config, series]) => ({config, series})));
  private _lastCurrencyConfigured: CurrencyCode | undefined;

  constructor() {}

  private _yAxisFormatter(currencyPipe: CurrencyPipe, currency: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (val: number, index: number) =>
      currencyPipe.transform(val, currency) || '';
  }

  private _xAxisFormatter(datePipe: DatePipe) {
    return (value: string) => datePipe.transform(value, 'mediumDate') || '';
  }

  private _tooltipGenerator(
    datePipe: DatePipe,
    currencyPipe: CurrencyPipe,
    translateService: TranslateService,
  ) {
    return ({
      series,
      seriesIndex,
      dataPointIndex,
      w,
    }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any) => {
      const details = w.config.series[seriesIndex].data[dataPointIndex].details;
      const date = details.created_at;
      const message = details.message || '-';
      const price = details.price + (details.shipping ?? 0);
      const currency = details.currency;
      const status = details.status
        ? translateService.instant(
            'PRICING_HISTORY_STATUS_' +
              (details.status as string).toUpperCase(),
          )
        : '';

      let resp = `<div class="chart-custom-tooltip">
          <div class="tooltip-title"><strong>${datePipe.transform(
            date,
            'medium',
          )}</strong></div>
          <br/>
        `;
      resp += status
        ? `<div>${translateService.instant(
            'SHARED_LABEL_STATUS',
          )}: <strong>${status}</strong></div>`
        : '';
      resp += `<div>${translateService.instant(
        'SHARED_LABEL_PRICE_INC_SHIPPING',
      )}: <strong>${currencyPipe.transform(price, currency)}</strong></div>
      <div>${translateService.instant(
        'SHARED_LABEL_MESSAGE',
      )}: <strong>${message}</strong></div>
        
      </div>`;

      return resp;
    };
  }

  private _generateSeries(priceHistory: PriceHistory | undefined) {
    if (priceHistory) {
      this.series$.next([
        {
          data: priceHistory.map((element) => ({
            x: element.created_at,
            y: element.price + (element.shipping ?? 0),
            details: element,
          })),
        },
      ]);
    } else {
      this.series$.next(null);
    }
  }

  private _configChart(config: ChartConfig) {
    if (config.currency && this._lastCurrencyConfigured !== config.currency) {
      this._lastCurrencyConfigured = config.currency;
      const finalConfig: ApexOptions = {
        chart: {
          ...lineChartOptions.chart,
          height: config.height || lineChartOptions.chart?.height,
        } as ApexChart,
        xaxis: {
          type: 'category',
          labels: {
            formatter: this._xAxisFormatter(this._datePipe),
          },
        },
        tooltip: {
          custom: this._tooltipGenerator(
            this._datePipe,
            this._currencyPipe,
            this._translateService,
          ),
        },
        yaxis: {
          labels: {
            formatter: this._yAxisFormatter(
              this._currencyPipe,
              config.currency,
            ),
          },
        },
        markers: {
          size: 5,
        },
      };

      this.chartConfig$.next(finalConfig);
    }
  }
}
