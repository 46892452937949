import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EmButtonBaseComponent} from '../base-button/button-base.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'em-fab-button',
  imports: [CommonModule, MatButtonModule, MatIconModule],
  templateUrl: './fab-button.component.html',
  styleUrls: ['./fab-button.component.scss'],
})
export class EmFabButtonComponent extends EmButtonBaseComponent {
  @Input() size: 'md' | 'sm' = 'md';
}
