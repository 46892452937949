import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, inject} from '@angular/core';
import {CountryService} from '@em/user-account/data-access';
import {SetupStatusService} from '@em/auth/data-access';
import {
  GetManagedCampaignsResp,
  ManagedCampaignsGateway,
} from '@em/shared/api-interface';
import {PlatformId} from '@em/shared/platforms/util';
import {
  ComponentStoreBase,
  ComponentStoreStateBase,
} from '@em/shared/util-types';
import {tapResponse} from '@ngrx/operators';
import {
  combineLatest,
  filter,
  mergeMap,
  of,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';

type errorTypes = 'loadCampaigns' | 'addGroup';

export interface ManagedCampaignsState
  extends ComponentStoreStateBase<errorTypes> {
  managedCampaigns: Map<PlatformId, GetManagedCampaignsResp | undefined>;
  inLoading: Set<PlatformId>;
}

@Injectable()
export class ManagedCampaignsStore extends ComponentStoreBase<
  ManagedCampaignsState,
  errorTypes
> {
  private readonly _managedCampaignsGateway = inject(ManagedCampaignsGateway);
  private readonly _setupStatusService = inject(SetupStatusService);
  private readonly _countryService = inject(CountryService);

  readonly googleCampaigns$ = this.select((s) =>
    s.managedCampaigns.get('google-shopping'),
  );
  readonly facebookCampaigns$ = this.select((s) =>
    s.managedCampaigns.get('facebook'),
  );
  readonly microsoftCampaigns$ = this.select((s) =>
    s.managedCampaigns.get('bing'),
  );
  readonly allCampaigns$ = this.select(
    this.googleCampaigns$,
    this.facebookCampaigns$,
    this.microsoftCampaigns$,
    (googleCampaigns, facebookCampaigns, microsoftCampaigns) => [
      ...(googleCampaigns || []),
      ...(facebookCampaigns || []),
      ...(microsoftCampaigns || []),
    ],
  );
  readonly isLoadingAny$ = this.select((s) => s.inLoading.size > 0);

  readonly updatePlatformCampaign = this.updater<{
    platformId: PlatformId;
    campaigns: GetManagedCampaignsResp | undefined;
  }>(
    (state, {platformId, campaigns}): ManagedCampaignsState => ({
      ...state,
      managedCampaigns: state.managedCampaigns.set(platformId, campaigns),
    }),
  );

  readonly addToLoading = this.updater<PlatformId>(
    (state, platformId): ManagedCampaignsState => ({
      ...state,
      inLoading: state.inLoading.add(platformId),
    }),
  );

  readonly removeFromLoading = this.updater<PlatformId>(
    (state, platformId): ManagedCampaignsState => {
      const inLoading = state.inLoading;
      inLoading.delete(platformId);

      return {
        ...state,
        inLoading,
      };
    },
  );

  constructor() {
    super({
      inLoading: new Set<PlatformId>(),
      managedCampaigns: new Map(),
    });
  }

  readonly loadCampaigns = this.effect<PlatformId>((platformId$) =>
    platformId$.pipe(
      filter((platformId) => !this.get((s) => s.inLoading).has(platformId)),
      tap((platformId) => {
        this.addToLoading(platformId);
      }),
      mergeMap((platformId) =>
        combineLatest([
          of(platformId),
          this._setupStatusService.observable().pipe(filter((s) => s.loaded)),
          this._countryService.observable().pipe(filter((s) => !!s)),
        ]).pipe(take(1)),
      ),
      mergeMap(([platformId, setupStatus, countryCode]) => {
        let obs$;
        if (
          platformId === 'google-shopping' &&
          !setupStatus.merchant?.hasFullSetup
        ) {
          obs$ = of([]);
        } else if (
          platformId === 'facebook' &&
          !setupStatus.facebookSetup.hasFullSetup
        ) {
          obs$ = of([]);
        } else {
          obs$ = this._managedCampaignsGateway.getManagedCampaigns({
            country: countryCode,
            platform: platformId,
          });
        }

        return obs$.pipe(
          tapResponse(
            (groups) => {
              this.updatePlatformCampaign({
                platformId,
                campaigns: groups as GetManagedCampaignsResp,
              });
              this.removeFromLoading(platformId);
            },
            (error: HttpErrorResponse) => {
              this.addError({
                httpError: error,
                errorMessage: {
                  key: 'loadCampaigns',
                },
              });
              this.updatePlatformCampaign({
                platformId,
                campaigns: undefined,
              });
              this.removeFromLoading(platformId);
            },
          ),
        );
      }),
    ),
  );

  readonly loadAllCampaigns = this.effect<boolean | void>((forceLoad$) =>
    forceLoad$.pipe(
      withLatestFrom(this.state$),
      tap(([forceLoad, state]) => {
        if (!state.managedCampaigns.has('facebook') || forceLoad) {
          this.loadCampaigns('facebook');
        }

        if (!state.managedCampaigns.has('google-shopping') || forceLoad) {
          this.loadCampaigns('google-shopping');
        }

        if (!state.managedCampaigns.has('bing') || forceLoad) {
          this.loadCampaigns('bing');
        }
      }),
    ),
  );
}
