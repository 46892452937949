import {Injectable} from '@angular/core';
import {createSelector, Store} from '@ngrx/store';
import {Observable} from 'rxjs';

import {ISetupStatus} from './setup-status';
import {invalidateSetupStatus} from './setup-status.actions';
import {selectSetupFeatureState} from './setup-status.state';

export const selectSetupStatus = createSelector(
  selectSetupFeatureState,
  (x) => x.status,
);

export const selectUserCountries = createSelector(
  selectSetupFeatureState,
  (x) => x.status.merchantCenter.availableCountries,
);

export const selectSetupOutdated = createSelector(
  selectSetupFeatureState,
  (x) => x.outdated,
);

@Injectable({
  providedIn: 'root',
})
export class SetupStatusService {
  userCountries$ = this._store.select(selectUserCountries);
  isOutdated$ = this._store.select(selectSetupOutdated);

  constructor(private readonly _store: Store) {}

  observable(): Observable<ISetupStatus> {
    return this._store.select(selectSetupStatus);
  }

  invalidateSetupStatus(): void {
    this._store.dispatch(invalidateSetupStatus());
  }
}
