import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, inject} from '@angular/core';
import {IS_PRODUCTION_MODE} from '@em/shared/util-configuration';
import {SetupStatusService} from '@em/auth/data-access';
import {
  ComponentStoreBase,
  ComponentStoreStateBase,
} from '@em/shared/util-types';
import {
  ActiveSubscription,
  Contract,
  ContractPriceCurrency,
  ContractWithCredits,
  ContractWithPrice,
  ContractsGateway,
  SubscriptionGateway,
} from '@em/subscription/api-interface';
import {tapResponse} from '@ngrx/operators';
import {Observable, combineLatest, of} from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';

type errorTypes = 'loadContract' | 'saveContract';

interface ContractState extends ComponentStoreStateBase<errorTypes> {
  liveContractWithCredits?: ContractWithCredits | null;
  pendingContractWithCredits?: ContractWithCredits | null;
  cancelledContractWithCredits?: ContractWithCredits | null;
  activeSubscription?: ActiveSubscription;
  cancelledSubscription?: ActiveSubscription;
  isInitialized?: boolean;
}

@Injectable()
export class ContractStore extends ComponentStoreBase<
  ContractState,
  errorTypes
> {
  private readonly _contractsGateway = inject(ContractsGateway);
  private readonly _subscriptionGateway = inject(SubscriptionGateway);
  private readonly _setupStatusService = inject(SetupStatusService);
  private readonly _isProduction: boolean = inject(IS_PRODUCTION_MODE);

  isInitialized$ = this.select((s) => s.isInitialized);
  liveContract$ = this.select((s) => s.liveContractWithCredits?.details);
  liveContractWithCredits$ = this.select((s) => s.liveContractWithCredits);
  pendingContract$ = this.select((s) => s.pendingContractWithCredits?.details);
  cancelledContractWithCredits$ = this.select(
    (s) => s.cancelledContractWithCredits,
  );
  activeSubscription$ = this.select((s) => s.activeSubscription);
  cancelledSubscription$ = this.select((s) => s.cancelledSubscription);
  editableContract$ = this.select(
    this.liveContract$,
    this.pendingContract$,
    (live, pending) => pending || live,
  );
  // in case of cancelled subscription, the cancelled contract still active till the end of the payment period
  // but is not live, it is cancelled
  activeContract$: Observable<ContractWithCredits['details'] | undefined> =
    combineLatest([
      this.liveContract$,
      this.cancelledSubscription$,
      this.cancelledContractWithCredits$,
    ]).pipe(
      map(([liveContract, cancelledSubscription, cancelledContract]) => {
        if (liveContract) {
          return liveContract;
        }

        // return the canceled contract if subscription still valid
        if (cancelledSubscription && cancelledSubscription.valid_until) {
          if (
            new Date(cancelledSubscription.valid_until).getTime() >
            new Date().getTime()
          ) {
            return cancelledContract?.details;
          }
        }

        return undefined;
      }),
    );

  constructor() {
    super({});
  }

  readonly loadContract = this.effect<void>((trigger$) =>
    trigger$.pipe(
      switchMap(() => {
        this.startLoading();
        return combineLatest([
          this._contractsGateway.getContracts({}).pipe(
            catchError(() => {
              if (this._isProduction) {
                // ToDo: redirect to error page
                // emit something where the App COmponent need to do the redirect
                return of({live: null, pending: null, cancelled: null});
              } else {
                // For local develpment get the live contract from the setup status
                return this._setupStatusService.observable().pipe(
                  filter((setupStatus) => setupStatus.loaded),
                  map((setupStatus) => ({
                    live: setupStatus.contract
                      ? {details: setupStatus.contract, credits: 0}
                      : null,
                    pending: null,
                    cancelled: null,
                  })),
                  take(1),
                );
              }
            }),
          ),
          this._subscriptionGateway
            .getActive({})
            .pipe(catchError(() => of(null))),
        ]).pipe(
          tapResponse(
            ([contractResp, activeSubscription]) => {
              this.patchState({
                isLoading: false,
                liveContractWithCredits: contractResp.live,
                pendingContractWithCredits: contractResp.pending,
                cancelledContractWithCredits: contractResp.cancelled,
                activeSubscription:
                  activeSubscription?.status === 'active'
                    ? activeSubscription
                    : undefined,
                cancelledSubscription:
                  activeSubscription?.status === 'cancelled'
                    ? activeSubscription
                    : undefined,
                isInitialized: true,
              });
            },
            (error: HttpErrorResponse) => {
              this.addError({
                httpError: error,
                errorMessage: {
                  key: 'loadContract',
                },
                statePayload: {
                  pendingContractWithCredits: undefined,
                  liveContractWithCredits: undefined,
                },
              });
            },
          ),
        );
      }),
    ),
  );

  readonly saveContract = this.effect<Partial<Contract>>((trigger$) =>
    trigger$.pipe(
      withLatestFrom(this.liveContract$, this.pendingContract$),
      switchMap(([changes, live, pending]) => {
        // make changes to the pending if exist, otherwize the live
        const toUpdate = pending || live;
        const toSave = {...toUpdate, ...changes} as Contract;

        return this._contractsGateway.putContracts(toSave).pipe(
          tapResponse(
            (response) => {
              if (response.status === 'live') {
                this.patchState({
                  liveContractWithCredits: response,
                  pendingContractWithCredits: null,
                });
              } else {
                this.patchState({pendingContractWithCredits: response});
              }
            },
            (error: HttpErrorResponse) => {
              this.addError({
                httpError: error,
                errorMessage: {
                  key: 'saveContract',
                },
              });
            },
          ),
        );
      }),
    ),
  );

  getContractPreview(
    contract: Contract,
    currency: ContractPriceCurrency,
  ): Observable<ContractWithPrice | null> {
    return this._contractsGateway.postPreview(contract).pipe(
      map((resp) => {
        const selectedCurrencyPrice = resp.required_payment?.prices.find(
          (p) => p.currency === currency,
        );
        return {
          contract: resp.details,
          pricePreview: {
            credits: resp.credits,
            currency: selectedCurrencyPrice?.currency,
            price: selectedCurrencyPrice?.price,
          },
        } as ContractWithPrice;
      }),
      catchError(() => of(null)),
    );
  }
}
