import {CommonModule} from '@angular/common';
import {
  Component,
  Input,
  NgModule,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexNonAxisChartSeries,
  ApexOptions,
  NgApexchartsModule,
} from 'ng-apexcharts';
import {scatterChartOptions} from '../../utils/apex-charts.helper';

@Component({
  selector: 'em-scatter-chart',
  templateUrl: './scatter-chart.component.html',
  styleUrls: ['./scatter-chart.component.scss'],
  standalone: false,
})
export class ScatterChartComponent implements OnChanges {
  @Input() series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
  @Input() config: ApexOptions | undefined;

  chartOptions = scatterChartOptions;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config']?.currentValue) {
      const mergedChart = {
        ...scatterChartOptions.chart,
        ...changes['config']?.currentValue.chart,
      };
      this.chartOptions = {
        ...scatterChartOptions,
        ...changes['config']?.currentValue,
        chart: mergedChart,
      };
    }
  }
}

@NgModule({
  declarations: [ScatterChartComponent],
  exports: [ScatterChartComponent],
  imports: [CommonModule, NgApexchartsModule],
})
export class ScatterChartModule {}
