import {CommonModule} from '@angular/common';
import {Component, EventEmitter, Output, inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {ProductsListLayoutStore} from '@em/data-feed/data-access-products';
import {
  EmInputModule,
  EmPrimaryButtonComponent,
  EmTextButtonComponent,
} from '@em/shared/ui';
import {TranslateModule} from '@ngx-translate/core';
import {tap} from 'rxjs';

@Component({
  selector: 'em-add-new-layout',
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    ReactiveFormsModule,
    TranslateModule,
    EmPrimaryButtonComponent,
    EmTextButtonComponent,
    EmInputModule,
  ],
  templateUrl: './add-new-layout.component.html',
  styleUrls: ['./add-new-layout.component.scss'],
})
export class EmAddNewLayoutComponent {
  @Output() layoutSaved = new EventEmitter<string>();
  private readonly _layoutStore = inject(ProductsListLayoutStore);
  private readonly _matDialogData = inject(MAT_DIALOG_DATA);

  formGroup = new FormGroup({
    name: new FormControl('', Validators.required),
  });

  constructor() {
    // close modal when layout added
    this._layoutStore.layoutSaved$
      .pipe(
        takeUntilDestroyed(),
        tap((uuid) => this.layoutSaved.emit(uuid)),
      )
      .subscribe();
  }

  addLayout() {
    if (this.formGroup.valid) {
      this._layoutStore.addOrUpdateLayout({
        active: true,
        name: this.formGroup.value.name as string,
        layout: this._matDialogData.layout,
      });
    }
  }
}
