import {Component, Input, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ComponentBase} from '@em/shared/ui';
import {
  AvailablePlatforms,
  IBarChartData,
  PlatformId,
} from '@em/shared/platforms/util';
import {CurrencyCode} from '@em/shared/util-types';
import {TranslateModule} from '@ngx-translate/core';
import {EmSpinnerModule} from '@em/shared/ui';

@Component({
  selector: 'em-products-bar-chart',
  templateUrl: './products-bar-chart.component.html',
  styleUrls: ['./products-bar-chart.component.scss'],
  standalone: false,
})
export class EmProductsBarChartComponent extends ComponentBase {
  @Input() currency: CurrencyCode | undefined | null;
  @Input() fractionSize = 2;
  @Input() platformCode?: PlatformId | null;
  @Input() isLoading?: boolean | null;

  readonly emptyElements = new Array(5);

  constructor() {
    super();
  }

  private _data: IBarChartData[] = [];

  get data(): IBarChartData[] {
    return this._data || [];
  }

  @Input()
  set data(data: IBarChartData[] | null | undefined) {
    this._data = data || [];
  }

  get noData() {
    return this.data.length === 0;
  }

  getSize(value: number) {
    if (!this.data || this.noData) return 0;

    return Math.abs((((value / this._maxResult()) * 100) / 2) * 0.8);
  }

  isPositive(value: number): boolean {
    return value > 0;
  }

  i18nName(): string {
    if (!this.platformCode) return '';
    return AvailablePlatforms.get(this.platformCode)?.name || '';
  }

  private _maxResult(): number {
    return this.data[0].value;
  }
}

@NgModule({
  declarations: [EmProductsBarChartComponent],
  exports: [EmProductsBarChartComponent],
  imports: [CommonModule, TranslateModule, EmSpinnerModule],
})
export class EmProductsBarChartModule {}
