import {Component, Inject, inject} from '@angular/core';
import {EmUpgradePlanComponent} from '../upgrade-plan/upgrade-plan.component';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
} from '@angular/material/dialog';
import {Contract} from '@em/subscription/api-interface';
import {Router} from '@angular/router';

@Component({
  selector: 'em-upgrade-plan-modal',
  templateUrl: './upgrade-plan-modal.component.html',
  styleUrls: ['./upgrade-plan-modal.component.scss'],
  imports: [EmUpgradePlanComponent, MatDialogModule],
})
export class EmUpgradePlanModalComponent {
  private readonly _router = inject(Router);
  private readonly _matDialog = inject(MatDialog);

  constructor(@Inject(MAT_DIALOG_DATA) public data: {contract: Contract}) {}

  redirectTo(url: string) {
    this._router.navigateByUrl(url);
    this._matDialog.closeAll();
  }

  toPayment() {
    this._matDialog.closeAll();
  }
}
