import {CommonModule} from '@angular/common';
import {Component, Input} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {EmNumberWithUnitPipe} from '../../../legacy/number-with-unit/number-with-unit.pipe';

@Component({
  selector: 'em-percent-kpi',
  imports: [CommonModule, TranslateModule, EmNumberWithUnitPipe],
  templateUrl: './percent-kpi.component.html',
  styleUrls: ['./percent-kpi.component.scss', '../kpi.shared.scss'],
})
export class EmPercentKpiComponent {
  @Input() performanceLabel?: string;
  @Input() performanceValue?: number | null;

  constructor() {}
}
