import {CommonModule} from '@angular/common';
import {Component, Input, OnDestroy, OnInit, inject} from '@angular/core';
import {EmSpinnerModule} from '@em/shared/ui';
import {configurationHelper} from '@em/shared/util-configuration';
import {Contract, StripeGateway} from '@em/subscription/api-interface';
import {TranslateModule} from '@ngx-translate/core';
import {StripeEmbeddedCheckout} from '@stripe/stripe-js';
import {loadStripe} from '@stripe/stripe-js/pure';
import {combineLatest, from, map, switchMap, tap, throwError} from 'rxjs';

@Component({
  selector: 'em-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.scss'],
  imports: [CommonModule, TranslateModule, EmSpinnerModule],
})
export class EmStripePaymentComponent implements OnInit, OnDestroy {
  @Input({required: true}) contract?: Contract;

  private readonly _stripeGateway = inject(StripeGateway);
  private _checkoutObject: StripeEmbeddedCheckout | undefined;

  protected isLoading = true;

  readonly stripe$ = from(
    loadStripe(configurationHelper.needConfig('STRIPE_PUBLIC_API_KEY')),
  );

  ngOnDestroy(): void {
    if (this._checkoutObject) {
      this._checkoutObject.destroy();
    }
  }

  ngOnInit(): void {
    combineLatest([this._fetchClientSecret(), this.stripe$])
      .pipe(
        switchMap(([clientSecret, stripe]) => {
          if (clientSecret && stripe) {
            return from(stripe.initEmbeddedCheckout({clientSecret}));
          } else {
            return throwError(() => 'Failed to load Stripe');
          }
        }),
        tap((checkout) => {
          checkout.mount('#checkout');
          this._checkoutObject = checkout;
          this.isLoading = false;
        }),
      )
      .subscribe();
  }

  private _fetchClientSecret() {
    if (this.contract) {
      let cont: Contract = this.contract;
      if (cont.repricing?.total_products === 0) {
        cont = {
          ...this.contract,
          repricing: {...this.contract.repricing, total_products: 1},
        };
      }
      return this._stripeGateway
        .postCheckout(cont)
        .pipe(map((resp) => resp.stripe.client_secret));
    } else {
      return throwError(() => 'Contract missing');
    }
  }
}
