import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, inject} from '@angular/core';
import {
  AutomaticFiltersGateway,
  BackEndSelectedFilters,
  BackendFilterSetPreview,
} from '@em/shared/api-interface';
import {isNotNullOrUndefined} from '@em/shared/util-rxjs';
import {
  ComponentStoreBase,
  ComponentStoreStateBase,
  CountryCode,
} from '@em/shared/util-types';
import {tapResponse} from '@ngrx/operators';
import {Observable, combineLatest, map, of, switchMap} from 'rxjs';

type errorTypes = 'calculatePercentage';

export interface FilterSetPreviewPercentageState
  extends ComponentStoreStateBase<errorTypes> {
  percentage?: number;
  filters?: Partial<BackendFilterSetPreview>;
  country?: CountryCode;
  percentageOf?: number | Partial<BackendFilterSetPreview>;
}

@Injectable()
export class FilterSetPreviewPercentageStore extends ComponentStoreBase<
  FilterSetPreviewPercentageState,
  errorTypes
> {
  private readonly _autmaticFilterGateway = inject(AutomaticFiltersGateway);

  readonly country$ = this.select((s) => s.country);
  readonly filters$ = this.select((s) => s.filters);
  readonly percentageOf$ = this.select((s) => s.percentageOf);
  readonly percentage$ = this.select((s) => s.percentage);

  updateFilters = this.updater<Partial<BackendFilterSetPreview>>(
    (state, filters): FilterSetPreviewPercentageState => ({...state, filters}),
  );
  updateCountry = this.updater<CountryCode>(
    (state, country): FilterSetPreviewPercentageState => ({...state, country}),
  );
  updatePercentageOf = this.updater<number | Partial<BackendFilterSetPreview>>(
    (state, percentageOf): FilterSetPreviewPercentageState => ({
      ...state,
      percentageOf,
    }),
  );
  constructor() {
    super({isLoading: true});

    this.calculatePercentage();
  }

  // Load All Existing Layouts
  readonly calculatePercentage = this.effect((trigger$) =>
    trigger$.pipe(
      switchMap(() =>
        combineLatest([
          this.country$.pipe(isNotNullOrUndefined()),
          this.filters$.pipe(isNotNullOrUndefined()),
          this.percentageOf$.pipe(isNotNullOrUndefined()),
        ]),
      ),
      switchMap(([country, filters, percentageOf]) =>
        combineLatest([
          this._getPreview(filters, country).pipe(map((list) => list.length)),
          typeof percentageOf === 'number'
            ? of(percentageOf)
            : this._getPreview(percentageOf, country).pipe(
                map((list) => list.length),
              ),
        ]).pipe(
          tapResponse(
            ([preview, precentageOf]) => {
              this.patchState({
                percentage:
                  precentageOf === 0 ? 0 : preview / precentageOf || 0,
                isLoading: false,
              });
            },
            (error: HttpErrorResponse) => {
              this.addError({
                httpError: error,
                errorMessage: {
                  key: 'calculatePercentage',
                },
              });
            },
          ),
        ),
      ),
    ),
  );

  private _getPreview(
    filters: Partial<BackendFilterSetPreview>,
    country: CountryCode,
  ): Observable<string[]> {
    return this._autmaticFilterGateway.postFilterPreview({
      links: filters.links || [],
      country,
      manually_added_products: filters.manually_added_products || [],
      manually_removed_products: filters.manually_removed_products || [],
      selected_filters: filters.selected_filters as BackEndSelectedFilters,
    });
  }
}
