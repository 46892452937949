import {CommonModule} from '@angular/common';
import {Component, inject} from '@angular/core';
import {EmPrimaryButtonComponent} from '@em/shared/ui';
import {TranslateModule} from '@ngx-translate/core';
import {PaymentService} from '../services/payment-service/payment.service';

@Component({
  selector: 'em-cancel-subscription',
  templateUrl: './cancel-subscription.component.html',
  styleUrls: ['./cancel-subscription.component.scss'],
  imports: [CommonModule, TranslateModule, EmPrimaryButtonComponent],
})
export class EmCancelSubscriptionComponent {
  private readonly _paymentService = inject(PaymentService);
  cancelSubscription() {
    this._paymentService.openCancelSubscriptionModal();
  }
}
