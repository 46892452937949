import {Category, CategoryConverter} from '@em/data-feed/data-access-products';
import {some} from 'lodash-es';
import {isEqual} from 'lodash-es';
import {every} from 'lodash-es';
import {each} from 'lodash-es';

export interface INodeContent<T> {
  content?: T;
  selected?: boolean;
  someSelected?: boolean;
}

export class SelectableNode<T> {
  selected = false;
  someSelected = false;

  constructor(readonly content?: T) {}
}

export class MillerColumnsViewModel<TInput> {
  levels: Array<Array<Category<string, SelectableNode<TInput>>>> = [];
  tree: Array<Category<string, SelectableNode<TInput>>> = [];
  private readonly _converter: CategoryConverter<
    TInput,
    SelectableNode<TInput>
  >;

  constructor(
    public categoryList: TInput[],
    private readonly _getPath: (item: TInput) => string[],
    sortBy: (item: TInput) => string | number,
  ) {
    this._converter = new CategoryConverter<TInput, SelectableNode<TInput>>({
      getPath: this._getPath,
      sortBy: (node) => (node && node.content && sortBy(node.content)) || '',
      getContent: (item) => new SelectableNode(item),
    });

    this.tree = this._converter.createTree(this.categoryList);
    this.syncBranches();
    this.resetView();
  }

  applySelection(selectedPaths: TInput[]) {
    this.eachCategory((category) => {
      if (category.content.content) {
        category.content.selected = some(selectedPaths, (x) =>
          isEqual(x, category.content.content),
        );
      } else {
        category.content.selected = false;
      }
    });

    this.syncBranches();
  }

  /**
   * Execute function for each node in tree recursively
   * @param {(node: Category<string, INodeContent<T>>) => void} fun
   */
  eachCategory(fun: (node: Category<string, SelectableNode<TInput>>) => void) {
    each(this.tree, (root) => {
      root.eachNode(fun);
    });
  }

  findCategory(regex: RegExp) {
    let found;
    this.tree.find((root) => {
      found = root.findNode((n) => regex.test(n.id));
      return !!found;
    });
    return found;
  }

  getSelectedSum(
    category: Category<string, INodeContent<TInput>>,
    accessor: (content: TInput) => number,
  ): number {
    let sum = 0;
    category.eachNode((n) => {
      if (n.content && n.content.selected && n.content.content) {
        sum += accessor(n.content.content);
      }
    });

    return sum;
  }

  getSelectedTotal(accessor: (content: TInput) => number): number {
    let sum = 0;
    each(this.tree, (root) => {
      sum += this.getSelectedSum(root, accessor);
    });
    return sum;
  }

  getSelection(): TInput[] {
    const selection: TInput[] = [];

    this.eachCategory((node) => {
      if (node.content && node.content.selected && node.content.content) {
        // Only retrieve if has category (name) -> comes from original list
        selection.push(node.content.content);
      }
    });

    return selection;
  }

  getTotal(accessor: (content: TInput) => number): number {
    let sum = 0;
    each(this.tree, (root) => {
      sum += this.getTotalIn(root, accessor);
    });
    return sum;
  }

  getTotalIn(
    category: Category<string, INodeContent<TInput>>,
    accessor: (content: TInput) => number,
  ): number {
    let sum = 0;
    category.eachNode((n) => {
      if (n.content && n.content.content) {
        sum += accessor(n.content.content);
      }
    });

    return sum;
  }

  isActive(node: Category<string, SelectableNode<TInput>>) {
    return this.levels.includes(node.children);
  }

  open(node: Category<string, SelectableNode<TInput>>) {
    let nodes = node.getAncestors();
    if (!node.isLeaf()) {
      nodes = [node, ...nodes];
    }
    this.levels = nodes.reverse().map((n) => n.children);
  }

  resetView() {
    this.levels = [this.tree];
  }

  searchCategoryFor(text: string) {
    if (text === '') {
      this.resetView();
      return;
    }

    if (text.length < 3) {
      return;
    }
    const regex = new RegExp(text.replace(/\s/, '.*'), 'i');
    const node = this.findCategory(regex);

    if (node) {
      this.open(node);
    } else {
      this.resetView();
    }
  }

  /**
   * Synchronize's someSelected and selected states for non-leaf node
   */
  syncBranches() {
    this.eachCategory((node) => {
      if (node.isLeaf()) {
        return;
      }

      if (!node.content) {
        node.content = new SelectableNode<TInput>();
      }

      if (every(node.children, (n) => n.content && n.content.selected)) {
        node.content.selected = true;
        node.content.someSelected = false;
      } else if (
        every(node.children, (n) => !n.content || !n.content.selected)
      ) {
        node.content.someSelected = some(
          node.children,
          (n) => n.content && n.content.someSelected,
        );
        node.content.selected = false;
      } else {
        node.content.someSelected = true;
        node.content.selected = false;
      }
    });
  }

  toggleCategory(category: Category<string, INodeContent<TInput>>) {
    category.content.selected = !category.content.selected;

    // propagate selection to children
    category.eachNode((node) => {
      node.content.selected = category.content && category.content.selected;
      if (node.content.selected) {
        node.content.someSelected = false;
      }
    });

    this.syncBranches();
  }
}
