import {Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {tapResponse} from '@ngrx/operators';
import {EMPTY} from 'rxjs';
import {filter, switchMap, withLatestFrom} from 'rxjs/operators';
import {PluginSettingsGateway} from '../http/plugin-settings/plugin-settings.gateway';
import {IGetPluginSettingsResponse} from '../http/plugin-settings/types';

export interface ShopSystemSettingsState<ReferenceType, BlobType> {
  shopSystemName?: string | null;
  shopToken?: string | null;
  settings?: IGetPluginSettingsResponse<ReferenceType, BlobType>;
  settingsSaved?: boolean;
  googleCountries?: string[] | null;
  isLoading?: boolean;
}

@Injectable()
export class ShopSystemSettingsStore<
  ReferenceType,
  BlobType,
> extends ComponentStore<ShopSystemSettingsState<ReferenceType, BlobType>> {
  readonly shopSystemName$ = this.select((state) => state.shopSystemName);
  readonly shopToken$ = this.select((state) => state.shopToken);
  readonly settings$ = this.select((state) => state.settings);
  readonly pluginVersion$ = this.select(
    (state) => state.settings?.plugin_version,
  );
  readonly googleCountries$ = this.select((state) => state.googleCountries);
  readonly referenceData$ = this.select<ReferenceType | undefined>(
    (state) => state.settings?.reference_data,
  );

  readonly updateShopSystemName = this.updater<string | null | undefined>(
    (
      state,
      shopSystemName,
    ): ShopSystemSettingsState<ReferenceType, BlobType> => ({
      ...state,
      shopSystemName,
    }),
  );

  readonly updateShopToken = this.updater<string | null | undefined>(
    (state, shopToken): ShopSystemSettingsState<ReferenceType, BlobType> => ({
      ...state,
      shopToken,
    }),
  );

  readonly settingsSaved$ = this.select((state) => state.settingsSaved);

  constructor(private readonly _settingsGateway: PluginSettingsGateway) {
    super({});
    this.loadSettings();
  }

  loadSettings = this.effect<void>((trigger$) =>
    trigger$.pipe(
      switchMap(() => this.shopToken$),
      filter((token) => !!token),
      switchMap((shopToken) =>
        this._settingsGateway
          .get<ReferenceType, BlobType>(shopToken as string)
          .pipe(
            tapResponse(
              (response) => {
                this.patchState({settings: response, settingsSaved: false});
              },
              () => {},
            ),
          ),
      ),
    ),
  );

  loadGoogleCountries = this.effect<void>((trigger$) =>
    trigger$.pipe(
      switchMap(() => this.shopToken$),
      filter((token) => !!token),
      switchMap((shopToken) =>
        this._settingsGateway.getGoogleCountries(shopToken as string).pipe(
          tapResponse(
            (response) => {
              this.patchState({
                googleCountries: response.google_countries || [],
              });
            },
            () => {},
          ),
        ),
      ),
    ),
  );

  saveSettings = this.effect<BlobType[]>((trigger$) =>
    trigger$.pipe(
      withLatestFrom(this.shopToken$),
      switchMap(([countries, shopToken]) => {
        if (shopToken) {
          return this._settingsGateway
            .put<BlobType>({target_countries: countries}, shopToken)
            .pipe(
              tapResponse(
                () => {
                  this.patchState({settingsSaved: true});
                  this.loadSettings();
                },
                () => {},
              ),
            );
        }
        return EMPTY;
      }),
    ),
  );
}
