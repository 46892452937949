import {Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'em-country-flag',
  templateUrl: './country-flag.component.html',
  styleUrls: ['./country-flag.component.scss'],
  standalone: false,
})
export class EmCountryFlagComponent {
  @Input() countryCode?: string;
  @HostBinding('class')
  @Input()
  size: 'sm' | 'md' | 'lg' = 'md';

  constructor() {}
}
