import {Component, Input} from '@angular/core';

@Component({
  selector: 'em-personal-advisor',
  templateUrl: './personal-advisor.component.html',
  styleUrls: ['./personal-advisor.component.scss'],
  standalone: false,
})
export class EmPersonalAdvisorComponent {
  @Input() isLoading: boolean | null = false;
  @Input() showDefaultTitle = true;
  @Input() fillContainer = false;
  @Input() robotPosition: 'over' | 'aligned' | 'top' = 'over';
  @Input() robotSize: 'lg' | 'md' = 'lg';

  constructor() {}
}
