import {CommonModule} from '@angular/common';
import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {
  EmCheckboxModule,
  EmIconModule,
  expandCollapseVisibilityAnimation,
} from '@em/shared/ui';
import {ColDef, ColGroupDef, GridApi} from 'ag-grid-community';

interface DisplayListItem {
  colDef: ColDef | ColGroupDef;
  expand: boolean;
  checked: boolean;
  children?: DisplayListItem[];
}

@Component({
  selector: 'em-columns-selector',
  imports: [CommonModule, EmCheckboxModule, FormsModule, EmIconModule],
  templateUrl: './columns-selector.component.html',
  styleUrls: ['./columns-selector.component.scss'],
  animations: [expandCollapseVisibilityAnimation],
})
export class EmColumnsSelectorComponent implements OnChanges {
  @Input() colDefList: Array<ColDef | ColGroupDef> | undefined;
  @Input() gridApi: GridApi | undefined;

  displayList: DisplayListItem[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.['colDefList']?.currentValue ||
      changes?.['gridApi']?.currentValue
    ) {
      this.prepareDisplayList(this.colDefList, this.gridApi);
    }
  }

  isGroup(val: ColDef | ColGroupDef): val is ColGroupDef {
    return (val as ColGroupDef).children !== undefined;
  }

  isColumn(val: ColDef | ColGroupDef): val is ColDef {
    return !this.isGroup(val);
  }

  expandCollapseGroup(group: Pick<DisplayListItem, 'expand'>) {
    group.expand = !group.expand;
  }

  groupValueChange(group: DisplayListItem, value: boolean) {
    if (this.isGroup(group.colDef)) {
      if (this.gridApi) {
        const allIds: string[] = group.colDef.children
          .filter((id) => !!id)
          .map((child) => (child as ColDef).colId) as string[];

        this.gridApi.setColumnsVisible(allIds, value);

        group.children?.forEach((g) => (g.checked = value));
      }
    }
  }

  colValueChange(
    displayItem: DisplayListItem,
    group: DisplayListItem | undefined,
    isVisible: boolean,
  ) {
    if (
      this.isColumn(displayItem.colDef) &&
      this.gridApi &&
      displayItem.colDef.colId
    ) {
      this.gridApi.setColumnVisible(displayItem.colDef.colId, isVisible);

      if (group) {
        group.checked =
          !!group.children && group.children.every((child) => child.checked);
      }
    }
  }

  someChildrenVisible(displayItem: DisplayListItem): boolean {
    return !!(
      !displayItem.checked &&
      displayItem.children?.length &&
      displayItem.children.some((child) => child.checked)
    );
  }

  prepareDisplayList(
    list: Array<ColDef | ColGroupDef> | undefined,
    gridApi: GridApi | undefined,
  ) {
    const displayList: DisplayListItem[] = [];

    if (list && gridApi) {
      for (const colDef of list) {
        let allChildVisible: boolean | undefined = true;
        let children: DisplayListItem[] | undefined;

        if (this.isGroup(colDef)) {
          for (const childCol of colDef.children) {
            if (this.isColumn(childCol)) {
              if (!children) {
                children = [];
              }
              allChildVisible = allChildVisible && !childCol.hide;
              children.push({
                colDef: childCol,
                checked: !childCol.hide,
                expand: true,
              });
            }
          }
        } else {
          allChildVisible = !colDef.hide;
        }

        displayList.push({
          colDef,
          checked: !!allChildVisible,
          children,
          expand: false,
        });
      }
    }

    this.displayList = displayList;
  }
}
