import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {merge, Observable, of, Subject} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
} from 'rxjs/operators';
import {
  SessionService,
  LocalUserDataAccessor,
  LocalUserDataService,
} from '@em/auth/data-access';
import {ISimpleModelService} from '../types/simple-model-service';
import {LanguageCode, IInitializable} from '@em/shared/util-types';
import {LocationUrlService, LanguageModel} from '@em/shared/util-configuration';
import {MerchantsService} from '../merchant/merchants.service';

const LANGUAGE_KEY = 'merchant_language';

@Injectable({
  providedIn: 'root',
})
export class LanguageService
  implements ISimpleModelService<LanguageModel, LanguageCode>, IInitializable
{
  readonly name = 'LanguageService';
  readonly fallback = new LanguageModel('en');
  private readonly _languageAccessor: LocalUserDataAccessor;
  private readonly _languageChange = new Subject<LanguageCode>();
  private readonly _observable: Observable<LanguageModel>;
  private readonly _localeAccessor: LocalUserDataAccessor;

  constructor(
    private readonly _session: SessionService,
    private readonly _localUserData: LocalUserDataService,
    private readonly _merchants: MerchantsService,
    private readonly _translate: TranslateService,
    private readonly _locationUrl: LocationUrlService,
  ) {
    this._languageAccessor = this._localUserData.createAccessor(LANGUAGE_KEY);
    this._localeAccessor = this._localUserData.createAccessor('locale');

    this._observable = merge(
      this._session.loginResponse.pipe(map((resp) => resp.language)),
      this._merchants.observable().pipe(map((merchant) => merchant.language)),
      this._languageChange,
    ).pipe(
      startWith(...this._fromLocalUserData()),
      map((resp) => this._buildEntity(resp)),
      shareReplay({refCount: false, bufferSize: 1}),
    );
  }

  initialize(): Observable<void> {
    // Maintain 1 subscription to ensure sideeffects
    // TODO: fix
    this._observable.subscribe((language) => {
      this._storeLanguage(language);
    });

    this._observable
      .pipe(
        map((lang) => lang.locale),
        distinctUntilChanged(),
        filter((locale) => locale !== this._localeAccessor.value),
      )
      .subscribe((newLocale) => {
        this._localeAccessor.value = newLocale;
        this._locationUrl.restartWithParam('locale', undefined, true);
      });

    this._languageChange.subscribe((languageCode) => {
      this._updateMerchant(languageCode);
    });

    return of(undefined);
  }

  observable(): Observable<LanguageModel> {
    return this._observable;
  }

  change(languageCode: LanguageCode) {
    this._languageChange.next(languageCode);
  }

  availableLanguages(): LanguageCode[] {
    return this._translate
      .getLangs()
      .map(
        (languageCode: string) => languageCode.toLowerCase() as LanguageCode,
      );
  }

  private _buildEntity(languageCode: LanguageCode): LanguageModel {
    return new LanguageModel(languageCode, this.fallback);
  }

  private _storeLanguage(language: LanguageModel) {
    this._languageAccessor.value = language.code;
  }

  private _updateMerchant(languageCode: LanguageCode) {
    this._merchants.update({language: languageCode});
  }

  private _fromLocalUserData(): LanguageCode[] {
    const value = this._languageAccessor.value;
    if (value) return [value as LanguageCode];
    return [];
  }
}
