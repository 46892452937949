import {CommonModule} from '@angular/common';
import {Component, EventEmitter, Input, Output, inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormsModule} from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import {Router, RouterLink} from '@angular/router';
import {ProductsCountService} from '@em/data-feed/data-access-products';
import {FACEBOOK, GOOGLE, MICROSOFT} from '@em/shared/platforms/util';
import {
  EmBoxModule,
  EmCheckboxModule,
  EmIconModule,
  EmPrimaryButtonComponent,
  EmWarningBoxModule,
} from '@em/shared/ui';
import {Contract, ContractWithPrice} from '@em/subscription/api-interface';
import {
  RepricingProductsCountStore,
  getHigherFrequency,
  getSumJobsProducts,
  getUniqueSources,
  ContractService,
} from '@em/subscription/data-access';
import {TranslateModule} from '@ngx-translate/core';
import {combineLatest, map, take, tap} from 'rxjs';
import {EmPlanBoxComponent} from '../plan-box/plan-box.component';
import {PaymentService} from '../services/payment-service/payment.service';
import {SuggestedPlanService} from '../services/suggested-plan/suggested-plan.service';
import {isEqual} from 'lodash';

@Component({
  selector: 'em-upgrade-plan',
  templateUrl: './upgrade-plan.component.html',
  styleUrls: ['./upgrade-plan.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    RouterLink,
    MatDialogModule,
    EmPrimaryButtonComponent,
    EmBoxModule,
    EmPlanBoxComponent,
    EmCheckboxModule,
    EmIconModule,
    EmWarningBoxModule,
  ],
  providers: [SuggestedPlanService],
})
export class EmUpgradePlanComponent {
  @Input() set contract(value: Contract | undefined | null) {
    if (value) {
      this._suggestedPlanService.missingFeatures$.next(
        this._copyContract(value),
      );
    }
  }
  @Output() navigateTo = new EventEmitter<string>();
  @Output() toPayment = new EventEmitter<boolean>();

  private readonly _contractService = inject(ContractService);
  private readonly _suggestedPlanService = inject(SuggestedPlanService);
  private readonly _productsCountService = inject(ProductsCountService);
  private readonly _paymentService = inject(PaymentService);
  private readonly _repricingProductsCountStore = inject(
    RepricingProductsCountStore,
  );
  private readonly _router = inject(Router);

  editableContract: Contract | null | undefined;

  readonly Google = GOOGLE;
  readonly facebook = FACEBOOK;
  readonly microsoft = MICROSOFT;

  readonly suggestedPlan$ = this._suggestedPlanService.suggestedPlan$;
  readonly suggestedContract$ = this._suggestedPlanService.suggestedPlan$.pipe(
    map((c) => c?.contract),
  );
  readonly productsCount$ = this._productsCountService.allProductsCount$;
  readonly showProductsMissing$ =
    this._productsCountService.allProductsCount$.pipe(map((count) => !count));

  readonly showPlans$ = combineLatest([
    this._contractService.liveContract$,
    this.productsCount$,
  ]).pipe(map(([liveContract, count]) => !!liveContract || !!count));
  readonly noPlanExist$ = this._contractService.noPlanExist$;
  readonly liveContractWithPrice$ =
    this._contractService.liveContractWithPrice$;

  readonly totalCrawlProducts$ = this._contractService.editableContract$.pipe(
    map((contract) => getSumJobsProducts(contract?.price_crawls || [])),
  );
  readonly priceCrawlSourcesCount$ =
    this._contractService.editableContract$.pipe(
      map((contract) => getUniqueSources(contract?.price_crawls || []).length),
    );
  readonly crawlHigherFrequencyTitle$ =
    this._contractService.editableContract$.pipe(
      map((contract) =>
        getHigherFrequency(contract?.price_crawls || [])?.toUpperCase(),
      ),
    );
  readonly hasPriceCrawl$ = this._contractService.editableContract$.pipe(
    map((contract) => !!contract?.price_crawls?.find((job) => job.enabled)),
  );

  readonly repricingProductsCount$ =
    this._repricingProductsCountStore.repricingProductsCount$;

  readonly showSuggestedPlan$ = combineLatest([
    this.liveContractWithPrice$,
    this.suggestedPlan$,
  ]).pipe(
    map(([current, suggested]) =>
      current && suggested ? !this._isEqual(current, suggested) : true,
    ),
  );

  constructor() {
    this._contractService.editableContract$
      .pipe(
        takeUntilDestroyed(),
        tap((c) => (this.editableContract = c)),
      )
      .subscribe();
  }

  upgrade() {
    this.toPayment.emit(true);
    this.suggestedContract$
      .pipe(
        take(1),
        tap((contract) => {
          if (contract) {
            this._paymentService.startPayment(contract, this._router.url);
          }
        }),
      )
      .subscribe();
  }

  updateGooglePrice(value: boolean) {
    this._suggestedPlanService.missingFeatures$.next({
      ...this._suggestedPlanService.missingFeatures$.value,
      google_prices: {enabled: value},
    } as Contract);
  }

  updateDatafeed(update: Partial<Contract['datafeed']>) {
    this._suggestedPlanService.missingFeatures$.next({
      ...this._suggestedPlanService.missingFeatures$.value,
      datafeed: {
        ...this._suggestedPlanService.missingFeatures$.value?.datafeed,
        ...update,
      },
    } as Contract);
  }

  updateAds(update: Partial<Contract['advertising']>) {
    this._suggestedPlanService.missingFeatures$.next({
      ...this._suggestedPlanService.missingFeatures$.value,
      advertising: {
        ...this._suggestedPlanService.missingFeatures$.value?.advertising,
        ...update,
      },
    } as Contract);
  }

  updatePriceCrawl(value: boolean) {
    if (value) {
      this._suggestedPlanService.missingFeatures$.next({
        ...this._suggestedPlanService.missingFeatures$.value,
        price_crawls: [...(this.editableContract?.price_crawls || [])],
      } as Contract);
    } else {
      this._suggestedPlanService.missingFeatures$.next({
        ...this._suggestedPlanService.missingFeatures$.value,
        price_crawls: [
          ...(this.editableContract?.price_crawls?.map((c) => ({
            ...c,
            enabled: false,
          })) || []),
        ],
      } as Contract);
    }
  }

  updateRepricing(value: boolean) {
    const currentValue = this._suggestedPlanService.missingFeatures$.getValue();
    const repricing = currentValue?.repricing;
    if (repricing) {
      this._suggestedPlanService.missingFeatures$.next({
        ...this._suggestedPlanService.missingFeatures$.value,
        repricing: {...repricing, enabled: value},
      } as Contract);
    } else {
      this.repricingProductsCount$
        .pipe(
          take(1),
          tap((count) => {
            this._suggestedPlanService.missingFeatures$.next({
              ...this._suggestedPlanService.missingFeatures$.value,
              repricing: {enabled: value, total_products: count},
            } as Contract);
          }),
        )
        .subscribe();
    }
  }

  closeAndNavigate(url: string) {
    this.navigateTo.emit(url);
  }

  private _copyContract(contract: Contract | undefined | null): Contract {
    return JSON.parse(JSON.stringify(contract));
  }

  private _isEqual(current: ContractWithPrice, suggested: ContractWithPrice) {
    if (isEqual(current, suggested)) {
      return true;
    } else if (
      current.pricePreview.credits !== suggested.pricePreview.credits
    ) {
      return false;
    } else {
      const emptyContract: Contract = {
        advertising: {
          google_campaigns: false,
          google_css: false,
          meta_campaigns: false,
          microsoft_campaigns: false,
        },
        google_prices: {enabled: false},

        datafeed: {
          csv_export: false,
          google_upload: false,
          meta_upload: false,
          microsoft_upload: false,
        },
        repricing: {enabled: false},
        product_management: {total_products: 1},
      };
      return (
        isEqual(
          {...emptyContract.advertising, ...current.contract.advertising},
          {...emptyContract.advertising, ...suggested.contract.advertising},
        ) &&
        isEqual(
          {...emptyContract.google_prices, ...current.contract.google_prices},
          {...emptyContract.google_prices, ...suggested.contract.google_prices},
        ) &&
        isEqual(
          {...emptyContract.datafeed, ...current.contract.datafeed},
          {...emptyContract.datafeed, ...suggested.contract.datafeed},
        )
      );
    }
  }
}
