import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EmButtonModule} from '../button/button.module';
import {EmIconModule} from '../icon/icon.component';
import {Clipboard} from '@angular/cdk/clipboard';
import {BehaviorSubject, interval} from 'rxjs';
import {take} from 'rxjs/operators';

@Component({
  selector: 'em-copy-to-clipboard',
  imports: [CommonModule, EmButtonModule, EmIconModule],
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss'],
})
export class EmCopyToClipboardComponent {
  @Input() text = '';
  @Output() coppied = new EventEmitter<string>();

  showCheckmark$ = new BehaviorSubject(false); //handle delay in an observable

  constructor(private readonly _clipboard: Clipboard) {}

  copyToClipboard() {
    this._clipboard.copy(this.text);
    this.coppied.emit(this.text);
    this.showCheckmark$.next(true);

    interval(2000)
      .pipe(take(1))
      .subscribe(() => this.showCheckmark$.next(false));
  }
}
