import {createFeatureSelector} from '@ngrx/store';
import {initialSetupStatus} from './initial-setup-status';
import {ISetupStatus} from './setup-status';

export const initialSetupState = {
  outdated: true,
  loading: false,
  status: initialSetupStatus,
};

export interface ISetupFeatureState {
  outdated: boolean;
  loading: boolean;
  status: ISetupStatus;
}

export const selectSetupFeatureState =
  createFeatureSelector<ISetupFeatureState>('setup');
