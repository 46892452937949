import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FetchProductsStore} from '@em/data-feed/data-access-products';
import {
  PipelineMappingEngineAttrsResp,
  PipelineResp,
  ProductPipelinesGateway,
} from '@em/shared/api-interface';
import {
  ComponentStoreBase,
  ComponentStoreStateBase,
} from '@em/shared/util-types';
import {tapResponse} from '@ngrx/operators';
import {EMPTY} from 'rxjs';
import {filter, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {DatafeedStore} from '../datafeed-store/datafeed.store';
import {PluginName} from '@em/shop-system/data-access-settings';

type errorTypes = 'load-pipeline' | 'add-pipeline';

export interface PipelineMappingState
  extends ComponentStoreStateBase<errorTypes> {
  pipeline?: PipelineResp;
  mappingEngineAttributes?: PipelineMappingEngineAttrsResp;
  shopSystemName?: PluginName | null;
}

const initialState: PipelineMappingState = {isLoading: false};

@Injectable()
export class PipelineMappingStore extends ComponentStoreBase<
  PipelineMappingState,
  errorTypes
> {
  readonly pipeline$ = this.select((state) => state?.pipeline);
  readonly source$ = this.select((state) => state?.pipeline?.source);
  readonly uuid$ = this.select((state) => state?.pipeline?.uuid);
  readonly mappingEngineUuid$ = this.select(
    (state) => state?.pipeline?.mapping_engine_uuid,
  );
  readonly mappingEngineAttributes$ = this.select(
    (state) => state.mappingEngineAttributes,
  );
  readonly shopSystemName$ = this.select((state) => state.shopSystemName);

  constructor(
    private readonly _datafeedStore: DatafeedStore,
    private readonly _pipelinesGateway: ProductPipelinesGateway,
    private readonly _fetchProductsStore: FetchProductsStore,
  ) {
    super(initialState);
    this.loadDatafeedInfo();
  }

  readonly loadPipeline = this.effect<string | null | undefined>((uuid$) =>
    uuid$.pipe(
      switchMap((uuid) => {
        if (uuid) {
          this.startLoading();
          return this._pipelinesGateway.getProductPipelines({uuid}).pipe(
            tapResponse(
              (pipeline) => {
                this.patchState({
                  pipeline,
                  isLoading: false,
                });
              },
              (err: HttpErrorResponse) => {
                this.addError({
                  httpError: err,
                  errorMessage: {
                    key: 'load-pipeline',
                  },
                  statePayload: {
                    pipeline: undefined,
                  },
                });
              },
            ),
          );
        } else {
          this.patchState({
            pipeline: undefined,
            isLoading: false,
          });
          return EMPTY;
        }
      }),
    ),
  );

  readonly loadDatafeedInfo = this.effect<void>((trigger$) =>
    trigger$.pipe(
      switchMap(() => this.source$),
      filter((source) => source === 'datafeed_api'),
      tap(() => this._datafeedStore.loadPlugin(true)),
      switchMap(() =>
        this._datafeedStore.pluginName$.pipe(
          tapResponse(
            (pluginName) => {
              this.patchState({
                shopSystemName: pluginName,
              });
            },
            () => {
              this.patchState({
                shopSystemName: null,
              });
            },
          ),
        ),
      ),
    ),
  );

  readonly loadMappingEngineAttributes = this.effect<void>((trigger$) =>
    trigger$.pipe(
      switchMap(() => this.uuid$),
      filter((uuid) => !!uuid),
      switchMap((uuid) =>
        this._pipelinesGateway.getColumns({uuid: uuid as string}).pipe(
          tapResponse(
            (columns) => {
              this.patchState({
                mappingEngineAttributes: columns,
              });
            },
            () => {
              this.patchState({
                mappingEngineAttributes: undefined,
              });
            },
          ),
        ),
      ),
    ),
  );

  readonly onAfterSave = this.effect<void>((trigger$) =>
    trigger$.pipe(
      withLatestFrom(this.pipeline$),
      tap(([, pipeline]) => {
        if (pipeline?.active) {
          this._fetchProductsStore.fetchProducts();
        }
      }),
      tapResponse(
        () => {},
        () => {},
      ),
    ),
  );
}
