import {CommonModule} from '@angular/common';
import {Component, Input, NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {RouterModule} from '@angular/router';
import {Store} from '@ngrx/store';
import {TranslateModule} from '@ngx-translate/core';
import {BehaviorSubject, Observable, of, ReplaySubject} from 'rxjs';
import {delay, take} from 'rxjs/operators';
import {selectOnboardingCompleted} from '@em/onboarding/data-access';
import {NavListItemModule} from '../nav-list-item/nav-list-item.component';
import {NavigationService} from '../navigation-service/navigation.service';
import {SideMenuBehaviourStore} from '../state/side-menu-behaviour/side-menu-behaviour.store';
import {TokenStorageService} from '@em/auth/data-access';
import {IMenuItem} from '../nav-menu-items-types';
import {EmButtonModule} from '@em/shared/ui';
import {CountrySelectionModule} from '../country-selection/country-selection.component';
import {UserMenuModule} from '../user-menu/user-menu.component';

@Component({
  selector: 'em-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss'],
  providers: [SideMenuBehaviourStore, NavigationService],
  standalone: false,
})
export class SideNavigationComponent {
  @Input() disabled: boolean | undefined;
  @Input() hideNavigation: boolean | undefined;

  readonly isOpen$ = this._menuBehaviourStore.isOpen$;
  readonly isSmall$ = this._menuBehaviourStore.isSmall$;
  readonly showMobileMenuIcon$ = this._menuBehaviourStore.showMobileMenu$;
  readonly navMode$ = this._menuBehaviourStore.navMode$;
  readonly isShrinkMode$ = this._menuBehaviourStore.isShrinkMode$;
  readonly mainItemsObservable$: Observable<IMenuItem[]> =
    this._navigationService.mainItemsObservable$;
  readonly userMenuItems$: Observable<IMenuItem[]> =
    this._navigationService.userMenuItems$;
  readonly showMenu$ = this._store.select(selectOnboardingCompleted);
  readonly logoUrl$ = this._navigationService.logoUrl$;
  readonly headerOutletActive$: Observable<boolean>;
  readonly activeItem$: Observable<IMenuItem | undefined> =
    this._navigationService.activeItem$;
  isAutoSize = false;

  private readonly _mouseOverMenu = new ReplaySubject<boolean>(1);
  private readonly _mouseOutMenu = new ReplaySubject<boolean>(1);
  private readonly _headerOutletActive$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _store: Store,
    private readonly _navigationService: NavigationService,
    private readonly _menuBehaviourStore: SideMenuBehaviourStore,
    private readonly _tokenStorageService: TokenStorageService,
  ) {
    this.headerOutletActive$ = this._headerOutletActive$.asObservable();

    this._menuBehaviourStore.mouseOverMenu(this._mouseOverMenu.asObservable());
    this._menuBehaviourStore.mouseOutMenu(this._mouseOutMenu.asObservable());
  }

  sideNavClosed() {
    this._menuBehaviourStore.closeMenu();
  }

  menuItemClicked() {
    this._menuBehaviourStore.menuItemClicked();
  }

  toggleMenu() {
    this._menuBehaviourStore.toggleMenu();
  }

  toggleShrinkMenu() {
    this._menuBehaviourStore.toggleShrinkMenu();
  }

  mouseOverSideBar() {
    this._mouseOverMenu.next(true);
  }

  mouseOutSideBar() {
    // For performance reason don't set autosize to true always, only when needed
    // https://material.angular.io/components/sidenav/overview#resizing-an-open-sidenav
    of(true)
      .pipe(delay(200), take(1))
      .subscribe(() => {
        this.isAutoSize = true;
      });

    of(true)
      .pipe(delay(300), take(1))
      .subscribe(() => {
        this.isAutoSize = false;
      });

    this._mouseOutMenu.next(true);
  }

  headerOutletActive(value: boolean) {
    this._headerOutletActive$.next(value);
  }

  isAdmin() {
    return this._navigationService.isAdmin();
  }

  logout() {
    this._navigationService.logout();
  }

  trackByIndex(index: number) {
    return index;
  }

  // Temporary solution to switch to a staging environtment
  goToStaging() {
    window.location.href =
      'https://staging.emarketing.com?jwt=' +
      this._tokenStorageService.getToken();
  }
}

@NgModule({
  declarations: [SideNavigationComponent],
  exports: [SideNavigationComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatToolbarModule,
    MatMenuModule,
    CountrySelectionModule,
    UserMenuModule,
    NavListItemModule,
    TranslateModule,
    EmButtonModule,
  ],
})
export class SideNavigationModule {}
