import {Component, Input, OnInit} from '@angular/core';
import {MatRadioChange} from '@angular/material/radio';
import {
  ConnectItemType,
  ConnectLogicalOperator,
  EditProductGroupStore,
  FilterGroup,
  FilterGroupItem,
  FilterSetLink,
  IFilterGroupLink,
} from '@em/data-feed/data-access-products';
import {ComponentBase} from '@em/shared/ui';
import {fromJS} from 'immutable';

export enum ACTIONS {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
}

@Component({
  selector: 'em-filter-connect',
  templateUrl: './filter-connect.component.html',
  styleUrls: ['./filter-connect.component.scss'],
  standalone: false,
})
export class FilterConnectComponent extends ComponentBase implements OnInit {
  @Input() groupIndex = 0;
  @Input() itemIndex = 0;
  @Input() connectGroup?: FilterGroup;
  @Input() group?: FilterGroup;
  @Input() item?: FilterGroupItem;
  @Input() connectItem?: FilterGroupItem;
  @Input() action: 'connect' | 'disconnect' = 'connect';

  value: ConnectLogicalOperator | null = null;

  readonly connectIcon = 'assets/images/products/connect.svg';
  readonly disconnectIcon = 'assets/images/products/disconnect.svg';

  constructor(private readonly _editGroupStore: EditProductGroupStore) {
    super();
  }

  get icon() {
    return !this.isGroup ? this.connectIcon : this.disconnectIcon;
  }

  get isGroup(): boolean {
    return this.action === ACTIONS.DISCONNECT;
  }

  ngOnInit(): void {
    this._subscribe(this._editGroupStore.groupLinks$, (links) => {
      if (links) {
        const foundLink = links.find(
          (link: FilterSetLink) =>
            link.parent_uuid ===
            (this.isGroup ? this.connectGroup?.uuid : null),
        );

        this.value = foundLink ? foundLink.operator : null;
      } else {
        this.value = null;
      }
    });
  }

  changeLogicalOperator($event: MatRadioChange) {
    if (!this.item || !this.connectGroup) return;

    let link: IFilterGroupLink;

    if (this.isGroup) {
      // connection in same group
      link = {
        parent_uuid: this.connectGroup?.uuid,
        operator: $event.value,
        type: ConnectItemType.ITEM,
      };
    } else {
      link = {
        parent_uuid: null,
        operator: $event.value,
        type: ConnectItemType.GROUP,
      };
    }

    this._editGroupStore.patchLinkedList(link);
  }

  doAction() {
    if (this.action === ACTIONS.CONNECT) {
      this._connectFilter();
    } else {
      this._disconnectFilter();
    }
  }

  private _connectFilter() {
    if (!this.group || !this.connectGroup) return;

    this._editGroupStore.connectFilterGroups({
      target: this.connectGroup,
      origin: this.group,
      operator: this.value || ConnectLogicalOperator.AND,
    });
  }

  private _disconnectFilter() {
    if (!this.item || !this.connectGroup) return;

    const update: FilterGroup = {
      ...this.connectGroup,
      items: fromJS(this.connectGroup.items).filter((item: FilterGroupItem) => {
        if (this.item) {
          return item.uuid !== this.item.uuid;
        }
        return true;
      }),
    };

    const index = this.itemIndex > 0 ? this.groupIndex + 1 : this.groupIndex;

    this._editGroupStore.updateFilterGroupLinks(update);
    this._editGroupStore.disconnectFilterGroupItem({
      disconnectGroup: {
        ...update,
      },
      filterGroupItem: this.item,
      index,
    });
  }
}
