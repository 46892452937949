import {ApexOptions} from 'ng-apexcharts';

export class ApexChartsHelper {
  static mergePieChartOptions(options: Partial<ApexOptions>): ApexOptions {
    return {...basicPieChartOptions, ...options};
  }
}

export const basicPieChartOptions: ApexOptions = {
  colors: ['#FF7900', '#F4F5F7'],
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    enabled: false,
  },
  stroke: {
    width: 4,
    colors: ['transparent'],
  },
  chart: {
    type: 'donut',
    width: '100%',
    height: '100%',
  },
  legend: {
    show: false,
  },
  states: {
    hover: {
      filter: {
        type: 'lighten',
      },
    },
    active: {
      filter: {
        type: 'none',
      },
    },
  },
  plotOptions: {
    pie: {
      expandOnClick: false,
      donut: {
        size: '120',
      },
    },
  },
};

export const basicBarChartOptions: ApexOptions = {
  colors: ['#FF7900'],
  chart: {
    type: 'bar',
    height: 275,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '40%',
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent'],
  },
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
    active: {
      filter: {
        type: 'none',
      },
    },
  },
  xaxis: {
    type: 'category',
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: true,
    labels: {
      show: true,
      formatter: (val: number): string => val.toFixed(2),
    },
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    x: {
      show: false,
    },
    marker: {
      show: false,
    },
    y: {
      title: {
        formatter: () => '',
      },
    },
  },
};

export const lineChartOptions: ApexOptions = {
  colors: ['#FF7900'],
  chart: {
    type: 'line',
    stacked: false,
    height: 450,
    toolbar: {
      show: false,
      tools: {
        download: false,
      },
    },
    zoom: {
      enabled: false,
    },
  },
  stroke: {
    curve: 'smooth',
    width: 3,
  },
  yaxis: {
    show: true,
    labels: {
      show: true,
      formatter: (val: number): string => val.toFixed(2),
    },
  },
  xaxis: {
    type: 'category',
  },
};

export const scatterChartOptions: ApexOptions = {
  colors: ['#FF7900'],
  chart: {
    height: 450,
    type: 'scatter',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
    animations: {
      enabled: false,
    },
  },
  xaxis: {
    tickAmount: 10,
    labels: {
      formatter(val) {
        // eslint-disable-next-line
        return parseFloat(val).toFixed(1);
      },
    },
  },
  yaxis: {
    tickAmount: 7,
  },
};
