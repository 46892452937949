import {CommonModule} from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewContainerRef,
  inject,
} from '@angular/core';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ProductsListLayoutStore} from '@em/data-feed/data-access-products';
import {
  DialogService,
  EmIconButtonComponent,
  EmSelectModule,
} from '@em/shared/ui';
import {TranslateModule} from '@ngx-translate/core';
import {EmAddNewLayoutComponent} from '../add-new-layout/add-new-layout.component';
import {ColumnState, GridApi} from 'ag-grid-community';
import {ProductsListLayout} from '@em/shared/api-interface';
import {EMPTY, map, switchMap, take, tap} from 'rxjs';
import {isNotNullOrUndefined, isNotUndefined} from '@em/shared/util-rxjs';

@Component({
  selector: 'em-products-list-layout',
  imports: [
    CommonModule,
    TranslateModule,
    MatTooltipModule,
    MatDialogModule,
    EmSelectModule,
    EmIconButtonComponent,
  ],
  templateUrl: './products-list-layout.component.html',
  styleUrls: ['./products-list-layout.component.scss'],
  providers: [ProductsListLayoutStore],
})
export class EmProductsListLayoutComponent implements OnInit {
  @Input({required: true}) gridApi?: GridApi;
  @Output() layoutChanged = new EventEmitter();

  private readonly _layoutStore = inject(ProductsListLayoutStore);
  private readonly _dialog = inject(MatDialog);
  private readonly _vcr = inject(ViewContainerRef);
  private readonly _dialogService = inject(DialogService);

  defaultLayout: ProductsListLayout = {
    name: 'SHARED_LABEL_GENERATE_OVERVIEW',
    active: false,
    layout: {columns: [], filters: {}},
    uuid: 'default',
  };
  selectedLayoutUuid: string = this.defaultLayout.uuid;
  readonly layouts$ = this._layoutStore.layouts$;
  readonly activeLayout$ = this._layoutStore.activeLayout$;

  get canEdit() {
    return this.selectedLayoutUuid !== 'default';
  }

  ngOnInit(): void {
    // Apply the active layout initially
    this._layoutStore.activeLayout$
      .pipe(
        isNotUndefined(),
        take(1),
        tap((layout) => {
          if (layout) {
            this.selectedLayoutUuid = layout.uuid;
            this._applyLayout(layout);
          }
        }),
      )
      .subscribe();
  }

  addNewLayout() {
    if (this.gridApi) {
      const dialog = this._dialog.open(EmAddNewLayoutComponent, {
        data: {
          layout: {
            columns: this.gridApi.getColumnState(),
            filters: this.gridApi.getFilterModel(),
          } as ProductsListLayout['layout'],
        },
        viewContainerRef: this._vcr,
        width: '400px',
      });

      dialog.componentInstance.layoutSaved
        .pipe(
          take(1),
          tap((uuid) => {
            this.selectLayout(uuid);
            dialog.close();
          }),
        )
        .subscribe();
    }
  }

  saveLayout() {
    this._dialogService
      .confirm({
        description: 'SAVE_PRODUCT_LIST_LAYOUT_DESC',
        title: 'SAVE_PRODUCT_LIST_LAYOUT_TITLE',
        confirmLabel: 'SHARED_LABEL_SAVE_CHANGES',
      })
      .pipe(
        switchMap((confirm) => {
          if (confirm) {
            return this.activeLayout$.pipe(
              isNotNullOrUndefined(),
              take(1),
              tap((layout) => {
                this._layoutStore.addOrUpdateLayout({
                  ...layout,
                  layout: {
                    columns: this.gridApi?.getColumnState(),
                    filters: this.gridApi?.getFilterModel(),
                  } as ProductsListLayout['layout'],
                });
              }),
            );
          } else {
            return EMPTY;
          }
        }),
      )
      .subscribe();
  }

  deleteLayout(uuid: string) {
    this._dialogService
      .confirm({
        description: 'DELETE_PRODUCT_LIST_LAYOUT_DESC',
        title: 'DELETE_PRODUCT_LIST_LAYOUT_TITLE',
        confirmLabel: 'SHARED_LABEL_DELETE',
        confirmType: 'danger',
      })
      .subscribe((confirm) => {
        if (confirm) {
          this._layoutStore.deleteLayout(uuid);
          this.selectLayout(this.defaultLayout.uuid);
        }
      });
  }

  selectLayout(uuid: string) {
    this.selectedLayoutUuid = uuid;
    if (this.defaultLayout.uuid === uuid) {
      // here we apply the default layout
      this.gridApi?.resetColumnState();
      this.gridApi?.setFilterModel([]);
      this.layoutChanged.emit();
    } else {
      this._layoutStore.layouts$
        .pipe(
          map((layouts) => layouts?.find((l) => l.uuid === uuid)),
          tap((layout) => {
            if (layout) {
              this._setActive(layout);
              this._applyLayout(layout);
            }
          }),
          take(1),
        )
        .subscribe();
    }
  }

  private _setActive(layout: ProductsListLayout) {
    this._layoutStore.addOrUpdateLayout({...layout, active: true});
  }

  private _applyLayout(layout: ProductsListLayout) {
    if (layout.layout.columns) {
      this.gridApi?.applyColumnState({
        state: layout.layout.columns as ColumnState[],
        applyOrder: true,
      });
    }
    if (layout.layout.filters) {
      this.gridApi?.setFilterModel(layout.layout.filters);
    }
    this.layoutChanged.emit();
  }
}
