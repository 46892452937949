import {CommonModule} from '@angular/common';
import {Component, Inject} from '@angular/core';
import {MatDialogModule, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TranslateModule} from '@ngx-translate/core';
import {IDialogConfig} from '../../../services/dialog-service/dialog-config';
import {EmTextButtonComponent} from '../../buttons/text-button/text-button.component';

@Component({
  selector: 'em-info-dialog',
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    EmTextButtonComponent,
  ],
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
})
export class EmInfoDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) readonly data: IDialogConfig) {}
}
