import {CommonModule} from '@angular/common';
import {Component, forwardRef} from '@angular/core';
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {TranslateModule} from '@ngx-translate/core';
import {DropdownFilterItemOptionsComponent} from '../dropdown-filter-item-options/dropdown-filter-item-options.component';
import {IOptionViewModel} from '../filter-item-options/filter-item-options.component';
import {IProductFilterDefinition} from '../product-filter-definition';
import {EmFilterItemOptionInputComponent} from '../filter-item-option-input/filter-item-option-input.component';
import {optionValueType} from '@em/data-feed/data-access-products';
import {IOptionDefinition} from '../option-definition';

@Component({
  selector: 'em-fetch-status-options',
  templateUrl: './fetch-status-filter-item-options.component.html',
  styleUrls: ['./fetch-status-filter-item-options.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatSlideToggleModule,
    EmFilterItemOptionInputComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmFetchStatusFilterItemOptionsComponent),
      multi: true,
    },
  ],
})
export class EmFetchStatusFilterItemOptionsComponent extends DropdownFilterItemOptionsComponent {
  valueOption?: IOptionViewModel;
  operatorOption?: IOptionViewModel;
  parentFilterKey = 'fetch_status';
  override _generateViewModel(definition: IProductFilterDefinition) {
    const superValue = super._generateViewModel(definition);
    this.valueOption = superValue.find((option) => option.key === 'value');
    this.operatorOption = superValue.find(
      (option) => option.key === 'operator',
    );
    return superValue;
  }

  OperatorChanged(
    key: string,
    value: optionValueType | undefined,
    optionType: IOptionDefinition['type'],
  ) {
    super.setOptionValue(key, value, optionType);

    if (value !== 'eq' && this.valueOption) {
      this.setOptionValue('value', null, this.valueOption.definition.type);
    }
  }
}
