import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'em-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss'],
  standalone: false,
})
export class ChangeEmailComponent {
  @Input() form?: UntypedFormGroup;

  constructor() {}
}
