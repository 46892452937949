import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {ProductGroup} from '@em/data-feed/data-access-products';
import {EmProductGroupCardComponent} from '../product-group-card/product-group-card.component';

@Component({
  selector: 'em-product-group-card-modal',
  imports: [MatDialogModule, EmProductGroupCardComponent],
  templateUrl: './product-group-card-modal.component.html',
  styleUrls: ['./product-group-card-modal.component.scss'],
})
export class EmProductGroupCardModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {group: ProductGroup}) {}
}
