import {CommonModule} from '@angular/common';
import {Component, ContentChild, Input, Optional, Self} from '@angular/core';
import {
  FormsModule,
  NgControl,
  ReactiveFormsModule,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import {FloatLabelType} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {CurrencyCode, IMoney} from '@em/shared/util-types';
import {EmCurrencySymbolPipe} from '../../pipes/currency-symbol/currency-symbol.pipe';
import {EmMaterialWrapperComponent} from '../material-wrapper/material-wrapper.component';
import {EmSuffixDirective} from '../../directives/form-field/suffix.directive';

export const moneyValidator: (min: number) => ValidatorFn =
  (min) => (control) => {
    if (!control.value) return null;

    let valid = true;
    const errors: ValidationErrors = {};
    const money = control.value as Partial<IMoney>;
    if (!money.value || money.value < min) {
      valid = false;
      errors['valueMin'] = {value: money.value, min};
    }

    if (!money.currencyCode) {
      valid = false;
      errors['missingCurrencyCode'] = {};
    }

    return valid ? null : errors;
  };

@Component({
  selector: 'em-money-input',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EmCurrencySymbolPipe,
    MatInputModule,
    MatFormFieldModule,
  ],
  templateUrl: './money-input.component.html',
  styleUrls: ['./money-input.component.scss'],
})
export class EmMoneyInputComponent extends EmMaterialWrapperComponent<
  IMoney | undefined
> {
  @Input() currencyCode?: CurrencyCode;
  @Input() placeholder = '';
  @Input() label?: string;
  @Input() floatLabel: FloatLabelType = 'auto';
  @Input() hint = '';
  @ContentChild(EmSuffixDirective) emSuffix: EmSuffixDirective | undefined;

  stringValue?: string;
  valueControl = new UntypedFormControl();
  private _numericValue?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _timer: any;

  constructor(@Optional() @Self() public override ngControl: NgControl) {
    super(ngControl);
  }

  onValueChange(event: Event) {
    if (event.target) {
      this._numericValue = Number.parseFloat(
        (event.target as HTMLInputElement).value,
      );
      if (event.type === 'keyup') {
        this._updateValue();
      } else {
        this._updateValueNow();
      }
    }
  }

  override setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.valueControl.disable();
    } else {
      this.valueControl.enable();
    }
  }

  override writeValue(money: IMoney | undefined): void {
    if (money) {
      if (money.value) {
        this.stringValue = money.value.toFixed(2);
        this.valueControl.setValue(this.stringValue);
      }
      this.currencyCode = money.currencyCode;
    }
  }

  private _emitChange(money: IMoney | undefined) {
    if (this.onChange) this.onChange(money);
  }

  private _updateValue() {
    clearTimeout(this._timer);
    this._timer = setTimeout(() => {
      this._updateValueNow();
    }, 500);
  }

  private _updateValueNow() {
    if (
      this._numericValue !== undefined &&
      !isNaN(this._numericValue) &&
      this.currencyCode
    ) {
      const money: IMoney = {
        value: this._numericValue,
        currencyCode: this.currencyCode,
      };
      this.stringValue = this._numericValue.toFixed(2);
      this.valueControl.setValue(this.stringValue);
      this._emitChange(money);
    } else {
      this.valueControl.setValue(undefined);
      this._emitChange(undefined);
    }
    this.controlTouched();
  }
}
