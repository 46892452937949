import {CommonModule, CurrencyPipe} from '@angular/common';
import {Component, Input, NgModule} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {MatMenuModule} from '@angular/material/menu';
import {TranslateModule} from '@ngx-translate/core';
import {combineLatest, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {CompetitorsLandscapeStore} from './competitor-landscape.store';
import {CompetitorChartOptions} from './state';
import {EmCheckboxModule, EmIconButtonComponent} from '@em/shared/ui';
import {CompetitorDetails} from './types';
import {ScatterChartModule} from '../scatter-chart/scatter-chart.component';
export * from './state';

@Component({
  selector: 'em-competitor-landscape-chart',
  templateUrl: './competitor-landscape-chart.component.html',
  styleUrls: ['./competitor-landscape-chart.component.scss'],
  providers: [CompetitorsLandscapeStore, CurrencyPipe],
  standalone: false,
})
export class EmCompetitorLandscapeChartComponent {
  @Input()
  set series(data: CompetitorDetails[]) {
    this._chartSeries.next(data);
  }
  @Input()
  set options(options: CompetitorChartOptions) {
    this._options.next(options);
  }

  readonly chartFormGroup: UntypedFormGroup;
  readonly chartData$ = this.chartStore.chartData$;

  private readonly _chartSeries = new Subject<CompetitorDetails[]>();
  private readonly _options = new Subject<CompetitorChartOptions>();

  constructor(
    _formBuilder: UntypedFormBuilder,
    private readonly chartStore: CompetitorsLandscapeStore,
  ) {
    this.chartFormGroup = _formBuilder.group({
      outliers: [true],
      mostlyUsedPrice: [true],
    });

    this.chartStore.generateChart(
      combineLatest([this._chartSeries, this._options]).pipe(
        map(([chartData, options]) => ({
          chartData,
          chartOptions: {
            ...options,
            displayOptions: this.chartFormGroup.value,
          },
        })),
      ),
    );

    this.chartStore.updateDisplayOptions(
      combineLatest([this._options, this.chartFormGroup.valueChanges]).pipe(
        map(([options, displayOptions]) => ({...options, displayOptions})),
      ),
    );
  }
}

@NgModule({
  declarations: [EmCompetitorLandscapeChartComponent],
  exports: [EmCompetitorLandscapeChartComponent],
  imports: [
    CommonModule,
    ScatterChartModule,
    MatMenuModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    EmCheckboxModule,
    EmIconButtonComponent,
  ],
})
export class EmCompetitorLandscapeChartModule {}
